import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
import './utils/i18n/i18n';
import { setupConfig } from '@ionic/react';
import "./utils/SentryInit"
import { Plugins, StatusBarStyle, } from '@capacitor/core';
import './utils/mixPanels/InitMixPanel';



setupConfig({
    swipeBackEnabled: false,
    hardwareBackButton: true
})

const { StatusBar } = Plugins;
StatusBar.setStyle({ style: StatusBarStyle.Dark });

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
