import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { getRoutes } from '../../utils/Routes';
import styled from 'styled-components';
import { PageContainer } from '../../stylesGlobal/CommonStyles';
import { ButtonColor } from '../../components/shared/Button';
import { ReactComponent as Check } from "../../assets/icons/check.svg"
import { useTranslationTyped } from '../../utils/i18n/i18nHelper';
import Overlay from '../../components/shared/Overlay';
import Header from '../../components/shared/Header';
import { useForm } from 'react-hook-form';
import { pagePadding, zIndex } from '../../stylesGlobal/Variables';
import { InputRadio } from '../../components/shared/InputRadio';
import { InputSelect } from '../../components/shared/Inputs';
import FormErrorMessage from '../../components/shared/FormErrorMessage';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { IonPage, IonContent } from '@ionic/react';
import { PageName, PaymentStateType } from '../../utils/Types';
import produce from 'immer';
import GoBackButtonGroup from '../../components/shared/GoBackButtonGroup';
import SmallOverlay from '../../components/shared/SmallOverlay';
import { paymentInfoState, teethPictureState, resourceState, financingOptionsState, userInfoState } from '../../recoil/Atoms';
import { PaymentMethod } from '../../utils/Enums';
import { getFinancingOrMixedPaymentState } from '../../recoil/Selectors';
import { flowHasOdontogram, getPageNumber, getPictureBase64FromFileSystem } from '../../utils/HelperFunctions';
import { ApiClient } from '../../api/ApiClient';
import { useResourceHelper } from './paymentMethods/resourceHelper';
import { useLogout } from '../../utils/customHooks/useLogout';
import { useRecoilState } from 'recoil';
import Spinner from '../../components/shared/Spinner';

const FinancingEntityWrapper = styled(PageContainer)`
    .financingEntity__form{
        display:flex;
        flex-direction:column;
        padding:0 ${pagePadding.leftRight};
        margin-top:3rem;
        padding-bottom:13rem;
        position:absolute;
        left:0;
        width: 100%;
        z-index:${zIndex.two};
    }

`
export type FormData = {
    financingName: string,
}

const FinancingEntityChooser: React.FC = () => {

    const setPaymentInfo = useSetRecoilState(paymentInfoState)
    const paymentInfo = useRecoilValue(getFinancingOrMixedPaymentState)
    const teethPicture = useRecoilValue(teethPictureState)
    const resource = useRecoilValue(resourceState)
    const userInfo = useRecoilValue(userInfoState)

    const [loading, setLoading] = useState(false)
    // const [errorOcurred, setErrorOcurred] = useState(false)
    const [financingOptions, setFinancingOptions] = useRecoilState(financingOptionsState)

    const { register, handleSubmit, errors, getValues } = useForm<FormData>({
        mode: "all",
        shouldFocusError: true,
    })

    const t = useTranslationTyped()
    const resourcesIds = useResourceHelper()
    const history = useHistory()
    const logout = useLogout()

    const onGoBack = () => {
        history.goBack()
    }

    const isFormWithErrors = () => !!errors.financingName

    useEffect(() => {
        const getFinancingOptions = async () => {
            setFinancingOptions(null)
            try {
                setLoading(true)
                const result = await ApiClient.getFinancingOptions({
                    documentoId: paymentInfo.documentId!,
                    tratamientos: resourcesIds.map(r => {
                        return {
                            tratamientoId: r.resourceId,
                            cantidad: r.amount,
                        }
                    }),
                    importeContado: paymentInfo.paymentMethod === PaymentMethod.CashAndFinancing ? paymentInfo.amountCash! : 0,
                    foto: teethPicture?.pictureCropedPath && flowHasOdontogram(resource!) ? await getPictureBase64FromFileSystem(teethPicture?.pictureCropedPath) : "",
                    codigoPromocion: paymentInfo.coupon,
                    identificador: userInfo?.id!,
                }, logout)
                setLoading(false)
                if (result.response) {
                    setFinancingOptions(result.response)
                    // setPaymentInformationAndProceed(formData, result.response as FinancingOptions, data?.currency!)
                } else {
                    // await timeoutPromise(100)
                    // setErrorOcurred(true)
                    setLoading(false)
                }

            } catch (e) {
                // await timeoutPromise(100)
                setLoading(false)
                // setErrorOcurred(true)
                console.error("Error while getting financing options", e)
            }
        }
        getFinancingOptions()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const onSubmit = (data: FormData) => {
        setPaymentInfo(
            produce((prevState: PaymentStateType | null) => {
                if (prevState && prevState.paymentMethod !== PaymentMethod.Cash) {
                    prevState.selectedFinancialEntityName = data.financingName
                }
            })
        )
        const { financingOffer, } = getRoutes()
        history.push(financingOffer)
    }

    const renderForm = () => {
        return <form className="financingEntity__form" onSubmit={handleSubmit(onSubmit)}>

            <InputRadio
                name="paymentMethod"
                value={paymentInfo.paymentMethod}
                label={t("paymentMethod", "form", paymentInfo.paymentMethod)}
                defaultChecked={true}
                selected={true}
                withMarginBottom={true}
            />
            {!financingOptions ? <Spinner theme="light" /> :
                <InputSelect
                    name="financingName"
                    ref={register({
                        required: {
                            message: t("chooseFinancingEntity", "form", "errorMessage"),
                            value: true,
                        },
                        validate: {
                            defaultSelected: () => getValues("financingName") !== "default"
                        }
                    })}
                >
                    <option
                        key={"default"}
                        value={"default"}
                        defaultChecked
                    >
                        {t("chooseFinancingEntity", "defaultValue")}
                    </option>
                    {financingOptions.fundersList.map(f => {
                        return <option
                            key={f.funderName}
                            value={f.funderName}>
                            {f.funderName}
                        </option>
                    })}
                </InputSelect>
            }

            <FormErrorMessage
                message={errors.financingName && errors.financingName.message
                    ? errors.financingName.message : ""}
            />
        </form>
    }

    return (
        <IonPage>
            <IonContent>
                <FinancingEntityWrapper>
                    <Overlay scroll={false}>
                        <SmallOverlay>
                            <Header
                                title={t("chooseFinancingEntity", "pageTitle")}
                                subtitle={t("chooseFinancingEntity", "pageSubtitle")}
                                stepNumber={getPageNumber(PageName.Payment)}
                            />
                        </SmallOverlay>
                        <section>
                            {renderForm()}
                        </section>
                    </Overlay>
                    <GoBackButtonGroup
                        onGoBack={onGoBack}
                        showSmallButtons={true}
                        readyButton={{
                            color: ButtonColor.blue,
                            onClick: handleSubmit(onSubmit),
                            iconSVG: <Check />,
                            label: t("generic", "ready"),
                            disabled: isFormWithErrors() || getValues("financingName") === "default" || loading,
                        }}
                    />
                </FinancingEntityWrapper>
            </IonContent>
        </IonPage>
    )
}

export default FinancingEntityChooser