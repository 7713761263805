import { IonPage, IonContent } from '@ionic/react';
import React, { useRef } from 'react';
import { usePage } from "./TreatmentPagesData"
import { useHistory } from 'react-router';
import { TreatmentOptionType } from './TreatmentFlowDataTypes';
import { getRoutes } from '../../utils/Routes';
import { useSetRecoilState } from 'recoil';
import { resourceState, treatmentFlowState } from '../../recoil/Atoms';
import { useRouteMatchFlowPageId } from '../../utils/customHooks/useRouteMatchFlowPageId';
import { produce } from "immer"
import { PageId, Resource } from '../../utils/Enums';
import GoBackButton from '../../components/shared/GoBackButton';
import styled from 'styled-components';
import { PageContainer } from '../../stylesGlobal/CommonStyles';
import { colors, screenSize, pagePadding, zIndex } from '../../stylesGlobal/Variables';
import TreatmentFlowItem from '../../components/treatmentFlow/TreatmentFlowItem';
import { motion, useTransform, useElementScroll } from "framer-motion"
import Header from '../../components/shared/Header';
import GoBackButtonGroup from '../../components/shared/GoBackButtonGroup';
import BackgroundCircles from '../../components/shared/BackgroundCircles';

type TreatmentFlowPageWrapperProps = {
    itemsQuantity: number
}

const TreatmentFlowPageWrapper = styled(PageContainer) <TreatmentFlowPageWrapperProps>`
    display:flex;
    flex-direction:column;
    justify-content:flex-end;
    position:relative;
    overflow-y: auto;
    position:fixed;
    top:0;
    left:0;

    @media (min-width: 600px) {
        width: 390px;
        position: relative;
    }

    .treatmentPage__overlay{
        position:fixed;
        width:100%;
        height:45%;
        top:0;
        left:0;
        z-index:${zIndex.one};
        background:${colors.darkGradient};
        padding-top:calc(5rem + env(safe-area-inset-top));
        overflow:hidden;
        @media (min-width: 600px) {
            position: absolute;
        }
    }

    .treatmentPage__list{
        z-index:${zIndex.two};
        position:absolute;
        top:39%;
        left:0;
        bottom:0;
        display:flex;
        flex-direction:column;
        justify-content:space-between;
        width: 100%;
        padding:0 ${pagePadding.leftRight} 0 ${pagePadding.leftRight};
    }

    .treatmentPage__gradient{
        position:fixed;
        bottom:0;
        left:0;
        right:0;
        width:100%;
        z-index:${zIndex.three};
        background: linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(255,255,255,.94) 80%);
        height:20%;
        pointer-events:none;
    }

    .treatmentPage__footer {
        position: fixed;
        width: 100%;
        z-index: 5;
        @media (min-width: 600px) {
            width: 390px;
        }
    }

    @media (min-width: ${screenSize.size_m}){
        .treatmentPage__overlay{
            padding-top:5rem;
        }
    }

    @media (min-width: ${screenSize.size_xl}){
        .treatmentPage__overlay{
            padding-top:calc(7rem + env(safe-area-inset-top));
        }

        .treatmentPage__list{
            top:38%;
        }

        .treatmentPage__gradient{
            height:20%;
        }
    }

    @media (min-height: 800px){
        .treatmentPage__list{
            top:${p => p.itemsQuantity > 2
        ? "36%"
        : "46%"
    };
        }
        .treatmentPage__overlay{
            padding-top:${p => p.itemsQuantity > 2
        ? "calc(4rem + env(safe-area-inset-top))"
        : "calc(12rem + env(safe-area-inset-top))"
    };
            height:${p => p.itemsQuantity > 2
        ? "42%"
        : "55%"
    };
        }
        .treatmentPage__gradient{
            height:20%;
        }
    }

    @media (min-height: 830px){
        .treatmentPage__list{
            top:${p => p.itemsQuantity > 2
        ? "36%"
        : "42%"
    };
        }
        .treatmentPage__overlay{
            padding-top:${p => p.itemsQuantity > 2
        ? "calc(6rem + env(safe-area-inset-top))"
        : "calc(12rem + env(safe-area-inset-top))"
    };

        }

    }

`

const TreatmentFlowPage: React.FC = () => {

    const setResourceValue = useSetRecoilState(resourceState);
    const setTreatmentValue = useSetRecoilState(treatmentFlowState);
    const history = useHistory()

    const pageId = useRouteMatchFlowPageId()
    const page = usePage(pageId)

    const treatmentListRef = useRef<HTMLElement>(null)
    const { scrollY } = useElementScroll(treatmentListRef)
    const y = useTransform(scrollY, [0, 200], [0, -70])
    const opacity = useTransform(scrollY, [0, 200], [1, 0])

    const onGoBack = () => {
        setTreatmentValue(
            produce((treatmentState: PageId[] | null) => treatmentState!.slice(0, -1)))
        history.goBack()
    }

    if (page) {
        const { flowWithPageId: flowWithId } = getRoutes()

        const onOptionSelection = (option: TreatmentOptionType) => {
            if (option.nextPageId) {
                setTreatmentValue(
                    produce((treatmentState: PageId[] | null) =>
                        treatmentState!.concat(option.nextPageId as PageId))
                )
                const toPage = flowWithId(option.nextPageId)
                history.push(toPage)
            }
            else if (option.resource && option.onSelectionRoute) {
                setResourceValue(
                    produce((resourceState: Resource | null) => option.resource))
                history.push(option.onSelectionRoute)
            }
        }

        const handleClick = (option: TreatmentOptionType) => () => {
            onOptionSelection(option)
        }

        return (
            <IonPage>
                <IonContent
                    scrollEvents={true}
                    color="transparent"
                    forceOverscroll={false}
                >
                    <TreatmentFlowPageWrapper ref={treatmentListRef} itemsQuantity={page.options.length}>
                        <section className="treatmentPage__overlay">
                            <BackgroundCircles />
                            <motion.section
                                style={{ y, opacity }}
                            >
                                <Header
                                    title={page.title}
                                    stepNumber={page.stepNumber}
                                />
                            </motion.section>
                        </section>
                        <section className="treatmentPage__list">
                            <ul>
                                {page.options.map((option, i) => {
                                    return (
                                        <TreatmentFlowItem
                                            image={option.image}
                                            title={option.title}
                                            subtitle={option.description}
                                            onClick={handleClick(option)}
                                            withMarginBottom={i === page.options.length - 1}
                                            key={`${option.title}-${i}`}
                                        />
                                    )
                                })}
                            </ul>
                        </section>
                        <div className="treatmentPage__footer">
                            <GoBackButtonGroup
                                onGoBack={onGoBack}
                                showSmallButtons={false}
                            />
                        </div>
                        {page.options.length > 2 && <span className="treatmentPage__gradient" />}
                    </TreatmentFlowPageWrapper>
                </IonContent>

            </IonPage>
        )
    } else {
        console.error(`Page not found: flow/%{}`)
        return (
            <IonPage>
                <h1 className="title">Page not found</h1>
                <GoBackButton
                    onClick={onGoBack}
                />
            </IonPage>)
    }
}

export default TreatmentFlowPage