import React from "react"
import styled from "styled-components"
import { colors, screenSize } from "../../stylesGlobal/Variables"
import { motion, AnimatePresence } from "framer-motion"

const FormErrorMessageWrapper = styled(motion.div)`
    
    margin-bottom:.5rem;
    transform-origin:top center;

    .formError_message{
        color:${colors.red};
        font-size:1.2rem;
        font-weight:600;
    }

    @media (min-width: ${screenSize.size_l}){
        margin-bottom:.8rem;
    }

`

type Props = {
    message: string,
}

const FormErrorMessage = (props: Props) => {

    return (
        <AnimatePresence>
            {props.message && props.message !== "" &&
                <FormErrorMessageWrapper
                    initial={{ height: "0" }}
                    animate={{ height: "20px" }}
                    exit={{ height: "0", marginBottom: "0" }}
                    transition={{
                        duration: .2,
                    }}
                >
                    <motion.p
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                        transition={{
                            duration: .1,
                        }}
                        className="formError_message"
                    >
                        {props.message}
                    </motion.p>
                </FormErrorMessageWrapper>
            }
        </AnimatePresence>
    )
}

export default FormErrorMessage