import { IonPage, IonContent } from '@ionic/react';
import React, { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router';
import { getRoutes } from '../../utils/Routes';
import { PageId } from '../../utils/Enums';
import styled from 'styled-components';
import { PageContainer } from '../../stylesGlobal/CommonStyles';
import { motion, useElementScroll, useTransform } from 'framer-motion';
import logo from "../../assets/logo_small.svg"
import { colors, pagePadding, screenSize, zIndex } from '../../stylesGlobal/Variables';
import { useTranslationTyped } from '../../utils/i18n/i18nHelper';
import { useSetRecoilState } from 'recoil';
import { treatmentFlowState } from '../../recoil/Atoms';
import { Persistence } from '../../components/persistanceModal/PersistenceModal';
import BackgroundCircles from '../../components/shared/BackgroundCircles';
import home_cover from '../../assets/home_cover_img.png';
import { ApiClient } from '../../api/ApiClient';
import { deleteTokens, saveTokens } from '../../api/AuthTokenHelper';
import { useLogout } from "../../utils/customHooks/useLogout"
import { SpinnerPage } from '../../components/shared/SpinnerPage';

const HomeWrapper = styled(PageContainer)`
    display:flex;
    flex-direction:column;
    // justify-content:flex-end;
    overflow-y: auto;
    padding: 0;
    height:100%;

    .home__overlay{
        position:relative;
        width:100%;
        top:0;
        left:0;
        // height: 100%;
        z-index:${zIndex.one};
        background:${colors.darkGradient};
        padding-top:calc(4rem + env(safe-area-inset-top));
        overflow: hidden;
        flex: 0 1 auto;
    }

    .home__topBar{
      width:100%;
      display:flex;
      justify-content:space-between;
      align-items:center;
      margin-bottom:3rem;
      padding: 0 2.5rem;
    }

    .home_user{
      display:flex;
      align-items:center;
      z-index: 2;
    
      p{
        font-size:1.2rem;
        color:${colors.white};
        font-weight:400;
        margin-left:1.3rem;
      }

    }

    .home__header{
        display:flex;
        flex-direction:column;
        justify-content:start;
        min-height: 52vh;
    }

    .home__itemList{
      z-index:${zIndex.two};
      position:absolute;
      top:52%;
      left:0;
      bottom:0;
      display:flex;
      flex-direction:column;
      justify-content:space-between;
      width: 100%;
      padding:0 ${pagePadding.leftRight} 0 ${pagePadding.leftRight};
    }

    .home__title{
        font-size:2.1rem;
        color:${colors.white};
        font-weight:600;
    }

    .home__title__green {
      color: ${colors.green}
    }

    .home__title__blue {
      color: ${colors.blue}
    }

    .home__title__white {
      color: ${colors.white}
    }

    .home__title_container {
      padding:0 ${pagePadding.leftRight} 4rem ${pagePadding.leftRight};
    }

    .home__subtitle{
        margin-top:1.5rem;
        font-size:1.4rem;
        color:${colors.white};
        opacity:.65;
        font-weight:400;
    }

    .home__button {
      width: 90%;
      border-radius: 1rem;
      display: flex;
      justify-content: center;
      padding: 2rem 0;
      color: ${colors.white};
      position: absolute;
      bottom: 2rem;
      background: ${colors.blue};
      left: 0;
      right: 0;
      margin: 0 auto;
      font-size:1.4rem;
      font-weight: normal;
    }

    .home__bottom__container {
      position: relative;
      z-index: 5;
      // display: flex;
      // flex-direction: column;
      // align-items: center;
      // height: 100%;
      flex: 1 1 auto;

      img {
        position: absolute;
        bottom: 13vh;
        height: 57vh;
        right: 0;
      }
    }

    h1 {
        font-size:1.4rem;
        font-weight:600;
        color:${colors.darkBlue};
    }


    .home__version {
        position: absolute;
        bottom: 1.5rem;
        left: 2.5rem;
        color: ${colors.darkBlue}
    }

    @media (min-width: ${screenSize.size_m}){
      .home__topBar{
        margin-bottom:4rem;
      }
      .home_user{    
        p{
          font-size:1.4rem;
        }
      }

      .home__title{
        font-size:2.4rem;
      }
      .home__subtitle{
        margin-top:2rem;
        font-size:1.6rem;
      }
      .home__bottom__container {
        img {
          bottom: 12vh;
          height: 58vh;
          right: 0;
        }
      }

    }

    @media (min-width: ${screenSize.size_l}){
      .home__title{
        font-size:2.8rem;
      }
    }

    @media (min-width: ${screenSize.size_xl}){
      .home__topBar{
        margin-bottom:5rem;
      }
      .home__title{
        font-size:3rem;
        font-weight:700;
      }
      .home__itemList{
        top:48%;
      }
      .home__overlay{
        padding-top:calc(4rem + env(safe-area-inset-top));
      }
      .home__bottom__container {
        img {
          bottom: 10vh;
          height: 58vh;
          right: 0;
        }
      }
    }

    @media (min-height: 800px){
      .home__topBar{
        margin-bottom:5rem;
      }
      .home__title{
        font-size:3rem;
        font-weight:700;
      }
      .home__itemList{
        top:50%;
      }
      .home__overlay{
        padding-top:calc(3rem + env(safe-area-inset-top));
      }
    }

    @media (min-height: 830px){
      .home__itemList{
        top:45%;
      }
    }
`

const Home: React.FC = () => {

  const history = useHistory()
  const { flowWithPageId: flowWithId } = getRoutes()
  const t = useTranslationTyped()

  const setTreatmentState = useSetRecoilState(treatmentFlowState)
  const homeWrapperRef = useRef<HTMLElement>(null)
  const logout = useLogout

  const [loading, setLoading] = useState(false)

  const { scrollY } = useElementScroll(homeWrapperRef)
  const y = useTransform(scrollY, [0, 100], [0, -70])
  const opacity = useTransform(scrollY, [0, 100], [1, 0])

  const handleStartProcess = () => {
    const toPage = flowWithId(PageId.MissingTeeth)
    setTreatmentState([PageId.MissingTeeth])
    history.push(toPage)
  }

  const getToken = async () => {
    const response = await ApiClient.getTokenWithEmailAndPassword()
    if (!response.error && response.response) {
      saveTokens(response.response)
      return response.response.access_token
    }
  }

  useEffect(() => {
    const checkForToken = async () => {
      setLoading(true)
      await getToken();
      const userInfo = await ApiClient.getUserInfo(logout)
      if (!userInfo.error) {
        setLoading(false)
      } else {
        await deleteTokens()
        await getToken();
        const secondTryUserInfo = await ApiClient.getUserInfo(logout)
        if (!secondTryUserInfo.error) {
          setLoading(false)
        } else {
          console.error('Failed to get token twice.')
          throw new Error('Failed to get token twice. Probably no network connection.')
        }
      }
    }
    checkForToken()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (loading) {
    return <SpinnerPage />
  } else {
    return (
      <IonPage>
        <IonContent
          scrollEvents={true}
          color="transparent"
          forceOverscroll={false}
        >
          <HomeWrapper ref={homeWrapperRef}>
            <Persistence />
            <section className="home__overlay">
              <BackgroundCircles />
              <motion.section
                className="home__header"
                style={{ y, opacity }}
              >
                <section className="home__topBar">
                  <img src={logo} alt="uSmile logo" className="home__logo" />
                </section>
                <section className="home__title_container">
                  <h1 className="home__title home__title__green">
                    {t("home", "pageTitle", "title_1")}
                  </h1>
                  <h1 className="home__title home__title__blue">
                    {t("home", "pageTitle", "title_2")}
                  </h1>
                  <h1 className="home__title home__title__white">
                    {t("home", "pageTitle", "title_3")}
                  </h1>
                </section>
              </motion.section>
            </section>
            <section className="home__bottom__container">
              <img src={home_cover} alt="Home Cover" />
              <div className="home__button" onClick={handleStartProcess}>
                {t("home", "find_ideal_treatment", "title")}
              </div>
            </section>
          </HomeWrapper>
        </IonContent>
      </IonPage >
    );
  }
};

export default Home;
