import { Mixpanel } from "@ionic-native/mixpanel"
import { EnvHelper } from "../EnvHelper"
import * as Sentry from "@sentry/react"

if (EnvHelper.isProduction && EnvHelper.mixPanelsToken) {
    Mixpanel.init(EnvHelper.mixPanelsToken)
        .catch(e => {
            console.error(e, "Error initializing mixPanels")
            Sentry.captureException(e)
        })
        .then(val => {
            console.log("Initialized mixpanel", val)
        })
} else {
    console.log(`Mixpanel not initialized because env is ${EnvHelper.isProduction} or not a valid token`)
}
