import { IonPage, IonContent, useIonViewDidEnter, useIonViewDidLeave, IonToast } from '@ionic/react';
import React, { useState } from 'react';
import { useRecoilState } from 'recoil';
import Odontogram from '../../components/odontogram/Odontogram';
import OdontogramTutorial from '../../components/odontogram/OdontogramTutorial';
import { PictureComponent } from '../../components/odontogram/PictureComponent';
import { PriceCalculator } from '../../components/odontogram/PriceCalculator';
import { ButtonColor } from '../../components/shared/Button';
import GoBackButtonGroup from '../../components/shared/GoBackButtonGroup';
import Header from '../../components/shared/Header';
import Overlay from '../../components/shared/Overlay';
import SmallOverlay from '../../components/shared/SmallOverlay';
import { hasSeenRestorationTutorialState, selectedTeethState } from '../../recoil/Atoms';
import { getPageNumber } from '../../utils/HelperFunctions';
import { PageName } from '../../utils/Types';
import { OdontogramPageWrapper, ODONTOGRAM_TYPE } from './ImplantPage';
import HelpIcon from "../../assets/icons/help.svg"
import { useTranslationTyped } from '../../utils/i18n/i18nHelper';
import { getRoutes } from '../../utils/Routes';
import { ReactComponent as Check } from "../../assets/icons/check.svg"
import { useHistory } from 'react-router';
import add_restoration from '../../assets/add-restoration.gif';
import remove_restoration from '../../assets/remove-restoration.gif';

const ImplantPage: React.FC = () => {
    const [pageLoaded, setPageLoaded] = useState(false)
    const [isMounted, setIsMounted] = useState(false)
    const [toastMessage, setToastMessage] = useState("")
    const [odontogramLoaded, setLoadOdontogram] = useState(false)

    const t = useTranslationTyped()
    const history = useHistory()

    const [selectedTeeth, setSelectedTeeth] = useRecoilState(selectedTeethState)
    const [hasSeenTutorial, setHasSeenTutorial] = useRecoilState(hasSeenRestorationTutorialState);

    const onGoBack = () => {
        setSelectedTeeth({
            ...selectedTeeth,
            restoredIds: []
        })
        history.goBack()
    }

    const onClickReady = () => {
        history.push(getRoutes().choosePaymentMethod)
    }

    useIonViewDidLeave(() => {
        setPageLoaded(false)
    })

    useIonViewDidEnter(() => {
        setIsMounted(true)
        setPageLoaded(true)
    });

    const renderHelpIcon = () => {

        return <div className="teeth__help" onClick={() => setHasSeenTutorial(false)}>
            <img src={HelpIcon} alt="help" />
        </div>
    }

    return (
        <IonPage>
            <IonContent
                color="transparent"
            >
                <IonToast
                    isOpen={toastMessage !== ""}
                    onDidDismiss={() => setToastMessage("")}
                    message={toastMessage}
                    duration={3000}
                    position="top"
                    color="danger"
                />
                <OdontogramPageWrapper>
                    <Overlay>
                        <SmallOverlay>
                            <Header
                                title={t("restore", "pageTitle")}
                                subtitle={t("restore", "pageSubtitle")}
                                stepNumber={getPageNumber(PageName.Odontogram)}
                            />
                        </SmallOverlay>
                        <section className="odontogram__container">
                            {isMounted ? <PriceCalculator /> : null}
                            {renderHelpIcon()}
                            <PictureComponent />
                            {
                                isMounted ? <Odontogram
                                    pageLoaded={pageLoaded}
                                    odontogramType={ODONTOGRAM_TYPE.RESTORATION}
                                    setLoadOdontogram={setLoadOdontogram}
                                    setErrorMessage={setToastMessage} /> : null
                            }
                        </section>
                    </Overlay>
                    <GoBackButtonGroup
                        onGoBack={onGoBack}
                        showSmallButtons={true}
                        disabled={!odontogramLoaded}
                        readyButton={{
                            color: ButtonColor.blue,
                            onClick: onClickReady,
                            iconSVG: <Check />,
                            label: t("generic", "ready"),
                            disabled: selectedTeeth.restoredIds.length === 0
                        }}
                    />
                    <OdontogramTutorial
                        title_one={t("restore", "stepOneTutorialTitle")}
                        subtitle_one={t("restore", "stepOneTutorialSubTitle")}
                        image_one={add_restoration}
                        title_two={t("restore", "stepTwoTutorialTitle")}
                        subtitle_two={t("restore", "stepTwoTutorialSubTitle")}
                        image_two={remove_restoration}
                        hasSeenTutorial={hasSeenTutorial!}
                        setHasSeenTutorial={setHasSeenTutorial} />
                </OdontogramPageWrapper>
            </IonContent>
        </IonPage>
    )
}

export default ImplantPage;