import React from "react"
import { IonContent, IonPage } from "@ionic/react"
import Spinner from "./Spinner"
import styled from "styled-components"
import { PageContainer } from "../../stylesGlobal/CommonStyles"
import { colors } from "../../stylesGlobal/Variables"

const SpinnerModalWrapper = styled(PageContainer)`
    background:${colors.darkGradient};
    .spinner__Container{
        display:flex;
        flex-direction:column;
        justify-content:center;
        align-items:center;
        margin-top:30%;
    }
    @media (min-height: 800px){
        .spinner__Container{
            margin-top:50%;
        }
    }
`

export const SpinnerPage = () => {
    return (
        <IonPage>
            <IonContent scrollEvents={false}>
                <SpinnerModalWrapper>
                    <section className="spinner__Container">
                        <Spinner theme="light" />
                    </section>
                </SpinnerModalWrapper>
            </IonContent>
        </IonPage>
    )
}
