import { createGlobalStyle } from "styled-components"
import FivoSansModern from "../assets/fonts/FivoSansModern-Regular.otf"
import FivoSansModernBold from "../assets/fonts/FivoSansModern-Bold.otf"
import FivoSansModernMedium from "../assets/fonts/FivoSansModern-Medium.otf"
import Rubik from "../assets/fonts/Rubik-Regular.otf"
import RubikBold from "../assets/fonts/Rubik-Bold.otf"
import RubikMedium from "../assets/fonts/Rubik-Medium.otf"

export const GlobalStyles = createGlobalStyle`

@font-face {
  font-family: 'FivoSansModern';
  font-style: normal;
  font-weight: 400;
  src:
    local('FivoSansModern'),
    url(${FivoSansModern}) format('opentype');
}

@font-face {
  font-family: 'FivoSansModern';
  font-style: normal;
  font-weight: 700;
  src:
    local('FivoSansModern-Bold'),
    url(${FivoSansModernBold}) format('opentype');
}

@font-face {
  font-family: 'FivoSansModern';
  font-style: normal;
  font-weight: 600;
  src:
    local('FivoSansModern-Medium'),
    url(${FivoSansModernMedium}) format('opentype');
}

@font-face {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 400;
  src:
    local('Rubik'),
    url(${Rubik}) format('opentype');
}

@font-face {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 700;
  src:
    local('Rubik-Bold'),
    url(${RubikBold}) format('opentype');
}

@font-face {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 600;
  src:
    local('Rubik-Medium'),
    url(${RubikMedium}) format('opentype');
}

    *,
    *::after,
    *::before  {
        margin: 0;
        padding: 0;
        box-sizing: border-box;
        -webkit-tap-highlight-color: transparent;
    }

    html {
        font-size: 62.5%;
        height: 100%;
     }

    body {
        margin: 0;
        font-family: Rubik, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        -webkit-overflow-scrolling: touch;
    }

    h1,h2,h3,h4,h5,h6,p,a,input,label,button{
        font-family: FivoSansModern, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    }

    ul{
        list-style:none;
    }

    a{
        text-decoration:none;
    }

    img{
        max-width:100%;
    }
    
    @media (min-width: 600px) {
      ion-app {
        height: 700px;
        width: 390px;
        left: unset !important;
        right: unset !important;
        top: unset !important;
        bottom: unset !important;
        display: flex;
        position: relative;
        flex-direction: row;
        background-color: white;
        border-radius: 20px;
      }

      #root {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      body {
          background: linear-gradient(153deg,#264B5A 0%,#1D1547 100%);
      }
    }
`
