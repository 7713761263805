import {
    Engine,
    Scene,
    SceneLoader,
    Color4,
    Color3,
    PBRMaterial,
    Vector3,
    ArcRotateCamera,
    CubeTexture,
    AbstractMesh,
    TransformNode,
    Animation
} from "babylonjs";
import enviromentMap from '../../assets/environmentSpecular.env';
import { getMouthAsset } from "./MouthAsset";
import { ODONTOGRAM_TYPE } from "../../pages/odontogram/ImplantPage";
import { EventNames, trackEvent } from "../../utils/mixPanels/MixPanelHelper";
import { Resource } from "../../utils/Enums";

export type ToothType = {
    id: string | undefined,
    implantNode: TransformNode | undefined,
    implantCrown: AbstractMesh | undefined,
    originalPosition: Vector3 | undefined
}

const loadScene = (engine: Engine) => {
    return new Promise<Scene>((resolve, reject) => {
        SceneLoader.ShowLoadingScreen = false;
        SceneLoader.Load(getMouthAsset(), "", engine, (loadedScene) => {
            resolve(loadedScene);
        },
            () => { },
            (scene, message, exception) => {
                console.error(message, exception);
                reject(message);
            })
    })
}

export const sceneSetup = async (engine: Engine) => {
    const scene = await loadScene(engine);
    scene.clearColor = Color4.FromColor3(Color3.White());
    return scene;
}

export const setupImplant = (scene: Scene | null, name: string) => {
    if (scene) {
        const implant = scene.getTransformNodeByID(name);
        if (implant) {
            implant.setEnabled(false);
        }
        return implant;
    }
    return null;
}

export const getMaterial = (scene: Scene | null) => {
    const randomTeeth = scene && scene.getMeshByID("11");
    const material = randomTeeth && randomTeeth.material as PBRMaterial;
    return material;
}

export const setupWhiteTeethMaterial = (material: PBRMaterial | null, color: Color3) => {
    const materialClone = material && material.clone("whiteTeeth");
    if (materialClone) {
        materialClone.albedoColor = color;
    }
    return materialClone;
}

export const setupCameraAndEffects = (scene: Scene | null, canvas: HTMLCanvasElement, setscreenLoaded: (loading: boolean) => void) => {
    if (scene) {
        const model = scene.getMeshByID("__root__");
        const cameraPosition = model ? new Vector3(model.position.x, model.position.y - 1, model.position.z) : new Vector3(0, 0, 0);
        const camera = new ArcRotateCamera("camera", 1.57, 1.6, 40, cameraPosition, scene);
        camera.minZ = 0.01;
        camera.lowerRadiusLimit = 20;
        camera.upperRadiusLimit = 40;
        camera.lowerAlphaLimit = 0.4;
        camera.upperAlphaLimit = 2.6;
        camera.lowerBetaLimit = 1.47;
        camera.upperBetaLimit = 1.7;
        camera.allowUpsideDown = false;
        camera.wheelPrecision = 150;
        camera.fov = 0.2;
        camera.panningSensibility = 0;
        camera.attachControl(canvas, true);

        const postProcessColor = new BABYLON.ImageProcessingPostProcess("processing", 1.0, camera as any);
        new BABYLON.FxaaPostProcess("fxaa", 1.0, camera as any);
        postProcessColor.exposure = 1.20;
        postProcessColor.contrast = 1.30;

        const hdrTexture = new CubeTexture(enviromentMap, scene, undefined, undefined, undefined, () => {
            setscreenLoaded(true);
        });
        scene.environmentTexture = hdrTexture;
    }
}

export const setupPointerEventsOverTeeth = (
    scene: Scene | null,
    setSelectedTeethRef: React.RefObject<(teeth: ToothType) => void>,
) => {
    if (scene) {
        scene.onPointerObservable.add((evt) => {
            if (evt && evt.pickInfo && evt.pickInfo.hit && evt.pickInfo.pickedMesh && evt.event.button === 0) {
                const selected = evt.pickInfo.pickedMesh as AbstractMesh;
                const id = Number(selected.id);
                if (id && id >= 11 && id <= 48 && setSelectedTeethRef.current) {
                    setSelectedTeethRef.current(
                        {
                            id: id.toString(),
                            implantCrown: selected,
                            implantNode: undefined,
                            originalPosition: undefined
                        }
                    )
                }
            }
        }, BABYLON.PointerEventTypes.POINTERTAP);
    }
}

export const addImplant = (
    teeth: ToothType,
    scene: Scene,
    upImplant: TransformNode,
    downImplant: TransformNode,
    implantedTeeth: ToothType[],
    restoredTeeth: ToothType[],
    darkMaterial: PBRMaterial,
    whiteMaterial: PBRMaterial,
    setimplantedTeeth: (teeth: ToothType[]) => void,
    setrestoredTeeth: (teeth: ToothType[]) => void,
    odontogramType: ODONTOGRAM_TYPE,
    setErrorMessage: (message: string) => void) => {
    const id = Number(teeth.id);
    const teethMesh = scene.getMeshByID(id.toString());
    if (id >= 11 && id <= 28 && teethMesh && upImplant) {
        selectTeeth(teeth, scene, teethMesh, 'UP', upImplant, implantedTeeth, restoredTeeth, darkMaterial, whiteMaterial, setimplantedTeeth, setrestoredTeeth, odontogramType, setErrorMessage);
    } else if (id && id >= 31 && id <= 48 && teethMesh && downImplant) {
        selectTeeth(teeth, scene, teethMesh, 'DOWN', downImplant, implantedTeeth, restoredTeeth, darkMaterial, whiteMaterial, setimplantedTeeth, setrestoredTeeth, odontogramType, setErrorMessage);
    }
}

const ifSelectedTeethHasImplantRemoveImplant = (
    mesh: AbstractMesh,
    implantedTeeth: ToothType[],
    setimplantedTeeth: (teeth: ToothType[]) => void,
    darkMaterial: PBRMaterial | null,
    removeImplant?: boolean) => {

    let implantExists = false;
    implantedTeeth.forEach((t, i) => {
        if (t.id === mesh.id) {
            if (removeImplant) {
                t.implantCrown!.position = new Vector3(t.originalPosition!.x, t.originalPosition!.y, t.originalPosition!.z);
                if (t.implantNode) {
                    t.implantNode.dispose();
                }
                t.implantCrown!.material = darkMaterial;
                const newImplantedTeeth = [...implantedTeeth];
                newImplantedTeeth.splice(i, 1);
                setimplantedTeeth(newImplantedTeeth);
            }
            implantExists = true;
        }
    })
    return implantExists;
}

const createImplantAnimation = (
    scene: Scene,
    mesh: AbstractMesh,
    side: string,
    implant: TransformNode,
    implantedTeeth: ToothType[],
    restoredTeeth: ToothType[],
    setImplantedTeeth: (teeth: ToothType[]) => void,
    whiteMaterial: PBRMaterial | null,) => {

    const originalPosition = mesh.position;
    const newPosition = new Vector3(mesh.position.x, side === 'UP' ? mesh.position.y - 1 : mesh.position.y + 1, mesh.position.z);

    const duplicateImplant = implant.clone("implantClone", implant.parent);

    if (duplicateImplant) {
        const newImplantedTeeth = implantedTeeth.slice(0);
        newImplantedTeeth.push({
            id: mesh.id,
            implantCrown: mesh,
            implantNode: duplicateImplant,
            originalPosition: new Vector3(originalPosition.x, originalPosition.y, originalPosition.z)
        })
        setImplantedTeeth(newImplantedTeeth);

        duplicateImplant.position = new Vector3(mesh.position.x, mesh.position.y + (side === 'UP' ? -1 : 1), mesh.position.z);
        mesh.visibility = 0;
        mesh.material = whiteMaterial;

        const implanthAnimation = new Animation("implantAnimation", "position.y", 60, Animation.ANIMATIONTYPE_FLOAT, Animation.ANIMATIONLOOPMODE_CYCLE);
        const implantKeys = [];
        implantKeys.push({ frame: 0, value: duplicateImplant.position.y })
        implantKeys.push({ frame: 100, value: duplicateImplant.position.y + (side === 'UP' ? 1.2 : -1.2) })
        implanthAnimation.setKeys(implantKeys);

        scene.beginDirectAnimation(duplicateImplant, [implanthAnimation], 0, 100, false, 1, () => {
            mesh.visibility = 1;
            const teethAnimation = new Animation("teethAnimation", "position.y", 60, Animation.ANIMATIONTYPE_FLOAT, Animation.ANIMATIONLOOPMODE_CYCLE);
            const keys = [];
            keys.push({ frame: 0, value: newPosition.y })
            keys.push({ frame: 100, value: originalPosition.y })
            teethAnimation.setKeys(keys);
            scene.beginDirectAnimation(mesh, [teethAnimation], 0, 100, false);
        });
    }
}

const createRestoration = (mesh: AbstractMesh,
    restoredTeeth: ToothType[],
    setRestoredTeeth: (teeth: ToothType[]) => void,
    darkMaterial: PBRMaterial | null,
    whiteMaterial: PBRMaterial | null,
    selectedTooth: ToothType,
) => {

    let implantExists = false;
    restoredTeeth.forEach((t, i) => {
        if (t.id === mesh.id && t.implantCrown) {
            t.implantCrown.material = darkMaterial;
            const newRestoredTeeth = [...restoredTeeth];
            newRestoredTeeth.splice(i, 1);
            setRestoredTeeth(newRestoredTeeth);
            implantExists = true;
        }
    })

    if (!implantExists) {
        mesh.material = whiteMaterial;
        const newRestoredTeeth = restoredTeeth.slice(0);
        newRestoredTeeth.push({
            id: mesh.id,
            implantCrown: mesh,
            implantNode: undefined,
            originalPosition: mesh.position
        })
        setRestoredTeeth(newRestoredTeeth);
    }
    trackEvent({
        eventName: EventNames.SelectsTooth,
        toothId: selectedTooth.id!,
        addedOrRemoved: implantExists ? "REMOVED" : "ADDED",
        resource: Resource.ToothRestoration,
    })
}

const selectTeeth = (
    tooth: ToothType,
    scene: Scene | null,
    mesh: AbstractMesh,
    side: string,
    implant: TransformNode,
    implantedTeeth: ToothType[],
    restoredTeeth: ToothType[],
    darkMaterial: PBRMaterial | null,
    whiteMaterial: PBRMaterial | null,
    setImplantedTeeth: (teeth: ToothType[]) => void,
    setRestoredTeeth: (teeth: ToothType[]) => void,
    odontogramType: ODONTOGRAM_TYPE,
    setErrorMessage: (message: string) => void) => {

    if (odontogramType === ODONTOGRAM_TYPE.IMPLANT) {
        const bloquedTeeths = ["48", "38", "18", "28"];
        if (bloquedTeeths.indexOf(tooth.id!) > -1) {
            setErrorMessage("No se pueden colocar implantes en las muelas de juicio")
        } else {
            const implantExists = ifSelectedTeethHasImplantRemoveImplant(mesh, implantedTeeth, setImplantedTeeth, darkMaterial, true);
            if (!implantExists && scene) {
                createImplantAnimation(scene, mesh, side, implant, implantedTeeth, restoredTeeth, setImplantedTeeth, whiteMaterial);
                trackEvent({
                    eventName: EventNames.SelectsTooth,
                    toothId: tooth.id!,
                    addedOrRemoved: implantExists ? "REMOVED" : "ADDED",
                    resource: Resource.FixedImplant,
                })
            }
        }
    } else {
        const implantExists = ifSelectedTeethHasImplantRemoveImplant(mesh, implantedTeeth, setImplantedTeeth, darkMaterial);

        if (implantExists) {
            setErrorMessage("No se puede aplicar una reparacion. Hay un implante colocado en ese lugar.")
        } else {
            createRestoration(mesh, restoredTeeth, setRestoredTeeth, darkMaterial, whiteMaterial, tooth);
        }
    }
}