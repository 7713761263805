import { Capacitor } from "@capacitor/core"

export const isProduction = process.env.NODE_ENV === 'production'
const isDevelopment = process.env.NODE_ENV === 'development' || !process.env.NODE_ENV

const envType =
    (
        (isProduction && (process.env.REACT_APP_ENV_TYPE || "prod"))
        || (isDevelopment && "dev-local")
    ) as string

const sentryDSN = 'https://9c464e58eea948458374a03f493e6bbc@o256449.ingest.sentry.io/5424613'
const buildName = envType + "-" + (process.env.REACT_APP_VERSION || "Neutral")

const sentryTracesSampleRate = process.env.REACT_APP_SENTRY_SAMPLE_RATE
    ? parseInt(process.env.REACT_APP_SENTRY_SAMPLE_RATE, 10) : 0.1

const loginInfo = {
    username: process.env.REACT_APP_CLIENT_USERNAME,
    password: process.env.REACT_APP_CLIENT_PASSWORD,
    grant_type: "password",
    client_id: process.env.REACT_APP_CLIENT_ID,
    client_secret: process.env.REACT_APP_CLIENT_SECRET,
    scope: "openid",
}

export const EnvHelper = {
    mapBoxApiToken: process.env.REACT_APP_MAPBOX_API_TOKEN,
    mapBoxStyle: process.env.REACT_APP_MAPBOX_STYLE,
    apiMock: process.env.REACT_APP_MOCK_API === "true",
    envType: process.env.REACT_APP_ENV_TYPE,
    isProduction,
    sentryDSN,
    buildName,
    loginUrl: process.env.REACT_APP_LOGIN_URL,
    logoutUrl: process.env.REACT_APP_LOGOUT_URL,
    redirectUrl: Capacitor.platform === "android"
        ? process.env.REACT_APP_REDIRECT_URL_ANDROID
        : (Capacitor.platform === "ios" ? process.env.REACT_APP_REDIRECT_URL_IOS
            : "http://localhost:8100/login"),
    authApiUrlOrigin: process.env.REACT_APP_API_AUTH_URL,
    apiUrl: process.env.REACT_APP_API_URL,
    clientId: process.env.REACT_APP_CLIENT_ID!,
    sentryTracesSampleRate: sentryTracesSampleRate,
    mixPanelsToken: process.env.REACT_APP_MIX_PANELS_TOKEN,
    onlyFinancingOption: process.env.REACT_APP_ONLY_FINANCING_OPTION === 'true',
    loginInfo
}