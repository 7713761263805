
import React, { forwardRef } from "react"
import styled, { css } from "styled-components"
import { colors, screenSize } from "../../stylesGlobal/Variables"
import { InputGeneralCSS } from "./Inputs"

type InputRadioWrapperProps = {
    selected: boolean,
    error?: boolean,
    withMarginBottom?: boolean,
    showBankIcon?: boolean,
    hasSubLabel: boolean,
    largeText?: boolean,
}
const InputRadioWrapper = styled.label<InputRadioWrapperProps>`
    ${InputGeneralCSS};
    min-height: 50px;
    display: flex;
    align-items:center;
    position: relative;
    user-select: none;
    align-items: center;
    justify-content: ${p => p.showBankIcon ? "space-between" : "center"};
    border: 1px solid ${p => p.error ? colors.red : p.selected ? colors.blue : "#DEE5F1"};
    padding-left: ${p => p.showBankIcon && "4.4rem"};
    ${p => p.withMarginBottom && css`
        margin-bottom:2rem;
    `}
    flex-direction: ${p => p.hasSubLabel ? "column" : "unset"};

    input{
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
    }

    .inputRadio__check{
        position: absolute;
        top: calc(50% - 9px);
        left: 5%;
        height: 18px;
        width: 18px;
        background-color: #DEE5F1;
        border-radius: 50%;
    }

    .inputRadio__label {
        padding-left: ${p => p.showBankIcon || p.largeText ? "2.5rem" : "unset"};
        text-align: ${p => p.largeText ? "center" : "unset"};
        font-size: ${p => p.largeText ? "1.1rem" : "unset"};
    }

    .inputRadio__subLabel {
        font-size: 1rem;
        color: gray;
    }

    input:checked{
        background-color: white;
        border:2px solid #DEE5F1;
    }

    input:checked ~ .inputRadio__check {
        background-color: white;
        border:2px solid #DEE5F1;
    }

    .inputRadio__check:after {
        content: "";
        position: absolute;
        display: none;
    }

    input:checked ~ .inputRadio__check:after {
        display: block;
    }

    .inputRadio__check:after {
        top: 2px;
        left: 2px;
        width: 10px;
        height: 10px;
        border-radius: 50%;
        background: ${colors.blue};
    }

    @media (min-width: ${screenSize.size_l}){
        .inputRadio__check{
            top: calc(50% - 11px);
            left: 5%;
            height: 22px;
            width: 22px;
        }

        .inputRadio__check:after {
            top: 2px;
            left: 2px;
            width: 14px;
            height: 14px;
        }
        .inputRadio__label {
            padding-left: ${p => p.showBankIcon || p.largeText ? "3rem" : "unset"};
            font-size: ${p => p.largeText ? "1.5rem" : "unset"};
        }
    
        .inputRadio__subLabel {
            font-size: 1.2rem;
        }
    }

`

type InputRadioProps = {
    label: string,
    selected: boolean,
    errorinput?: boolean,
    withMarginBottom?: boolean,
    bankIcon?: string,
    subLabel?: string,
    largeText?: boolean
}
type InputProps = React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
> & InputRadioProps

export const InputRadio = forwardRef<HTMLInputElement, InputProps>((props, ref) => (
    <InputRadioWrapper
        selected={props.selected}
        error={props.errorinput}
        withMarginBottom={props.withMarginBottom}
        showBankIcon={!!props.bankIcon}
        hasSubLabel={!!props.subLabel}
        largeText={props.largeText}
    >
        <span className="inputRadio__label">{props.label}</span>
        {props.subLabel && <span className="inputRadio__subLabel">{props.subLabel}</span>}
        {props.bankIcon && <img src={props.bankIcon} alt="bank" />}
        <input
            type="radio"
            ref={ref}
            name={props.name}
            value={props.value}
            defaultChecked={props.defaultChecked}
        />
        <span className="inputRadio__check" />
    </InputRadioWrapper>
));
