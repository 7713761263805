import React, { useState, useEffect, useRef } from 'react';
import {
    IonPage,
    IonContent,
} from '@ionic/react';
import { useHistory } from 'react-router';
import { AppointmentDataType, PageName } from '../../utils/Types';
import {
    appointmentConflictState,
    paymentInfoState,
    selectedAppointmentState,
    selectedDentistState,
    userLocationState
} from '../../recoil/Atoms';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { useTranslationTyped } from '../../utils/i18n/i18nHelper';
import { useMonthName } from '../../utils/customHooks/useMonthName';
import { useRecoilValue } from 'recoil';
import { useGetRequest } from '../../api/useGetRequest';
import { ApiClient } from '../../api/ApiClient';
import styled from 'styled-components';
import { PageContainer, HideScrollBar } from '../../stylesGlobal/CommonStyles';
import Overlay from '../../components/shared/Overlay';
import Header from '../../components/shared/Header';
import { pagePadding, zIndex, colors } from '../../stylesGlobal/Variables';
import SmallOverlay from '../../components/shared/SmallOverlay';
import DateItem from '../../components/appointmentChooser/DateItem';
import Spinner from '../../components/shared/Spinner';
import ListItem from '../../components/shared/ListItem';
import ErrorMessage from '../../components/shared/ErrorMessage';
import EmptyState from '../../components/shared/EmptyState';
import { getRoutes } from '../../utils/Routes';
import GoBackButtonGroup from '../../components/shared/GoBackButtonGroup';
import { getPageNumber } from '../../utils/HelperFunctions';
import { useLogout } from '../../utils/customHooks/useLogout';
import { ErrorModal } from '../../components/error/ErrorModal';

type AppointmentChooserWrapperProps = {
    loading: string,
    error: string,
}
const AppointmentChooserWrapper = styled(PageContainer) <AppointmentChooserWrapperProps>`
    background-color:${colors.white};

    .appointment__dateList{
        display:flex;
        padding-left:${pagePadding.leftRight};
        padding-top:1rem;
        overflow-x: auto;
        overflow-y: hidden;
        position:relative;
        height:8.5rem;
        width:100%;
        padding-bottom:1rem;

        ${HideScrollBar};
    }

    .appointment__dateList-container{
        position:absolute;
        top:0;
        left:0;
        width:100%;
        height:100%;
        display:flex;
        overflow-x: auto;
        overflow-y: hidden;
        padding-top:1rem;
        height:8.5rem;
        padding-left:${pagePadding.leftRight};
        padding-bottom:1rem;

        ${HideScrollBar};
    }

    .appointment__dateList-gradient{
        position:absolute;
        width:100%;
        height:100%;
        top:0;
        right:0;
        background:linear-gradient(90deg, rgba(0,0,0,0) 60%, rgba(38,47,90,.5) 100%);
        z-index:99;
        pointer-events:none;
    }

    .appointment__hoursList{
        position: absolute;
        width:100%;
        left:0;
        display:flex;
        flex-direction:column;
        z-index:${zIndex.two};
    }

    .appointment__stickyHourItem{
        height:100%;
        width:100%;
        display:block;
        position:sticky;
        font-size:1.4rem;
        font-weight:700;
        color:rgba(38,52,70,.67);
        padding:1rem ${pagePadding.leftRight};
        background-color:#F1F1F1;
        border-bottom:1px solid #DEE5F1;
        top:0;
    }

`

const DatePlaceholder = () => {
    return (
        <ul className="appointment__dateList">
            <div className="appointment__dateList-container">
                {new Array(9).fill("").map((_, i) => {
                    return (
                        <DateItem
                            key={`datePlaceholder-${i}`}
                            isPlaceholder={true}
                        />
                    )
                })}
            </div>
        </ul>
    )
}

const AppointmentChooserPage: React.FC = () => {
    const [selectedDate, setSelectedDate] = useState<string | null>()
    const history = useHistory()
    const t = useTranslationTyped()
    const getMonthName = useMonthName()

    const userLocation = useRecoilValue(userLocationState)
    const paymentInfo = useRecoilValue(paymentInfoState)
    const selectedDentist = useRecoilValue(selectedDentistState)
    const setSelectedAppointment = useSetRecoilState(selectedAppointmentState)
    const [appointmentConflict, setAppointmentConflict] = useRecoilState(appointmentConflictState)

    const listRef = useRef<HTMLIonContentElement>(null)
    const { contact } = getRoutes()
    const logout = useLogout()

    const { error, data, loading, handleRetry } = useGetRequest({
        key: `clinics by location: ${userLocation?.latitude}, ${userLocation?.longitude}, ${appointmentConflict}, ${selectedDentist}`,
        fetcher: async () => {
            if (selectedDentist) {
                return selectedDentist
            } else {
                if (!appointmentConflict) {
                    const clinics = await ApiClient.getClinicsByLocation({
                        ...userLocation!,
                        documentId: paymentInfo?.documentId!
                    }, logout)
                    return clinics.response
                } else {
                    return null
                }
            }
        }
    })

    useEffect(() => {
        if (data && !error) {
            const firstDate = Object.keys(data)[0]
            setSelectedDate(firstDate)
        }
    }, [data, error])


    const onGoBack = () => {
        history.goBack()
    }

    const handleDateSelected = (date: string) => async () => {
        setSelectedDate(date)
        if (listRef && listRef.current) {
            await listRef.current.scrollToTop(1)
        }
    }

    const getAppointmentsForDate = () => {
        if (selectedDate && data) {
            return data[selectedDate]
        } else {
            return {}
        }
    }

    const onAppointmentSelect = (appointment: AppointmentDataType) => () => {
        setSelectedAppointment(appointment)
        history.push(contact)
    }

    const onResolveAppointmentConflict = async () => {
        setAppointmentConflict(false)
    }

    const renderDateList = () => {
        return (
            <>
                {loading || !data
                    ? <DatePlaceholder />
                    : <ul className="appointment__dateList">
                        <div className="appointment__dateList-gradient" />
                        <div className="appointment__dateList-container">
                            {Object.keys(data).map((date, i) => {
                                const [dateString, month] = date.split("-")
                                return (
                                    <DateItem
                                        day={dateString}
                                        month={getMonthName(month)}
                                        onClick={handleDateSelected(date)}
                                        key={`${date}-${i}`}
                                        selected={selectedDate === date}
                                    />
                                )
                            })}
                        </div>
                    </ul>
                }
            </>
        )
    }

    const renderClinicList = () => {
        const appointmentsForSelectedDate = getAppointmentsForDate()
        if (loading) {
            return <Spinner theme="dark" />
        } else if (error || !data) {
            return <ErrorMessage
                label={t("location", "error")}
                handleRetry={handleRetry}
            />
        } else if (!appointmentsForSelectedDate || (!loading && Object.keys(appointmentsForSelectedDate).length === 0)) {
            return <EmptyState message={t("location", "emptySearchResults")} />
        } else if (!loading && Object.keys(appointmentsForSelectedDate).length > 0) {
            return Object.keys(appointmentsForSelectedDate).map((hour, i) => {
                return (
                    <li className="appointment__hourGroup" key={`${hour}-${i}`}>

                        <div
                            className="appointment__stickyHourItem"
                        >
                            {hour}
                        </div>
                        <ul>
                            {appointmentsForSelectedDate[hour].map((appointment, i) => {
                                return (
                                    <ListItem
                                        title={`${appointment.dentistName} ${appointment.dentistSurname}`}
                                        subtitle={appointment.type === "CLINIC" ?
                                            `${appointment.clinicName} ${appointment.clinicSurname}`
                                            : undefined}
                                        text={appointment.address}
                                        onClick={onAppointmentSelect(appointment)}
                                        key={i}
                                    />
                                )
                            })}
                        </ul>
                    </li>
                )
            })
        }
    }

    return (
        <IonPage>
            <IonContent
                scrollX={true}
                forceOverscroll={false}
            >
                <ErrorModal
                    onRetry={onResolveAppointmentConflict}
                    showErrorModal={appointmentConflict}
                    conflictModal
                />
                <AppointmentChooserWrapper
                    loading={loading.toString()}
                    error={error.toString()}
                >
                    <Overlay
                        large={true}
                        scroll={true}
                    >
                        <SmallOverlay>
                            <Header
                                title={t("chooseClinic", "pageTitle")}
                                stepNumber={getPageNumber(PageName.AppointmentChooser)}
                            />
                            {renderDateList()}
                        </SmallOverlay>
                        <IonContent
                            color="transparent"
                            scrollY={true}
                            forceOverscroll={false}
                            ref={listRef}
                        >
                            <ul className="appointment__hoursList">
                                {renderClinicList()}
                            </ul>
                        </IonContent>
                    </Overlay>
                    <GoBackButtonGroup
                        onGoBack={onGoBack}
                        showSmallButtons={false}
                    />
                </AppointmentChooserWrapper>
            </IonContent>
        </IonPage >
    )
}

export default AppointmentChooserPage