import { isPlatform } from "@ionic/react"
import styled, { css } from "styled-components"
import { pagePadding, zIndex, screenSize } from "./Variables"

export const HideScrollBar = css`
    &::-webkit-scrollbar {
            display: none;
    }
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
`

export const PageContainer = styled.section`
    width:100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    -webkit-overflow-scrolling:auto;
    max-width: 100%;
    overflow-x: hidden;
    
    ${HideScrollBar};
    
    @media (max-width: 600px) {
        height: -moz-available;          /* WebKit-based browsers will ignore this. */
        height: -webkit-fill-available;  /* Mozilla-based browsers will ignore this. */
        height: stretch;
        height: 100%;
    }
`

type GoBackContainerProps = {
    hideSmallDevices?: boolean
}

export const GoBackContainer = styled.section<GoBackContainerProps>`
    margin-bottom: 3rem;
    margin-bottom: ${isPlatform("ios") ? 'calc(3rem + env(safe-area-inset-bottom, 3rem))' : '3rem'};
    position:relative;
    bottom:0;
    left:0;
    right:0;
    display:${p => p.hideSmallDevices ? "none" : "flex"};
    justify-content:space-between;
    align-items:center;
    z-index:${zIndex.four};
    padding:0 ${pagePadding.leftRight} 0 ${pagePadding.leftRight};

    @media (min-width:${screenSize.size_l})
    and (min-height:670px){
        display:flex;
        flex-direction:column;
        justify-content:center;
        button{
            &:last-child{
                margin-top:2rem;
            }
        }
    }

    @media (min-height: 800px){
        // margin-bottom:4rem;
    }
`

export const GoBackSmallContainer = styled.section<GoBackContainerProps>`
    margin-bottom:2rem;
    position:relative;
    bottom:0;
    left:0;
    right:0;
    display:flex;
    justify-content:space-between;
    align-items:center;
    z-index:${zIndex.four};
    padding:0 1rem 0 1rem;
    bottom: 0px;

    @media (min-width:${screenSize.size_l})
    and (min-height:670px){
        display:none;
    }
`