export const screenSize = {
    size_s: "320px",
    size_m: "360px",
    size_l: "375px",
    size_xl: "411px",
}

export const colors = {
    white: "#ffffff",
    green: "#65E7B5",
    blue: "#2871DA",
    darkBlue: "#222B52",
    lightBlue: "#30D0DE",
    gray: "#88949E",
    red: "#F43535",
    darkGradient: "linear-gradient(180deg, #262F5A 0%, #1D2447 100%)",
    lightBlueGradient: "linear-gradient(243.77deg, #36E4F3 -2.85%, #30D0DE 75.66%)"
}

export const pagePadding = {
    leftRight: "2.5rem"
}

export const zIndex = {
    negative: -1,
    zero: 0,
    one: 1,
    two: 2,
    three: 3,
    four: 4,
    five: 5
}