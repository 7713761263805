import {
    ClinicOrDentistSearchBindingModel,
    ClinicsByNameViewModel
} from "./clientInterfaces/ClinicModel"
import {
    RegisterFinancingBindingModel,
    BaseFinancingBindingModel,
    FinancingBindingModel,
    ClinicsByLocationBindingModel
} from "./clientInterfaces/PaymentModel"
import {
    AvailableAppointments,
    BaseFinancing,
    FinancingOptions,
    FinancingOptionsCash,
    OrderInfo,
    RegisteredFinancing,
    UserInfo
} from "../utils/Types"
import { TokenViewModel } from "./clientInterfaces/AuthModel"
import { EnvHelper } from "../utils/EnvHelper"
import { RegisterAppointmentBindingModel } from "./clientInterfaces/AppointmentMode"
import { OrderBindingModel } from "./clientInterfaces/OrderModel"

export type ResponseWithPosibleError<T> = {
    response: T,
    error: false,
} | ResponseWithError

export type ResponseWithError = {
    response: null,
    error: true,
    errorCode: number,
}

export const apiUrl = {
    getToken: `${EnvHelper.authApiUrlOrigin}auth/realms/credentist/protocol/openid-connect/token`,
    getUserInfo: `${EnvHelper.authApiUrlOrigin}userinfo`,
    getAllClinics: `${EnvHelper.apiUrl}pacientes/clinicas`,
    getClinicsByNameOrDentist: `${EnvHelper.apiUrl}pacientes/clinicas/nombre/`,
    financings: `${EnvHelper.apiUrl}pacientes/financiaciones`,
    baseFinancing: `${EnvHelper.apiUrl}pacientes/financiaciones/tipo`,
    registerFinancing: `${EnvHelper.apiUrl}pacientes/financiaciones/registro`,
    appointments: `${EnvHelper.apiUrl}pacientes/citas`,
    order: `${EnvHelper.apiUrl}pacientes/ordenes`
}

export interface ApiFunctions {
    getToken(code: string, logout: () => void): Promise<ResponseWithPosibleError<TokenViewModel>>
    refreshToken(refreshToken: string, logout: () => void): Promise<ResponseWithPosibleError<TokenViewModel>>
    getUserInfo(logout: () => void): Promise<ResponseWithPosibleError<UserInfo>>
    getClinicsByLocation(query: ClinicsByLocationBindingModel, logout: () => void): Promise<ResponseWithPosibleError<AvailableAppointments>>
    getDentistsByName(query: ClinicOrDentistSearchBindingModel, logout: () => void): Promise<ResponseWithPosibleError<ClinicsByNameViewModel[]>>
    getFinancingOptions(query: FinancingBindingModel, logout: () => void): Promise<ResponseWithPosibleError<FinancingOptions>>
    getCashPayment(query: FinancingBindingModel, logout: () => void): Promise<ResponseWithPosibleError<FinancingOptionsCash>>
    getBaseFinancing(query: BaseFinancingBindingModel, logout: () => void): Promise<ResponseWithPosibleError<BaseFinancing>>
    registerFinancing(query: RegisterFinancingBindingModel, logout: () => void): Promise<ResponseWithPosibleError<RegisteredFinancing>>
    registerAppointment(query: RegisterAppointmentBindingModel, logout: () => void): Promise<ResponseWithPosibleError<null>>
    getOrder(query: OrderBindingModel, logout: () => void): Promise<ResponseWithPosibleError<OrderInfo>>

}