import flow from "./flow"
import { PaymentMethod } from "../../../Enums"

export const es = {
    flow,
    generic: {
        goBack: "Atrás",
        ready: "Listo!",
        return: "Volver",
        tryAgain: "Por favor, intenta de nuevo",
        retry: "Reintentar"
    },
    login: {
        loginButton: "Iniciar sesión"
    },
    errorModal: {
        title: "Ocurrió un error inesperado.",
        message: " Por favor, intentelo nuevamente.",
        button: "Reintentar"
    },
    conflictModal: {
        title: "El horario seleccionado ya no está disponible",
        message: "Seleccione una nueva cita",
        button: "Aceptar"
    },
    home: {
        pageTitle: {
            title_1: "La sonrisa que quieres",
            title_2: "fácil, accesible",
            title_3: "y financiada",
        },
        // pageSubtitle: "Encuentre rápidamente las mejores opciones para su sonrisa y obtenga financiamiento sin perder tiempo.",
        find_ideal_treatment: {
            title: "Descubrí tu tratamiento ideal",
            subtitle: "tu auto diagnóstico y presupuestación",//"Auto diagnóstico y presupuestación"
        },
        children_dentist: {
            title: "Niños",
            subtitle: ""
        },
        actionSheetTitle: "¿Desea cerrar la sesión de la aplicación?",
        logout: "Cerrar sesión",
        cancel: "Cancelar"
    },
    implant: {
        pageTitle: "Haz de cuenta que es tu maxilar/dentadura",
        pageSubtitle: "Tócalo para hacerlo rotar y selecciona la zona afectada.",
        stepOneTutorialTitle: "Instructivo para colocar implante",
        stepOneTutorialSubTitle: "Deslice el odontograma con un dedo para rotarlo. Para agregar un implante presione la pieza dental que le falta",
        stepTwoTutorialTitle: "Instructivo para eliminar implante",
        stepTwoTutorialSubTitle: "Para eliminar un implante colocado presione sobre el mismo",
        restoreAlert: {
            alertTitle: "¿Quieres hacer algún arreglo extra?",
            alertSubtitle: "Tal vez un diente oscuro, una caries, una rotura, etc",
            alertDescription: "",
            alertRestore: "Si",
            alertDontRestore: "No",
            alertCancel: "Cancelar",
        }
    },
    restore: {
        pageTitle: "Haz de cuenta que es tu maxilar/dentadura",
        pageSubtitle: "Tócalo para hacerlo rotar y selecciona la zona afectada.",
        stepOneTutorialTitle: "Instructivo para restaurar diente",
        stepOneTutorialSubTitle: "Deslice el odontograma con un dedo para rotarlo. Seleccione el/los diente/s que desea restaurar",
        stepTwoTutorialTitle: "Instructivo para eliminar restauracion",
        stepTwoTutorialSubTitle: "Para quitar una restauracion seleccione el diente que tiene la restauracion"
    },
    pictureTaker: {
        pageTitle: "Ahora hazte una foto",
        pageSubtitle: "Intenta que sea similar a la referencia",
    },
    location: {
        pageTitle: "Elige dónde atenderte",
        pageSubtitle: "Busca por la ubicación que te quede mejor o por nombre de la clínica",
        placeholder: "Busca un dentista",
        nextPageButtonLabel: "Elegir fecha y día!",
        emptySearchResults: "No se encontraron resultados",
        error: "Ocurrió un error al obtener los resultados",
        clinicSubtitle: "Clinica"
    },
    chooseClinic: {
        pageTitle: "Elige cuándo atenderte",
        emptyDateList: "No hay fechas disponibles",
        error: "Ocurrió un error al obtener las fechas disponibles",
        months: {
            jan: "Ene",
            feb: "Feb",
            mar: "Mar",
            apr: "Abr",
            may: "May",
            jun: "Jun",
            jul: "Jul",
            ago: "Ago",
            sep: "Set",
            oct: "Oct",
            nov: "Nov",
            dic: "Dic",
        },
    },
    contact: {
        pageTitle: "Completa los datos para que el profesional se comunique contigo",
        form: {
            name: {
                label: "Nombre",
                errorMessage: "El nombre no puede ser vacío",
            },
            phone: {
                label: "Teléfono",
                errorMessage: "El teléfono debe tener al menos 8 digitos",
            },
            address: {
                label: "Dirección",
                errorMessage: "La dirección debe tener al menos 5 caracteres",
            },
            email: {
                label: "Email",
                errorMessage: "El email ingresado no es válido",
            },
            firstName: {
                label: "Nombre",
                errorMessage: "El nombre ingresado no es válido",
            },
            lastName: {
                label: "Apellido",
                errorMessage: "El apellido ingresado no es válido",
            },
            income: {
                label: "Ingresos mensuales",
                errorMessage: "Los ingresos mensuales no son validoss",
            },
            sendButtonLabel: "Enviar",
        }
    },
    paymentMethod: {
        pageTitle: "Ahora, presupuestemos ",
        pageSubtitle: "Elige la forma de pago",
        aproximatePaymentAmount: "Monto aproximado",
        form: {
            errorMessage: "El metodo de pago no puede ser vacío",
            [PaymentMethod.Cash]: "Efectivo",
            [PaymentMethod.Financing]: "Financiado",
            [PaymentMethod.CashAndFinancing]: "Efectivo y financiado",
        },
        cashPaymentMethodForm: {
            amount: {
                errorMessage: "Debe ingresar un monto válido",
            },
            second_message: "Tienes que pagar el resto del importe en efectivo después de tu primera cita y antes de la segunda.",
            onSubmitButtonLabel: "Listo para seleccionar clínica",
        },
        financingMethodForm: {
            documentId: {
                placeholder: "Documento",
                errorMessage: "Debe ingresar un número ID válido",
            },
            documentValidatorIdChip: {
                placeholder: "Serie",
                errorMessage: "Debe ingresar un número de serie válido",
            },
            documentValidatorIdClassic: {
                placeholder: "Serie + Folio",
                errorMessage: "Debe ingresar un número de serie y folio válido",
            },
            cashAmount: {
                placeholder: "Monto deseado en efectivo",
                errorMessage: "Debe ingresar un monto válido",
                maxErrorMessage: "El monto en efectivo debe ser menor que el precio total",
            },
            onSubmitButtonLabel: "Ver opciones de financiamiento",
            couponPlaceholder: "Cupón (opcional)"
        },
    },
    chooseFinancingEntity: {
        pageTitle: "Entidad Financiera",
        pageSubtitle: "Por favor seleccione una entidad financiera para ver las opciones de financiamiento",
        form: {
            errorMessage: "Debe seleccionar una entidad financiera",
        },
        onSubmitButtonLabel: "Ver ofertas de financiamiento",
        defaultValue: "Seleccione un financiador..."
    },
    chooseFinancingOffer: {
        pageTitle: "Ofertas",
        pageSubtitle: "Por favor seleccione una oferta de financiamiento para pasar a selecionar la clínica",
        form: {
            errorMessage: "Debe seleccionar una oferta",
        },
        onSubmitButtonLabel: "Ver resumen",
        termsAndConditions: "Términos y Condiciones"
    },
    paymentSummary: {
        pageTitle: "Resumen",
        pageSubtitle: "Por favor revisa el resumen para pasar a selecionar la clínica",
        onSubmitButtonLabel: "Listo para seleccionar clínica",
        form: {
            cash: "Efectivo",
        },
        messages: {
            financingSecondAppointment: "Debe recibir la aprobación de su préstamo para tener una segunda cita. ",
            loanAutomaticallyApproved: "Su préstamo ha sido aprobado automáticamente por la entidad financiadora. ",
            emailReception: "Recibirás un correo electrónico de la entidad financiadora con instrucciones para confirmarlo. ",
            followInstructions: "Sígalos antes de su primera cita. ",
            cashPayment: "Tienes que pagar el resto del importe en efectivo después de tu primera cita y antes de la segunda. ",
            cashFinancingSecondAppointment: "Debe recibir la aprobación de su préstamo para tener una segunda cita. ",
        },
        convertionRateMessage1: "Cuota fija en",
        convertionRateMessage2: ", su valor en pesos Uruguayos se ajustará cada mes con la cotización vigente de ",
        convertionRateMessage3: "Equivale a UYU",
        convertionRateMessage4: "al día de hoy.",
    },
    persistenceModal: {
        message: "La última vez no se completó la consulta, ¿deseas continuar dónde estabas?",
        buttons: {
            yes: "Continuar donde estaba",
            no: "Comenzar de nuevo"
        }
    },
    orderSummary: {
        title: "Resumen de la orden",
        dateLabel: "Tu primer cita está agendada para el",
        addressLabel: "Debes estar presente en la siguiente direccion",
        clinicName: "Clinica",
        dentistName: "Odontólogo",
        treatments: "Tratamientos",
        budget: "presupuesto",
        termsAndConditionsLabel: "Haz click aqui para ver los ",
        termsAndConditions: "Terminos y Condiciones",
        approved: "APROBADO",
        pending: "PENDIENTE",
        rejected: "RECHAZADO",
        loanApprovalLabel: "Debes recibir la aprobación de tu financiación para poder tener una segunda cita",
        emailSentLabel1: `Te hemos enviado un mail a `,
        emailSentLabel2: ` con el resumen de tu orden`,
        emailSentLabel3: ` y solicitándote datos adicionales que requiere el banco para evaluar la aprobación de tu préstamo`,
        cashAmountLabel: "En efectivo",
        paymentPending: "Pago pendiente",
        paymentCompleted: "Pago realizado",
        payCashNow: "Paga ahora en efectivo",
        cashPaymentLabel: "Debes pagar la parte en efectivo despues de tu primer cita y antes de la segunda",
        orderNumber: "Número de orden",
        monthlyPayments: "pagos mensuales de",
        paymentMethod: {
            cashAndFinancing: "Financiado & Efectivo",
            cash: "Efectivo",
            financing: "Financiado",
        },
        startOver: "Agendar otra cita",
    }
}