import { useTranslationTyped } from "../i18n/i18nHelper"

export const useMonthName = () => {
    const t = useTranslationTyped()
    return (monthNumber: string) => {
        switch (monthNumber) {
            case "0":
                return t("chooseClinic", "months", "jan")
            case "1":
                return t("chooseClinic", "months", "feb")
            case "2":
                return t("chooseClinic", "months", "mar")
            case "3":
                return t("chooseClinic", "months", "apr")
            case "4":
                return t("chooseClinic", "months", "may")
            case "5":
                return t("chooseClinic", "months", "jun")
            case "6":
                return t("chooseClinic", "months", "jul")
            case "7":
                return t("chooseClinic", "months", "ago")
            case "8":
                return t("chooseClinic", "months", "sep")
            case "9":
                return t("chooseClinic", "months", "oct")
            case "10":
                return t("chooseClinic", "months", "nov")
            case "11":
                return t("chooseClinic", "months", "dic")
        }
    }
}