import { PageId } from "../../../Enums";

export default {
    step: "Paso",
    of: "de",
    [PageId.MissingTeeth]: {
        title: "Empecemos...\n¿cuál es tu situación?",
        option_1: {
            title: "Me falta algún diente",
            subtitle: "Quiero una solución",
        },
        option_2: {
            title: "Tengo todos mis dientes",
            subtitle: "¡Quiero mejorar mi sonrisa!",
        }
    },
    [PageId.FixedOrMobileImplants]: {
        title: "Aquí tienes dos caminos ¿cuál prefieres?",
        option_1: {
            title: "Implantes de dientes fijos",
            subtitle: "",
        },
        option_2: {
            title: "Prótesis móviles",
            subtitle: "",
        }
    },
    [PageId.TeethImprovements]: {
        title: "¿Qué aspecto de tus dientes quieres mejorar?",
        option_1: {
            title: "Alinear los dientes",
            subtitle: "Mis dientes estan torcidos y quiero alinearlos",
        },
        option_2: {
            title: "Blanqueo",
            subtitle: "Me gustaría blanquearme los dientes",
        },
        option_3: {
            title: "Restaurar diente",
            subtitle: "Necesito arreglar uno o más dientes",
        }
    },
    [PageId.Implants]: {
        title: "¿Con qué tipo de prótesis te sentirías mejor?",
        option_1: {
            title: "Con implantes",
            subtitle: "Es apoyado por implantes",
        },
        option_2: {
            title: "Sin implantes",
            subtitle: "Es apoyado por una pasta",
        }
    },
    [PageId.Alignment]: {
        title: "¿Qué opción para alinear tus dientes prefieres?",
        option_1: {
            title: "Alineadores invisibles",
            subtitle: "Los alineadores invisibles se usan 22 horas al día.",
        },
        option_2: {
            title: "Aparatos fijos",
            subtitle: "Es una opción más barata. Deben ajustarse con más frecuencia.",
        },
        option_3: {
            title: "Carillas o coronas",
            subtitle: "Solución con resultados inmediatos",
        }
    },
}