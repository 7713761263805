export enum Resource {
    FixedImplant = "FixedImplant",
    MobileProstheticWithImplant = "MobileProstheticWithImplant",
    MobileProstheticWithoutImplant = "MobileProstheticWithoutImplant",
    InvisibleAligner = "InvisibleAligner",
    Appliances = "Appliances",
    VeneerOrCrown = "VeneerOrCrown",
    Whitening = "Whitening",
    ToothRestoration = "ToothRestoration",
    FixedApplieances = "FixedApplieances",
}

export enum PaymentMethod {
    Cash = "Cash Payment",
    Financing = "Financing",
    CashAndFinancing = "Cash & Financing",
}

export enum PageId {
    MissingTeeth = "MissingTeeth",
    FixedOrMobileImplants = "FixedOrMobileImplants",
    TeethImprovements = "TeethImprovements",
    Implants = "Implants",
    Alignment = "Alignment",
}

export enum OfferType {
    preApproved = "PREAPROBADO",
}