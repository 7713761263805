import React, { useState, useRef, useCallback, useEffect } from "react"
import styled from "styled-components"
import { Engine, Scene, PBRMaterial, TransformNode } from 'babylonjs';
import 'babylonjs-loaders';
import {
  sceneSetup,
  setupImplant,
  getMaterial,
  setupWhiteTeethMaterial,
  setupCameraAndEffects,
  setupPointerEventsOverTeeth,
  addImplant,
  ToothType
} from "./OdontogramFunctions";
import { ODONTOGRAM_TYPE } from "../../pages/odontogram/ImplantPage";
import { selectedTeethState } from "../../recoil/Atoms";
import produce from "immer";
import { SelectedTeeth } from "../../utils/Types";
import { useRecoilState } from "recoil";
import { IonSpinner } from "@ionic/react";

const OdontogramWrapper = styled.section`
    display:flex;
    justify-content:center;
    align-items:center;
    position: relative;
    width: 100%;
    height: 100%;

    .canvas {
        width: 100%;
        outline: none;
        z-index: 3;
        opacity: 0;
        transition: opacity 0.5s ease-in-out;
        will-change: opacity;
        height:100%
    }

    .loader {
      position: absolute;
      top: 0;
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }


    .loaded {
      opacity: 1;
    }

`

interface Props {
  pageLoaded: boolean,
  odontogramType: ODONTOGRAM_TYPE,
  setErrorMessage: (message: string) => void
  setLoadOdontogram: React.Dispatch<React.SetStateAction<boolean>>
}

type CanvasObjects = {
  sceneRef: Scene | null,
  upImplantRef: TransformNode | null,
  downImplantRef: TransformNode | null,
  whiteMaterialRef: PBRMaterial | null,
  darkMaterialRef: PBRMaterial | null,
  implantMaterialRef: PBRMaterial | null
}

const Odontogram: React.FC<Props> = (props) => {
  const [screenLoaded, setScreenLoaded] = useState(false);
  const [implantedTeeth, setImplantedTeethOnState] = useState<ToothType[]>([]);
  const [restoredTeeth, setRestoredTeethOnState] = useState<ToothType[]>([]);
  const [selectedTeeths, setSelectedTeeth] = useRecoilState(selectedTeethState)

  const setSelectedTeethRef = useRef<((teeth: ToothType) => void) | null>(null);
  const canvas = useRef<HTMLCanvasElement | null>(null);
  const canvasObjects = useRef<CanvasObjects>({
    sceneRef: null,
    upImplantRef: null,
    downImplantRef: null,
    whiteMaterialRef: null,
    darkMaterialRef: null,
    implantMaterialRef: null
  });

  const engine = useRef<Engine | null>(null);

  useEffect(() => {
    props.setLoadOdontogram(screenLoaded)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [screenLoaded])

  const setImplantedTeeth = (teeth: ToothType[]) => {
    setSelectedTeeth(
      produce((prevState: SelectedTeeth) => {
        prevState.implantedIds = teeth.map(t => t.id!)
      })
    )
    setImplantedTeethOnState(teeth)
  }

  const setRestoredTeeth = (teeth: ToothType[]) => {
    setSelectedTeeth(
      produce((prevState: SelectedTeeth) => {
        prevState.restoredIds = teeth.map(t => t.id!)
      })
    )
    setRestoredTeethOnState(teeth)
  }

  setSelectedTeethRef.current = (teeth) => {
    addImplant(
      teeth,
      canvasObjects.current.sceneRef!,
      canvasObjects.current.upImplantRef!,
      canvasObjects.current.downImplantRef!,
      implantedTeeth,
      restoredTeeth,
      canvasObjects.current.darkMaterialRef!,
      canvasObjects.current.whiteMaterialRef!,
      setImplantedTeeth,
      setRestoredTeeth,
      props.odontogramType,
      props.setErrorMessage);
  }

  const loadImplantedTeeths = () => {
    const scene = canvasObjects.current.sceneRef;
    const bloquedTeeths = ["48", "38", "18", "28"];
    bloquedTeeths.forEach((teethId) => {
      const crown = scene?.getMeshByID(teethId);
      crown!.material = canvasObjects.current.implantMaterialRef;
    })
    const implantedTeeths = selectedTeeths.implantedIds.map((teethId) => {
      const crown = scene?.getMeshByID(teethId);
      if (bloquedTeeths.indexOf(teethId) === -1) {
        crown!.material = canvasObjects.current.whiteMaterialRef;
      }
      return {
        id: teethId,
        implantNode: undefined,
        implantCrown: crown!,
        originalPosition: crown!.position,
      }
    });
    setImplantedTeethOnState(implantedTeeths);
  }

  const loadRestoredTeeths = () => {
    const scene = canvasObjects.current.sceneRef;
    const restoredTeeths = selectedTeeths.restoredIds.map((teethId) => {
      const crown = scene?.getMeshByID(teethId);
      crown!.material = canvasObjects.current.whiteMaterialRef;
      return {
        id: teethId,
        implantNode: undefined,
        implantCrown: crown!,
        originalPosition: crown!.position,
      }
    });
    const implantedTeeths = selectedTeeths.implantedIds.map((teethId) => {
      const crown = scene?.getMeshByID(teethId);
      crown!.material = canvasObjects.current.whiteMaterialRef;
      return {
        id: teethId,
        implantNode: undefined,
        implantCrown: crown!,
        originalPosition: crown!.position,
      }
    });
    setImplantedTeethOnState(implantedTeeths);
    setRestoredTeethOnState(restoredTeeths);
  }

  const loadBabylon = useCallback(async () => {
    if (canvas.current) {
      const newEngine = new Engine(canvas.current, true, { preserveDrawingBuffer: true, stencil: true });
      canvasObjects.current.sceneRef = await sceneSetup(newEngine);
      const scene = canvasObjects.current.sceneRef;

      canvasObjects.current.downImplantRef = setupImplant(scene, "Dental_implant_down");
      canvasObjects.current.upImplantRef = setupImplant(scene, "Dental_implant_up");

      const originalTeethMaterial = getMaterial(scene);
      canvasObjects.current.darkMaterialRef = originalTeethMaterial;
      canvasObjects.current.whiteMaterialRef = setupWhiteTeethMaterial(originalTeethMaterial, BABYLON.Color3.White());
      canvasObjects.current.implantMaterialRef = setupWhiteTeethMaterial(originalTeethMaterial, BABYLON.Color3.Gray());

      setupCameraAndEffects(scene, canvas.current, setScreenLoaded);
      setupPointerEventsOverTeeth(scene, setSelectedTeethRef);
      newEngine.runRenderLoop(function () {
        scene.render();
      });

      if (props.odontogramType === ODONTOGRAM_TYPE.IMPLANT) {
        loadImplantedTeeths()
      } else {
        loadRestoredTeeths()
      }

      engine.current = newEngine;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (props.pageLoaded && !engine.current) {
      let unmounted = false

      const setup = async () => {
        await loadBabylon()
        if (unmounted && engine.current) {
          engine.current.dispose()
          engine.current = null
        }
      }

      setup()
      return () => {
        unmounted = true
        console.log("Se desmonto el odontograma")
        if (engine.current) {
          engine.current.stopRenderLoop();
          engine.current.dispose()
          engine.current = null
        }

      }
    }
  }, [props.pageLoaded, loadBabylon])


  useEffect(() => {
    implantedTeeth.forEach((i) => {
      if (i.implantCrown) {
        i.implantCrown.material = canvasObjects.current.implantMaterialRef;
      }
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.odontogramType])

  return (
    <OdontogramWrapper>
      {!screenLoaded && <div className="loader"><IonSpinner name="crescent" /></div>}
      <canvas className={`canvas${screenLoaded ? ' loaded' : ''}`} ref={canvas} />
    </OdontogramWrapper>
  )
}

export default Odontogram