import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { es } from "./locales/es"

const resources = {
    es: {
        translation: es
    },
};

i18n
    .use(initReactI18next)
    .init({
        fallbackLng: 'es',
        lng: "es",
        debug: true,
        interpolation: {
            escapeValue: false,
        },
        resources,
    });


export default i18n;

export type Dictionary = typeof es