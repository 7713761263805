import React from "react"
import styled from "styled-components"
import { screenSize, pagePadding, colors } from "../../stylesGlobal/Variables"
import { ReactComponent as Search } from "../../assets/icons/search.svg"
import { useTranslationTyped } from "../../utils/i18n/i18nHelper"

const SearchInputWrapper = styled.div`
    position:relative;
    display: flex;
    justify-content:center;
    align-items:center;

    .location__search{
        background-color:${colors.white};
        color:${colors.darkBlue};
        padding:1.2rem;
        text-align:center;
        border-radius:3rem;
        margin:1rem ${pagePadding.leftRight} 0 ${pagePadding.leftRight};
        width:90%;
        font-size:1.4rem;

        &:focus{
            outline:none;
        }
    }

    .location__searchIcon{
        position:absolute;
        left:4rem;
    }

    @media (min-width: ${screenSize.size_l}){
        .location__search{
            font-size:1.6rem;
        }
    }

    @media (min-width:${screenSize.size_xl}){
        .location__search{
            padding:1.8rem;
        }
    }
`

type Props = {
    onClick: () => void,
}

const SearchButton = (props: Props) => {
    const t = useTranslationTyped()

    return (
        <SearchInputWrapper>
            <button
                className="location__search"
                onClick={props.onClick}
            >
                {t("location", "placeholder")}
                <Search className="location__searchIcon" />
            </button>
        </SearchInputWrapper>
    )
}

export default SearchButton