import React, { useEffect, useRef, useState } from "react"
import { IonPage, IonContent, useIonViewDidLeave, useIonViewDidEnter } from "@ionic/react"
import {
    Plugins,
    CameraResultType,
    CameraDirection,
    FilesystemDirectory,
    Capacitor,
    CameraSource
} from '@capacitor/core';
import { ButtonColor } from "../../components/shared/Button";
import Overlay from "../../components/shared/Overlay";
import Header from "../../components/shared/Header";
import { useTranslationTyped } from "../../utils/i18n/i18nHelper";
import { PageContainer } from "../../stylesGlobal/CommonStyles";
import styled from "styled-components";
import { useHistory } from "react-router";
import { ReactComponent as Check } from "../../assets/icons/check.svg"
import GoBackButtonGroup from '../../components/shared/GoBackButtonGroup';
import SmallOverlay from '../../components/shared/SmallOverlay';
import { getRoutes } from "../../utils/Routes";
import { useRecoilState } from "recoil";
import { resourceState, teethPictureState } from "../../recoil/Atoms";
import picture_model from "../../assets/picture_model.png";
import * as Sentry from "@sentry/react"
import take_picture_icon from "../../assets/take_picture_icon.svg"
import { PageName, PictureInfo } from "../../utils/Types";
import { getPageNumber } from "../../utils/HelperFunctions";
import produce from "immer";
import { Resource } from "../../utils/Enums";
import "croppie/croppie.css";
import rotate_left_icon from '../../assets/rotate_left_icon.svg';
import rotate_right_icon from '../../assets/rotate_right_icon.svg';
import Croppie from 'croppie'

const PictureTakerPageWrapper = styled(PageContainer)`

    .pictureTaker__container{
        position:relative;
        left:0;
        display:flex;
        flex-direction:column;
        width: 100%;
        justify-content: center;
        background: black;
        border-bottom-left-radius: 20px;
        border-bottom-right-radius: 20px;
        margin-top: -2rem;
        height: calc(100% - 24rem);
    }

    .pictureTaker__cropContainer {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
    }

    .croppie-container .cr-boundary {
        border-radius: 19px;
    }

    .pictureTaker__actions {
        position: absolute;
        bottom: 2rem;
        left: 0;
        right: 0;
        z-index: 1;
        display: flex;
        justify-content: center;
        align-items: center;
        max-width: 54%;
        margin: 0 auto;
    }

    .pictureTaker__model {
        bottom: 2rem;
        position: relative;
    }

    .pictureTaker__picture{
        object-fit: cover;
        width: 100%;
    }

    .croppie-container .cr-viewport, .croppie-container .cr-resizer { 
        border-radius: 11px;
    }

    .croppie-container .cr-viewport, .croppie-container .cr-resizer {
        top: -57px;
    }

`

const IMAGE_FILE_NAME = "teethSelfie.txt"

const saveImageInFileSystem = async (path: string, name: string) => {
    try {
        const { Filesystem } = Plugins
        const fileName = name
        await Filesystem.writeFile({
            data: path,
            path: fileName,
            directory: FilesystemDirectory.Data
        });
        const finalPhotoUri = await Filesystem.getUri({
            directory: FilesystemDirectory.Data,
            path: fileName
        });

        return Capacitor.convertFileSrc(finalPhotoUri.uri);
    } catch (e) {
        console.error("Error saving image in filesystem directory.", e)
        Sentry.captureException(e)
        return null
    }
}

const PictureTakerPage: React.FC = () => {
    const [imageInfo, setImageInfo] = useRecoilState(teethPictureState)
    const [resource, setResource] = useRecoilState(resourceState)
    const t = useTranslationTyped()
    const history = useHistory()
    const cropContainer = useRef<HTMLDivElement | null>(null)
    const croppie = useRef<Croppie | undefined>(undefined)
    const [pageInView, setPageInView] = useState(true)

    const loadImageInCroppie = async () => {

        if (Capacitor.platform !== "web") {
            const { Filesystem } = Plugins
            let data = await Filesystem.readFile({
                path: IMAGE_FILE_NAME,
                directory: FilesystemDirectory.Data
            })

            croppie.current?.bind({
                url: 'data:image/jpeg;base64,' + data.data
            })
        } else {
            croppie.current?.bind({
                url: imageInfo!.picturePath
            })
        }
    }

    const setupsCroppie = () => {
        if (!croppie.current && cropContainer.current) {
            croppie.current = new Croppie(cropContainer.current!, {
                showZoomer: false,
                viewport: {
                    width: 290,
                    height: 160
                },
                enableOrientation: true
            })
        }

        loadImageInCroppie();
    }

    useEffect(() => {
        console.log("Entro mediante idPicture")
        if (cropContainer.current && imageInfo?.picturePath) {
            setupsCroppie()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [imageInfo?.idPicture])

    useIonViewDidLeave(() => {
        setPageInView(false)
        if (croppie.current) {
            croppie.current = undefined;
        }
    })

    useEffect(() => {
        if (pageInView && imageInfo?.picturePath) {
            setupsCroppie()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pageInView])

    useIonViewDidEnter(() => {
        setPageInView(true)
    });

    const takePicture = async () => {
        if (croppie.current) {
            setImageInfo({ picturePath: "", pictureCropedPath: "", idPicture: 0 })
            croppie.current = undefined;
        }
        const { Camera } = Plugins
        const image = await Camera.getPhoto({
            quality: 20,
            allowEditing: false,
            resultType: Capacitor.platform !== "web" ? CameraResultType.Base64 : CameraResultType.Uri,
            direction: CameraDirection.Front,
            preserveAspectRatio: true,
            source: CameraSource.Prompt,
        });
        const idPicture = Math.random();
        if (Capacitor.platform !== "web") {
            const imagePath = await saveImageInFileSystem(image.base64String!, IMAGE_FILE_NAME)
            if (imagePath && idPicture) {
                setImageInfo(produce((prevState: PictureInfo | null) => {
                    if (prevState) {
                        prevState.picturePath = imagePath
                        prevState.pictureCropedPath = ""
                        prevState.idPicture = idPicture
                    } else {
                        return {
                            picturePath: imagePath,
                            pictureCropedPath: "",
                            idPicture: idPicture
                        }
                    }
                }))
            }
        } else {
            setImageInfo({ picturePath: image.webPath!, pictureCropedPath: "", idPicture: idPicture })
        }
    }


    const onGoBack = () => {
        setResource(null)
        history.goBack()
    }

    const onClickReady = async () => {
        if (croppie.current) {
            const result = await croppie.current.result({
                type: 'base64',
                size: 'original',
                format: 'jpeg',
                quality: 0.5
            })
            let imagePath = undefined;
            if (Capacitor.platform !== "web") {
                imagePath = await saveImageInFileSystem(result, `teethSelfieCroped.jpg`)
            } else {
                imagePath = result
            }
            setImageInfo({ picturePath: imageInfo!.picturePath, pictureCropedPath: imagePath!, idPicture: imageInfo!.idPicture })
        }
        const { implant, restore } = getRoutes()
        history.push(resource === Resource.FixedImplant ? implant : restore)
    }

    const rotateLeft = () => {
        croppie.current?.rotate(90)
    }

    const rotateRight = () => {
        croppie.current?.rotate(-90)
    }

    const getPreview = () => {
        if (imageInfo?.picturePath && pageInView) {
            return <div className="pictureTaker__cropContainer" ref={cropContainer} />
        } else {
            return <img className="pictureTaker__model" src={picture_model} alt="mouth model" />
        }
    }

    return <IonPage>
        <IonContent
            color="transparent"
            scrollY={false}
        >
            <PictureTakerPageWrapper>
                <Overlay fullHeight={true}>
                    <SmallOverlay hideOverflow extraHeight={5.5}>
                        <Header
                            title={t("pictureTaker", "pageTitle")}
                            subtitle={t("pictureTaker", "pageSubtitle")}
                            stepNumber={getPageNumber(PageName.PictureTaker)}
                        />
                    </SmallOverlay>
                    <section className="pictureTaker__container">
                        {getPreview()}
                        <div className="pictureTaker__actions">
                            <img src={rotate_left_icon} alt={"left icon"} onClick={rotateLeft} />
                            <img className="pictureTaker__button" src={take_picture_icon} alt="camera icon" onClick={takePicture} style={{ margin: "auto" }} />
                            <img src={rotate_right_icon} alt={"right icon"} onClick={rotateRight} />
                        </div>
                    </section>
                </Overlay>

                <GoBackButtonGroup
                    onGoBack={onGoBack}
                    showSmallButtons={true}
                    readyButton={{
                        color: ButtonColor.blue,
                        onClick: onClickReady,
                        iconSVG: <Check />,
                        label: t("generic", "ready"),
                        disabled: !imageInfo
                    }}
                />
            </PictureTakerPageWrapper>
        </IonContent>
    </IonPage >
}

export default PictureTakerPage