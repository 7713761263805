import { RecoilValueReadOnly, selector } from "recoil"
import { PaymentMethod } from "../utils/Enums";
import { PaymentCashFinancing, PaymentFinancing, PaymentStateType } from "../utils/Types";
import { paymentInfoState } from "./Atoms";

const getPaymentTypedState = <T extends PaymentMethod>(paymentMethod: T) => {
    return selector({
        key: paymentMethod,
        get: ({ get }) => {
            const paymentState = get(paymentInfoState);
            if (paymentState && paymentState.paymentMethod === paymentMethod) {
                return paymentState as Extract<PaymentStateType, { paymentMethod: T }>
            }
            // throw new Error(`paymentMethod was not ${paymentMethod}.`)
            //TODO: esto se comento para que no explote cuando comenzamos de nuevo una recorrida
            console.error(`paymentMethod was not ${paymentMethod}.`)
            return {} as Extract<PaymentStateType, { paymentMethod: T }>
        },
    });
}

export const getCashAndFinancingPaymentState = getPaymentTypedState(PaymentMethod.CashAndFinancing)
export const getCashPaymentState = getPaymentTypedState(PaymentMethod.Cash)
export const getFinancingOrMixedPaymentState = selector({
    key: "getFinancingOrMixedPaymentState",
    get: ({ get }) => {
        const paymentState = get(paymentInfoState);
        if (paymentState && paymentState.paymentMethod !== PaymentMethod.Cash) {
            return paymentState
        }
        console.error(`paymentMethod was not financing or cashAndFinancing.`)
        return {} as RecoilValueReadOnly<PaymentFinancing | PaymentCashFinancing>
        // throw new Error(`paymentMethod was not financing or cashAndFinancing.`)
    }
});