import { ApiFunctions, ResponseWithPosibleError } from "./ApiConfig"
import {
    ClinicViewModel,
    ClinicOrDentistSearchBindingModel
} from "./clientInterfaces/ClinicModel"
import { timeoutPromise } from "../utils/HelperFunctions"
import {
    RegisterFinancingBindingModel,
    FinancingBindingModel,
    ClinicsByLocationBindingModel
} from "./clientInterfaces/PaymentModel"
import { TokenViewModel } from "./clientInterfaces/AuthModel"
import { FinancingOptions, OrderInfo, RegisteredFinancing, UserInfo } from "../utils/Types"
import { OfferType } from "../utils/Enums"
import { digestAppointmentData } from "./helpers/AppointmentHelper"
import { RegisterAppointmentBindingModel } from "./clientInterfaces/AppointmentMode"
import { OrderBindingModel } from "./clientInterfaces/OrderModel"

const agendas: ClinicViewModel[] = [
    {
        nombre: "Clinica dental",
        apellido: "Dientitos",
        id: 123,
        latitud: -34.4,
        longitud: -56.6,
        telefono: "099099099",
        distance: 100,
        domicilio: "Av Italia 1234",
        tipo: "CLINICA",
        agendas: [],
        idExternoAgenda: "cli_den_id",
        odontologos: [
            {
                nombre: "Diego",
                apellido: "Bonomo",
                id: "123",
                idExternoAgenda: "die_go",
                agendas: [
                    {
                        fechaInicio: "20200720T1700-0300",
                        fechaFin: "20200720T1800-0300"
                    },
                    {
                        fechaInicio: "20200720T1800-0300",
                        fechaFin: "20200720T1900-0300"
                    },
                    {
                        fechaInicio: "20200721T1700-0300",
                        fechaFin: "20200721T1800-0300"
                    },
                    {
                        fechaInicio: "20200721T1800-0300",
                        fechaFin: "20200721T1900-0300"
                    },
                ]
            },
            {
                nombre: "Mateo",
                apellido: "Rama",
                id: "456",
                idExternoAgenda: "mat_eo",
                agendas: [
                    {
                        fechaInicio: "20200720T1700-0300",
                        fechaFin: "20200720T1800-0300",
                    },
                    {
                        fechaInicio: "20200720T1800-0300",
                        fechaFin: "20200720T1900-0300",
                    },
                    {
                        fechaInicio: "20200721T2000-0300",
                        fechaFin: "20200721T2100-0300",
                    },
                    {
                        fechaInicio: "20200721T1900-0300",
                        fechaFin: "20200721T2000-0300",
                    },
                    {
                        fechaInicio: "20200730T1900-0300",
                        fechaFin: "20200730T2000-0300",
                    },
                ]
            },
            {
                nombre: "Martin",
                apellido: "Fagioli",
                id: "789",
                idExternoAgenda: "mar_tin",
                agendas: [
                    {
                        fechaInicio: "20200722T1700-0300",
                        fechaFin: "20200722T1800-0300",
                    },
                    {
                        fechaInicio: "20200722T1800-0300",
                        fechaFin: "20200722T1900-0300",
                    },
                    {
                        fechaInicio: "20200722T1900-0300",
                        fechaFin: "20200722T2000-0300",
                    },
                    {
                        fechaInicio: "20200722T2000-0300",
                        fechaFin: "20200722T2100-0300",
                    },
                    {
                        fechaInicio: "20200727T2000-0300",
                        fechaFin: "20200727T2100-0300",
                    },
                    {
                        fechaInicio: "20200728T2000-0300",
                        fechaFin: "20200728T2100-0300",
                    },
                ]
            },
        ]
    },
    {
        nombre: "Dental care",
        apellido: "Muelitas",
        idExternoAgenda: "den_car_id",
        latitud: -34.4,
        longitud: -56.6,
        domicilio: "Av Italia 4321",
        id: 321,
        telefono: "098098098",
        distance: 100,
        tipo: "CLINICA",
        agendas: [],
        odontologos: [
            {
                nombre: "Alfredo",
                apellido: "Blengio",
                id: "321",
                idExternoAgenda: "alf_redo",
                agendas: [
                    {
                        fechaInicio: "20200720T1700-0300",
                        fechaFin: "20200720T1800-0300",
                    },
                    {
                        fechaInicio: "20200720T1100-0300",
                        fechaFin: "20200720T1200-0300",
                    },
                    {
                        fechaInicio: "20200722T1400-0300",
                        fechaFin: "20200722T1500-0300",
                    },
                    {
                        fechaInicio: "20200722T1000-0300",
                        fechaFin: "20200722T1100-0300",
                    },
                    {
                        fechaInicio: "20200726T1000-0300",
                        fechaFin: "20200726T1100-0300",
                    },

                ]
            },
            {
                nombre: "Guido",
                apellido: "Dizioli",
                id: "654",
                idExternoAgenda: "gui-do",
                agendas: [
                    {
                        fechaInicio: "20200720T1700-0300",
                        fechaFin: "20200720T1800-0300",
                    },
                    {
                        fechaInicio: "20200720T1800-0300",
                        fechaFin: "20200720T1900-0300",
                    },
                    {
                        fechaInicio: "20200721T1700-0300",
                        fechaFin: "20200721T1800-0300",
                    },
                    {
                        fechaInicio: "20200723T1800-0300",
                        fechaFin: "20200723T1900-0300",
                    },
                    {
                        fechaInicio: "20200724T1800-0300",
                        fechaFin: "20200724T1900-0300",
                    },

                ]
            },
        ]
    },
    {
        nombre: "Bernardo",
        apellido: "Calcagno",
        idExternoAgenda: "ber_cal_id",
        latitud: -34.4,
        longitud: -56.6,
        id: 543,
        telefono: "093093093",
        domicilio: "Av Italia 5678",
        distance: 100,
        tipo: "ODONTOLOGO",
        agendas: [
            {
                fechaInicio: "20200722T1500-0300",
                fechaFin: "20200722T1600-0300",
            },
            {
                fechaInicio: "20200722T2000-0300",
                fechaFin: "20200722T2100-0300",
            },
            {
                fechaInicio: "20200727T1100-0300",
                fechaFin: "20200727T1200-0300",
            },
            {
                fechaInicio: "20200728T2000-0300",
                fechaFin: "20200728T2100-0300",
            },
        ],
        odontologos: [],
    },
]

const financingOptions: FinancingOptions = {
    bestOfferId: "24x195",
    quotation: [{
        code: "",
        // date: "",
        id: 1,
        name: "",
        symbol: "",
        value: 1,
    }],
    fundersList: [{
        isClient: false,
        funderName: "Banco Santander",
        offerList: [{
            amountOfInstallments: 48,
            conditions: "condiciones",
            pricePerInstallment: 100,
            offerId: "S48x100",
            offerType: OfferType.preApproved,
            currency: "USD",
        },
        {
            amountOfInstallments: 24,
            conditions: "condiciones",
            pricePerInstallment: 195,
            offerId: "S24x195",
            offerType: OfferType.preApproved,
            currency: "USD",
        },
        {
            amountOfInstallments: 12,
            conditions: "condiciones",
            pricePerInstallment: 380,
            offerId: "S12x380",
            currency: "USD",
        }]
    },
    {
        isClient: false,
        funderName: "Microfinanzas",
        offerList: [{
            amountOfInstallments: 48,
            conditions: "condiciones",
            pricePerInstallment: 100,
            offerId: "M48x100",
            offerType: OfferType.preApproved,
            currency: "USD",
        },
        {
            amountOfInstallments: 24,
            conditions: "condiciones",
            pricePerInstallment: 195,
            offerId: "M24x195",
            offerType: OfferType.preApproved,
            currency: "USD",
        },
        {
            amountOfInstallments: 12,
            conditions: "condiciones",
            pricePerInstallment: 380,
            offerId: "M12x380",
            currency: "USD",
        }]
    }]
}

const registerFinancing: RegisteredFinancing = {
    orderStatus: "",
    orderId: "123",
}

const returnResponseOrError = <T>(response: T): ResponseWithPosibleError<T> => {
    const randomNumber = Math.random()
    if (randomNumber > 0.9) {
        return { response: null, error: true, errorCode: 400 }
    } else {
        return { response, error: false }
    }
}

export class MockClient implements ApiFunctions {

    private static instance: MockClient

    private constructor() { }

    static getInstance(): MockClient {
        console.log("MOCK CLIENT TURNED ON")
        if (!MockClient.instance) {
            MockClient.instance = new MockClient()
            return MockClient.instance
        } else {
            return MockClient.instance
        }
    }

    getToken = async (code: string) => {
        await timeoutPromise(1000)
        const rsp: TokenViewModel = {
            token_type: "",
            access_token: "1234567890",
            expires_in: 123,
            refresh_expires_in: 123,
            refresh_token: "1234567890",
            id_token: "1234567890",
            session_state: "1234567890",
            scope: "1234567890",
            date: 1234,
        }
        return returnResponseOrError(rsp)
    }

    getTokenWithEmailAndPassword = async () => {
        await timeoutPromise(1000)
        return returnResponseOrError({ access_token: '123456789' } as TokenViewModel)
    }

    refreshToken = async (refreshToken: string) => {
        return await this.getToken("")
    }

    getUserInfo = async () => {
        await timeoutPromise(1000)
        const rsp: UserInfo = {
            id: "123456789",
            email: "testing@gmail.com",
            firstName: "Test",
            lastName: "Cooper",
            username: "Testing cooper",
        }
        return returnResponseOrError(rsp)
    }

    getDentistsByName = async (data: ClinicOrDentistSearchBindingModel) => {
        await timeoutPromise(1000)
        return returnResponseOrError([])
    }

    getClinicsByLocation = async (query: ClinicsByLocationBindingModel) => {
        await timeoutPromise(1000)
        return returnResponseOrError(digestAppointmentData(agendas))
    }

    getFinancingOptions = async (
        query: FinancingBindingModel
    ): Promise<ResponseWithPosibleError<FinancingOptions>> => {
        await timeoutPromise(1000)
        return returnResponseOrError(financingOptions)
    }

    getCashPayment = async (
        query: FinancingBindingModel
    ) => {
        await timeoutPromise(1000)
        return returnResponseOrError({ orderId: "1234", importeContado: "350", moneda: 'USD' })
    }

    getBaseFinancing = async (query: any) => {
        await timeoutPromise(1000)
        return returnResponseOrError({
            amountOfInstallments: 16,
            conditions: "Visa o Master",
            cashPayment: 1500,
            pricePerInstallment: 90,
            currency: "USD"
        })
    }

    registerFinancing = async (_query: RegisterFinancingBindingModel) => {
        await timeoutPromise(1000)
        return returnResponseOrError(registerFinancing)
    }

    registerAppointment = async (_query: RegisterAppointmentBindingModel) => {
        await timeoutPromise(1000)
        return returnResponseOrError(null)
    }

    getOrder = async (_query: OrderBindingModel) => {
        await timeoutPromise(1000)
        const rsp: OrderInfo = {
            appointment: {
                dateTime: "20070405T1230-0200",
                clinicName: "Dientitos",
                clinicAddress: "Av. Italia 1234, esq Bolivia",
                clinicCoordinates: {
                    latitude: "-34",
                    longitude: "-56",
                },
                dentistName: "Diego",
                dentistSurname: "Bonomo",
            },
            treatments: [
                {
                    resourceId: "IMP11",
                    amount: 1,
                }
            ],
            payment: {
                amountOfInstallments: 12,
                pricePerInstallment: 49,
                installmentsCurrency: "USD",
                offerConditions: "Pago con tarjeta de credito volar",
                financiatorName: "Banco Itaú",
                financementApproved: true,
                cashPaymentAmount: 500,
                cashPayed: false,
                cashPaymentEnabled: false,
                cashCurrency: "UYU"
            },
        }
        return returnResponseOrError(rsp)
    }

}