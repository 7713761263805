import React, { useState, useEffect } from 'react';
import { IonPage, IonContent } from '@ionic/react';
import { useHistory } from 'react-router';
import { ViewportProps } from "react-map-gl"
import { Plugins } from '@capacitor/core';
import styled from 'styled-components';
import {
    PageContainer
} from '../../stylesGlobal/CommonStyles';
import { ButtonColor } from '../../components/shared/Button';
import { useTranslationTyped } from '../../utils/i18n/i18nHelper';
import { zIndex } from '../../stylesGlobal/Variables';
import { ReactComponent as Calendar } from "../../assets/icons/calendar.svg"
import Spinner from '../../components/shared/Spinner';
import Header from '../../components/shared/Header';
import SearchButton from '../../components/locationChooser/SearchButton';
import { useSetRecoilState, useRecoilState } from 'recoil';
import { userLocationState, selectedDentistState } from '../../recoil/Atoms';
import { getRoutes } from '../../utils/Routes';
import Overlay from '../../components/shared/Overlay';
import SmallOverlay from '../../components/shared/SmallOverlay';
import SearchModal from '../../components/locationChooser/SearchModal';
import Map, { MapValues } from '../../components/locationChooser/Map';
import GoBackButtonGroup from '../../components/shared/GoBackButtonGroup';
import { PageName } from '../../utils/Types';
import { getPageNumber } from '../../utils/HelperFunctions';
import { getLocationInfo } from './Geocoder';

const LocationChooserWrapper = styled(PageContainer)`

    .location__header{
        display:flex;
        flex-direction:column;
        justify-content:center;
    }

    .location__map{
        width:100%;
        height: 100%;
        border-radius:0 0 2rem 2rem;
        top: -2rem;
        z-index:${zIndex.one};
        overflow: hidden;
        background-color:#F5F5F5;
        position: relative;
        /* margin-bottom: 6rem; */
    }

    .location__loading{
        width: 100%;
        height: 100%;
        display: flex;
        align-items:center;
        justify-content:center;
        background: rgba(0,0,0,0.4);
        z-index: ${zIndex.one};
    }

    .mapboxgl-ctrl-logo {
        display: none;
    }
    .mapboxgl-ctrl.mapboxgl-ctrl-attrib {
        display: none;
    }   

`

const { Geolocation } = Plugins;

const initialCoords = {
    latitude: -34.896280,
    longitude: -56.161156,
}

const LocationChooserPage: React.FC = () => {

    const history = useHistory()
    const t = useTranslationTyped()
    const { appointmentChooser } = getRoutes()
    const [recoilUserLocation, setUserLocation] = useRecoilState(userLocationState)
    const setSelectedDentist = useSetRecoilState(selectedDentistState)
    const setClinic = useSetRecoilState(selectedDentistState)

    const [mapValues, setMapValues] = useState<MapValues>({
        latitude: initialCoords.latitude,
        longitude: initialCoords.longitude,
        zoom: 12
    })

    const [loading, setLoading] = useState(false)
    const [openModal, setOpenModal] = useState(false)

    useEffect(() => {
        if (recoilUserLocation && recoilUserLocation.longitude && recoilUserLocation.longitude) {
            setMapValues({
                ...recoilUserLocation,
                zoom: 16,
            })
        } else {
            const getPosition = async () => {
                try {
                    setLoading(true)
                    const position = await Geolocation.getCurrentPosition({ enableHighAccuracy: true, })
                    setMapValues({
                        latitude: position.coords.latitude,
                        longitude: position.coords.longitude,
                        zoom: 16,
                    })
                    setLoading(false)
                } catch (e) {
                    setLoading(false)
                    console.error("Error while getting user location", e)
                }
            }
            getPosition()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const onViewPortChange = (viewport: ViewportProps) => {
        setMapValues(viewport)
    }

    const onClickChooseDate = async () => {
        setLoading(true)
        const locationInfo = await getLocationInfo(mapValues.longitude, mapValues.latitude)
        setClinic(undefined)
        setUserLocation({
            ...locationInfo,
            longitude: mapValues.longitude,
            latitude: mapValues.latitude
        })
        setSelectedDentist(undefined)
        setLoading(false)
        history.push(appointmentChooser)
    }

    const onGoBack = () => {
        setUserLocation(null)
        history.goBack()
    }

    const toggleOpenModal = () => {
        setOpenModal(prevState => !prevState)
    }

    const handleSearchClick = async () => {
        toggleOpenModal();
        const locationInfo = await getLocationInfo(mapValues.longitude, mapValues.latitude)
        setUserLocation({
            ...locationInfo,
            longitude: mapValues.longitude,
            latitude: mapValues.latitude
        })
    }

    return (
        <IonPage>
            <IonContent
                color="transparent"
            >
                <LocationChooserWrapper>
                    <Overlay>
                        <SmallOverlay hideOverflow extraHeight={12} >
                            <section
                                className="location__header"
                            >
                                <Header
                                    title={t("location", "pageTitle")}
                                    subtitle={t("location", "pageSubtitle")}
                                    stepNumber={getPageNumber(PageName.LocationChooser)}
                                />
                                <SearchButton
                                    onClick={handleSearchClick}
                                />
                            </section>
                        </SmallOverlay>
                        <section className="location__map">
                            {loading && <div className="location__loading">
                                <Spinner theme="light" />
                            </div>}
                            <Map
                                mapValues={mapValues}
                                onViewPortChange={onViewPortChange}
                            />
                        </section>
                    </Overlay>

                    <GoBackButtonGroup
                        onGoBack={onGoBack}
                        showSmallButtons={true}
                        readyButton={{
                            color: ButtonColor.blue,
                            onClick: onClickChooseDate,
                            iconSVG: <Calendar />,
                            disabled: loading,
                            label: t("location", "nextPageButtonLabel")
                        }}
                    />
                </LocationChooserWrapper>
                <SearchModal
                    isOpen={openModal}
                    onClose={toggleOpenModal}
                />
            </IonContent>

        </IonPage >
    )
}

export default LocationChooserPage