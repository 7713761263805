import React from "react"
import styled from "styled-components"
import logo from "../../assets/logo.svg"
import { colors, screenSize } from "../../stylesGlobal/Variables"
import { StepCount } from "./StepCount"

const HeaderWrapper = styled.section`
    display:flex;
    flex-direction:column;
    justify-content:center;

    .header__logo{
        margin-bottom:.5rem;
    }

    .header__title{
        width:95%;
        font-size:1.8rem;
        text-align:center;
        color:${colors.white};
        font-weight:600;
        margin:0 auto;
        white-space: pre-line;
    }

    .header__subtitle{
        width:85%;
        font-size:1.4rem;
        text-align:center;
        color:${colors.white};
        opacity:.7;
        font-weight:400;
        margin: 1rem auto 0 auto;
    }

    img {
        height: 57px;
    }

    @media (min-width:${screenSize.size_l})
    and (min-height:600px){
        .header__title{
            font-size:2.5rem;
                    width:90%;
        }
        .header__subtitle{
            margin: 1.5rem auto 0 auto;
        }
    }
    @media (min-width:${screenSize.size_xl}){
        .header__title{
            font-weight:700;
        }        
    }

    @media (min-height: 800px){
        .header__title{
            width:91%;
            font-weight:700;
        }
    }

`

type Props = {
    title: string,
    subtitle?: string,
    stepNumber?: number,
}

const Header = (props: Props) => {

    return (
        <HeaderWrapper>
            <img src={logo} alt="uSmile logo" className="header__logo" />
            <h1 className="header__title">{props.title}</h1>
            {props.stepNumber && <StepCount stepNumber={props.stepNumber} />}
            {props.subtitle &&
                <p className="header__subtitle">
                    {props.subtitle}
                </p>
            }
        </HeaderWrapper>
    )
}

export default Header