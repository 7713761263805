import { useHistory } from "react-router"
import { useCleanRecoil } from "../../pages/contact/Cleaner"
import { cleanLocalStorage } from "../LocalStorageConnector"
import { getRoutes } from "../Routes"
import * as Sentry from "@sentry/react"

// const openBrowser = async () => {
//     window.open(`${EnvHelper.logoutUrl}${EnvHelper.redirectUrl}`, '_blank');
// }

// const openInAppBrowser = async () => {
//     let browser: InAppBrowserObject
//     browser = InAppBrowser.create(
//         `${EnvHelper.logoutUrl}${EnvHelper.redirectUrl}`,
//         '_blank',
//         { location: "no", zoom: "no" }
//     )
//     browser.on("loadstart")?.subscribe(async info => {
//         if (info.url === EnvHelper.redirectUrl) {
//             browser.close()
//         }
//     })
// }

export const useLogout = () => {
    const history = useHistory()
    const { home } = getRoutes()
    const cleanRecoil = useCleanRecoil(true)

    return () => {
        cleanLocalStorage()
        cleanRecoil()
        history.replace(home)
        // add alert showing error
        Sentry.configureScope(scope => scope.setUser(null));
    }
}