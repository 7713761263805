import React, { useState, useEffect } from "react"
import { Route, Redirect, useHistory, Switch } from "react-router"
import TreatmentFlowPage from "../../pages/treatmentFlow/TreatmentFlowPage"
import ImplantPage from "../../pages/odontogram/ImplantPage"
import RestorePage from '../../pages/odontogram/RestorePage'
import LocationChooserPage from "../../pages/locationChooser/LocationChooserPage"
import AppointmentChooserPage from "../../pages/appointmentChooser/AppointmentChooser"
import ContactPage from "../../pages/contact/Contact"
import { getRoutes } from "../../utils/Routes"
import { IonRouterOutlet } from "@ionic/react"
import Home from "../../pages/home/Home"
import PictureTakerPage from "../../pages/pictureTaker/PictureTaker"
import PaymentMethodChooser from "../../pages/payment/PaymentMethodChooser"
import FinancingEntityChooser from "../../pages/payment/FinancingEntityChooser"
import FinancingOfferChooser from "../../pages/payment/FinancingOfferChooser"
import PaymentSummaryPage from "../../pages/payment/PaymentSummary"
import { OrderSummaryPage } from "../../pages/orderSummary/OrderSummary"
import {
    // identifyUser,
    useTrackBackwardsNavigationEvent,
    useTrackForwardNavigationEvent
} from "../../utils/mixPanels/MixPanelHelper"
import { Action } from "history"
import { useSetRecoilState } from "recoil"
import { userInfoState } from "../../recoil/Atoms"
import * as Sentry from "@sentry/react"
import { getUserInfoFromStorage, setUserInfoInStorage } from "../persistanceModal/PersistenceDataAccess"
import { v4 as uuid } from 'uuid';
import IdAndCouponPage from "../../pages/payment/paymentMethods/IdAndCouponPage"
import CashPaymentMethodPage from "../../pages/payment/paymentMethods/CashPaymentMethod"

const AppRouter: React.FC = () => {
    // const [userInfo, setUserInfo] = useRecoilState(userInfoState)
    const history = useHistory()
    const [historyUtils, setHistoryUtils] = useState<{ location: string, action: Action }>()
    const [lastPageLocation, setLastPageLocation] = useState("")
    const trackForwardEvents = useTrackForwardNavigationEvent()
    const trackBackwardsEvents = useTrackBackwardsNavigationEvent()

    const setUserInfo = useSetRecoilState(userInfoState)

    useEffect(() => {
        history.listen((location, action) => {
            setHistoryUtils({
                location: location.pathname,
                action,
            })
        })
        const getUserId = async () => {
            const savedUser = await getUserInfoFromStorage()
            if (!savedUser?.id) {
                const newUserId = uuid()
                setUserInfo({ id: newUserId })
                Sentry.setUser({ id: newUserId })
                setUserInfoInStorage({ id: newUserId })
            }
        }
        getUserId()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (historyUtils) {
            if (historyUtils.location !== lastPageLocation) {
                if (historyUtils.action === "PUSH") {
                    trackForwardEvents({ fromPage: lastPageLocation, toPage: historyUtils.location })
                } else if (historyUtils.action === "POP") {
                    trackBackwardsEvents({ fromPage: lastPageLocation, toPage: historyUtils.location })
                }
                setLastPageLocation(historyUtils.location)
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [historyUtils])

    const {
        home,
        implant,
        restore,
        locationChooser,
        idAndCoupon,
        appointmentChooser,
        contact,
        flow,
        pictureTaker,
        choosePaymentMethod,
        financialEntity,
        financingOffer,
        paymentSummary,
        orderSummary,
        cashPaymentMethod,
    } = getRoutes()
    return <IonRouterOutlet>
        <Switch>
            <Route path={home} component={Home} exact={true} />
            <Route path={`${flow}/:pageId`} component={TreatmentFlowPage} exact={true} />
            <Route path={implant} component={ImplantPage} exact={true} />
            <Route path={restore} component={RestorePage} exact={true} />
            <Route path={locationChooser} component={LocationChooserPage} exact={true} />
            <Route path={choosePaymentMethod} component={PaymentMethodChooser} exact={true} />
            <Route path={financialEntity} component={FinancingEntityChooser} exact={true} />
            <Route path={financingOffer} component={FinancingOfferChooser} exact={true} />
            <Route path={idAndCoupon} component={IdAndCouponPage} exact={true} />
            <Route path={cashPaymentMethod} component={CashPaymentMethodPage} exact={true} />
            <Route path={paymentSummary} component={PaymentSummaryPage} exact={true} />
            <Route path={appointmentChooser} component={AppointmentChooserPage} exact={true} />
            <Route path={contact} component={ContactPage} exact={true} />
            <Route path={pictureTaker} component={PictureTakerPage} exact={true} />
            <Route path={orderSummary} component={OrderSummaryPage} exact={true} />
            <Route exact path="/" render={() => <Redirect to={home} />} />
            <Redirect to={home} />
        </Switch>
    </IonRouterOutlet>
}

export default AppRouter