import React from "react"
import { useRecoilState } from "recoil"
import {
    treatmentFlowState,
    resourceState,
    userLocationState,
    userInfoState,
    teethPictureState,
    hasSeenImpantTutorialState,
    completedOrderState
} from "../../recoil/Atoms"
import { useState, useEffect } from "react"
import { IonModal, IonContent } from "@ionic/react"
import { useHistory } from "react-router"
import { initializeStateAndWasUpdated } from "./PersistenceHelper"
import { useSetRecoilState } from "recoil"
import Spinner from "../shared/Spinner"
import styled from "styled-components"
import { PageContainer } from "../../stylesGlobal/CommonStyles"
import { useTranslationTyped } from "../../utils/i18n/i18nHelper"
import Button, { ButtonColor } from "../shared/Button"
import { colors, screenSize } from "../../stylesGlobal/Variables"
import logo from "../../assets/logo.svg"
import { continueFlow } from "./ModalHelper"

const PersistanceModalWrapper = styled(PageContainer)`
    background:${colors.darkGradient};

    .modal__messageContainer{
        display:flex;
        flex-direction:column;
        justify-content:center;
        align-items:center;
        margin-top:30%;
    }

    .modal__logo{
        margin-bottom:3rem;
    }

    .modal__message{
        font-size:1.8rem;
        font-weight:600;
        color:${colors.white};
        text-align:center;
    }

    .modal__buttonsContainer{
        width:80%;
        margin-top:5rem;
        display:flex;
        flex-direction:column;
        justify-content:center;
        align-items:center;
        button{
            &:first-child{
                margin-bottom:2rem;
            }
        }
    }

    @media (min-width:${screenSize.size_m})
    and (min-height:670px){
        .modal__message{
            font-size:2.1rem;
        }
        
    }

    @media (min-height: 800px){
        .modal__messageContainer{
            margin-top:50%;
        }
    }
`

export const Persistence: React.FC = () => {
    const history = useHistory()
    const [treatmentValue, setTreatmentState] = useRecoilState(treatmentFlowState)
    const [resourceValue, setResourceState] = useRecoilState(resourceState)
    const setUserLocationState = useSetRecoilState(userLocationState)
    const setUserInfoState = useSetRecoilState(userInfoState)
    const setOdontogramTutorialState = useSetRecoilState(hasSeenImpantTutorialState)
    const setCompletedOrderState = useSetRecoilState(completedOrderState)
    const [teethPictureInfo, setTeethPictureInfo] = useRecoilState(teethPictureState)

    const [showModal, setShowModal] = useState(false)
    const [loading, setLoading] = useState(false)

    const initialize = async () => {
        const canContinueFlow = await initializeStateAndWasUpdated(
            setTreatmentState,
            setResourceState,
            setUserLocationState,
            setUserInfoState,
            setTeethPictureInfo,
            setOdontogramTutorialState,
            setCompletedOrderState
        )
        if (canContinueFlow) {
            setShowModal(true)
        }
    }
    const t = useTranslationTyped()


    useEffect(() => {
        initialize()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    const onContinueFlow = async () => {
        // In this function we check where should we send the user based on the data loaded.
        // We added the setTimeout because it was only executing the last push. 
        //But this generates an animation that's not very nice.
        setLoading(true)
        await continueFlow(history, treatmentValue!, resourceValue, teethPictureInfo)
        setShowModal(false)
    }

    const onCancelFlow = async () => {
        setTreatmentState([])
        setResourceState(null)
        setUserLocationState(null)
        setTeethPictureInfo(null)
        setShowModal(false)
    }

    return (
        <IonModal isOpen={showModal} >
            <IonContent>
                <PersistanceModalWrapper>
                    <section className="modal__messageContainer">
                        <img src={logo} alt="Credentist logo" className="modal__logo" />
                        {loading
                            ? <Spinner theme="light" />
                            : <>
                                <p className="modal__message">{t("persistenceModal", "message")}</p>
                                <section className="modal__buttonsContainer">
                                    <Button
                                        color={ButtonColor.blue}
                                        label={t("persistenceModal", "buttons", "yes")}
                                        onClick={onContinueFlow}
                                    />
                                    <Button
                                        color={ButtonColor.transparent}
                                        label={t("persistenceModal", "buttons", "no")}
                                        onClick={onCancelFlow}
                                    />

                                </section>
                            </>
                        }
                    </section>
                </PersistanceModalWrapper>
            </IonContent>
        </IonModal>
    )
}
