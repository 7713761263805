import { saveOnLocalStorage, getFromLocalStorage } from "../../utils/LocalStorageConnector"
import { PageId, Resource } from "../../utils/Enums"
import { UserLocation, UserInfo, PictureInfo } from "../../utils/Types"

const treatmentFlowKey = "treatmentFlow"
const resourceKey = "resourceState"
const userLocationKey = "userLocation"
const userInfoKey = "userInfo"
const teethPictureKey = "teethPicture"
const odontogramTutorialKey = "odontogramTutorial"
const completedOrderKey = "completedOrder"

const getAccessors = <T>(key: string) => {
    const getter = async (): Promise<T | null> => {
        const content = await getFromLocalStorage(key)
        if (content) {
            const ret: T = JSON.parse(content)
            return ret
        } else {
            console.log(`No value found on storage for key ${key}`)
            return null
        }
    }

    const setter = async (content: T | null) => {
        await saveOnLocalStorage(key, JSON.stringify(content))
    }

    return [
        getter,
        setter,
    ] as const
}

export const [getFlowFromStorage, setFlowInStorage] = getAccessors<PageId[]>(treatmentFlowKey)

export const [getResourceFromStorage, setResourceInStorage] = getAccessors<Resource>(resourceKey)

export const [getUserLocationFromStorage, setUserLocationInStorage] = getAccessors<UserLocation>(userLocationKey)

export const [getUserInfoFromStorage, setUserInfoInStorage] = getAccessors<UserInfo>(userInfoKey)

export const [getTeethPictureFromStorage, setTeethPictureInStorage] = getAccessors<PictureInfo>(teethPictureKey)

export const [getOdontogramTutorialStateFromStorage, setOdontogramTutorialStateInStorage] = getAccessors<boolean>(odontogramTutorialKey)
export const [getCompletedOrderStateFromStorage, setCompletedOrderStateInStorage] = getAccessors<boolean>(completedOrderKey)