import React from "react"
import { IonContent, IonPage } from "@ionic/react"
import { useGetRequest } from "../../api/useGetRequest"
import { ApiClient } from "../../api/ApiClient"
import { useRecoilValue } from "recoil"
import { financingOptionsState, orderState, paymentInfoState, userInfoState } from "../../recoil/Atoms"
import Spinner from "../../components/shared/Spinner"
import Header from "../../components/shared/Header"
import SmallOverlay from "../../components/shared/SmallOverlay"
import Overlay from "../../components/shared/Overlay"
import { useTranslationTyped } from "../../utils/i18n/i18nHelper"
import styled from "styled-components"
import { PageContainer } from "../../stylesGlobal/CommonStyles"
import { colors, screenSize } from "../../stylesGlobal/Variables"
import moment from "moment"
import GrayLogo from "../../assets/grayLogo.svg"
import { OrderInfo } from "../../utils/Types"
import Button, { ButtonColor } from "../../components/shared/Button"
import { useHistory } from "react-router"
import { getRoutes } from "../../utils/Routes"
import { ErrorModal } from "../../components/error/ErrorModal"
import { useLogout } from "../../utils/customHooks/useLogout"
import { useCleanRecoil } from "../contact/Cleaner"
import { getBankIcon, getQuotationFromPaymentInfo } from "../../utils/HelperFunctions"
import { PaymentMethod } from "../../utils/Enums"

type OrderSummaryPageWrapperProps = {
    loading: boolean
}

const OrderSummaryPageWrapper = styled(PageContainer) <OrderSummaryPageWrapperProps>`
    .orderSummary__firstGroupContainer {
        background-color: #0D3BFB;
        border-radius: 0 0 20px 20px;
        color: ${colors.white};
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        padding: 4.2rem 0;
        padding-top: 7rem;
        position: relative;
        z-index: 2;
        label {
            font-size: 14px;
            font-weight: 400;
            margin-bottom: 1rem;
        }
        p {
            font-size: 16px;
            font-weight: 600;
        }
        
    }
    
    .orderSummary__secondGroupContainer {
        background-color: #30D0DE;
        border-radius: 0 0 20px 20px;
        color: ${colors.white};
        display: flex;
        justify-content: center;
        flex-direction: column;
        padding: 4.2rem 4rem;
        position: relative;
        top: -18px;
        z-index: 1;
        align-items: ${props => props.loading ? "center" : "start"}
        label {
            font-size: 11px;
            margin-bottom: 1rem;
            font-weight: 400;
            text-transform: uppercase;
        }
        p, li {
            font-size: 14px;
            font-weight: 600;
        }
        li {
            list-style-type: initial;
        }
    }

    .orderSummary__columnContainer {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 100%;
        margin-bottom: 3rem;
    }

    .orderSummary__column {
        display: flex;
        flex-direction: column;
    }

    .orderSummary__thirdGroupContainer {
        padding: 0 2rem;

        label {
            color: rgba(0, 0, 0, 0.3);
            font-size: 12px;
            text-align: center;
        }

        .orderSummary__emailSentLabel {
            margin-top: 15px;
        }
    }

    .orderSummary__blueBorder {
        border: 1px solid #0D53FC;
        border-radius: 5px;
        margin-bottom: 2rem;
    }

    .orderSummary__blueBorderContent {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 1.5rem 1rem;
        color: #263446;

        p {
            font-size: 16px;
            font-weight: 400;
            text-align: center;
        }

        .orderSummary__paymentMethod {
            font-weight: 700;
        }
    }
    .orderSummary__containerFooter {
        background-color: rgb(244, 244, 244);
        display: flex;
        flex-direction: row;
        display: flex;
        width: 100%;
        padding: 1rem;
        color: rgb(117, 126, 138);
        text-transform: uppercase;
        font-weight: 600;
        border-radius: 0 0 5px 5px;
        align-items: center;

        &.center{
            justify-content:center;
        }

        &.spaceBetween{
            justify-content:space-between;
        }

        span {
            display: flex;
            align-items: center;
        }
    }

    .orderSummary__paymentState {
        width: 6px;
        height: 6px;
        border-radius: 3px;
        margin-right: 7px;
    }

    .orderSummary__pageFooter {
        background-color: rgb(239, 239, 239);
        width: 100%;
        height: 6rem;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    h2 {
        font-size: 41px;
        text-align: center;
        margin-bottom: 6rem;
    }

    h3 {
        font-size: 11px;
        text-transform: uppercase;
        margin-bottom: 2rem;
        color: rgba(0, 0, 0, 0.56);
    }

    .orderSummary__appointmentContainer {
        text-align: center;
        display: flex;
        flex-direction: column;
        margin-bottom: 3rem;
        width: 90%;
    }

    .orderSummary__appointmentContainer__last {
        margin-bottom: 0;
    }

    .orderSummary__appointmentTitle {

    }

    .orderSummary__appointmentSubtitle {

    }
    
    .orderSummary__orderNumber {
        text-align: center;
        margin-top: 6rem;
    }

    .orderSummary__startOverContainer {
        margin-bottom: 2rem;
    }

    .orderSummary__installments {
        margin-bottom: 1rem;
    }

    .orderSummary__termsAndConditions {
        text-decoration: underline;
    }

    ion-content {
        position: relative;
        top: -2rem;
    }

    .orderSummary__paymentAmount {
        font-weight: 700
    }

    @media (min-width: ${screenSize.size_m}){
        .orderSummary__thirdGroupContainer {
            padding: 0 4rem;
        }
    }
`

export const OrderSummaryPage: React.FC = () => {

    const paymentInfo = useRecoilValue(paymentInfoState)
    const orderInfo = useRecoilValue(orderState)
    const financingOptions = useRecoilValue(financingOptionsState)

    const t = useTranslationTyped()
    const logout = useLogout()
    const history = useHistory()
    const startOver = useCleanRecoil(false)
    const user = useRecoilValue(userInfoState)

    const { data, error, handleRetry } = useGetRequest({
        key: `Order summary`,
        fetcher: async () => await ApiClient.getOrder({ orderId: orderInfo?.orderId! }, logout)
    })

    const getQuotation = (currency: string) => {
        if (paymentInfo && paymentInfo.paymentMethod !== PaymentMethod.Cash) {
            return getQuotationFromPaymentInfo(financingOptions!, currency)
        }
    }

    const getTimeAsString = (date: string) => {
        const momentDate = moment(date)
        const newDate = momentDate.toDate()
        var options = { weekday: 'long', month: 'long', day: 'numeric', minute: "numeric", hour: "numeric" };
        return newDate.toLocaleDateString("es-ES", options)
    }

    const paymentStateColor = (state: boolean) => {
        return <div className="orderSummary__paymentState" style={{
            background: state
                ? "rgb(140, 202, 61)"
                : "rgb(255, 199, 0)"
        }} />
    }

    const getPaymentMethodLabel = () => {
        const order = data?.response?.payment!
        if (order.cashPaymentAmount && order.pricePerInstallment) {
            return t("orderSummary", "paymentMethod", "cashAndFinancing")
        } else if (order.cashPaymentAmount && !order.pricePerInstallment) {
            return t("orderSummary", "paymentMethod", "cash")
        } else {
            return t("orderSummary", "paymentMethod", "financing")
        }
    }

    const getConvertionRateMessage = () => {
        if (paymentInfo
            && paymentInfo.paymentMethod !== PaymentMethod.Cash
            && paymentInfo.selectedOffer
        ) {
            const currency = paymentInfo?.selectedOffer.currency
            const quotation = getQuotation(currency)
            if (quotation?.value) {
                return `${t("paymentSummary", "convertionRateMessage1")} ${quotation.name}
                        ${t("paymentSummary", "convertionRateMessage2")} ${quotation.name}.
                        ${t("paymentSummary", "convertionRateMessage3")} ${Math.round(quotation?.value * paymentInfo?.selectedOffer.pricePerInstallment)}
                        ${t("paymentSummary", "convertionRateMessage4")}`
            }
        }
    }

    const openTermsAndConditions = () => {
        console.log("terms and conditions")
    }

    const startProcessAgain = () => {
        startOver()
        history.replace(getRoutes().home)
    }

    const renderFirstContainer = (order?: OrderInfo | null | undefined) => {
        return <div className="orderSummary__firstGroupContainer">
            {order ? <>
                <div className="orderSummary__appointmentContainer">
                    <label className="orderSummary__appointmentTitle">{t("orderSummary", "dateLabel")}</label>
                    <p className="orderSummary__appointmentSubtitle">{getTimeAsString(order.appointment.dateTime)}</p>
                </div>
                {order.appointment.clinicAddress &&
                    <div className="orderSummary__appointmentContainer orderSummary__appointmentContainer__last">
                        <label className="orderSummary__appointmentTitle">{t("orderSummary", "addressLabel")}</label>
                        <p className="orderSummary__appointmentSubtitle">{order.appointment.clinicAddress}</p>
                    </div>
                }
            </>
                :
                <Spinner theme="dark" />
            }
        </div>
    }

    const renderSecondContainer = (order: OrderInfo | null | undefined) => {
        return <div className="orderSummary__secondGroupContainer">
            {order ? <>
                <div className="orderSummary__columnContainer">
                    <div className="orderSummary__column">
                        <label>{t("orderSummary", "clinicName")}</label>
                        <p>{order.appointment.clinicName}</p>
                    </div>
                    <div className="orderSummary__column">
                        <label>{t("orderSummary", "dentistName")}</label>
                        <p>{order.appointment.dentistName} {order.appointment.dentistSurname}</p>
                    </div>
                </div>
                <label>{t("orderSummary", "treatments")}</label>
                <ul>
                    {order.treatments.map(t => <li>{t.resourceId}</li>)}
                </ul>
            </>
                : <Spinner theme="dark" />
            }
        </div>
    }

    const renderThirdContainer = (order: OrderInfo | null | undefined) => {
        const financiatorName = order?.payment.financiatorName
        return <div className="orderSummary__thirdGroupContainer">
            {order ? <>

                <h3>{t("orderSummary", "budget")}</h3>
                <div
                    className="orderSummary__blueBorder orderSummary__blueBorderContent"
                    style={financiatorName ? {
                        flexDirection: "row",
                        justifyContent: "space-between",
                        padding: "1.5rem 2rem",
                    } : {}}
                >
                    <p className="orderSummary__paymentMethod">{getPaymentMethodLabel()}</p>
                    {financiatorName
                        && <img src={getBankIcon(financiatorName)} alt={financiatorName} />}
                </div>

                {renderFinancingInformation(order)}

                {renderCashInformation(order)}

                <div className="orderSummary__emailSentLabel">
                    <label>
                        <span>{t("orderSummary", "emailSentLabel1")}</span>
                        <u>{user?.email}</u>
                        <span>{t("orderSummary", "emailSentLabel2")}</span>
                        <span>{order.payment.amountOfInstallments ? t("orderSummary", "emailSentLabel3") : ""}</span>
                    </label>
                </div>

                <h3 className="orderSummary__orderNumber" >
                    {t("orderSummary", "orderNumber")}
                </h3>
                <h2>{orderInfo?.orderId}</h2>
                <div className="orderSummary__startOverContainer">
                    <Button
                        color={ButtonColor.blue}
                        onClick={startProcessAgain}
                        label={t("orderSummary", "startOver")}
                    />
                </div>
            </>
                : <Spinner theme="dark" />
            }
        </div>
    }

    const renderFinancingInformation = (order: OrderInfo) => {
        const { payment } = order
        if (payment.amountOfInstallments) {
            const quotation = getQuotation(payment?.installmentsCurrency)
            return <>
                <div className="orderSummary__blueBorder">
                    <div className="orderSummary__blueBorderContent">
                        <p className="orderSummary__installments">
                            {`${payment.amountOfInstallments} ${t("orderSummary", "monthlyPayments")} 
                            ${Math.round(payment.pricePerInstallment!)} ${quotation?.name}`}
                        </p>
                        <label>
                            <span
                                className="orderSummary__termsAndConditions"
                                onClick={openTermsAndConditions}
                            >
                                {payment.offerConditions}
                            </span>
                        </label>
                    </div>
                    <div className="orderSummary__containerFooter spaceBetween">
                        <label>{payment.financiatorName}</label>
                        <span>
                            {paymentStateColor(payment.financementApproved)}
                            <label> {t("orderSummary", payment.financementApproved ? "approved" : "pending")}</label>
                        </span>
                    </div>
                </div>
                <label>
                    {getConvertionRateMessage()}
                </label>
                <label>
                    {t("orderSummary", "loanApprovalLabel")}
                </label>
            </>
        } else {
            return null
        }
    }

    const renderCashInformation = (order: OrderInfo) => {
        const { payment } = order
        if (payment.cashPaymentAmount) {
            return <>
                <div className="orderSummary__blueBorder" style={{ marginTop: "2rem" }}>
                    <div className="orderSummary__blueBorderContent">
                        <p>{`${t("orderSummary", "cashAmountLabel")}`} <span className="orderSummary__paymentAmount" >
                            {`${payment.cashCurrency} ${payment.cashPaymentAmount}`}
                        </span></p>
                    </div>
                    <div className="orderSummary__containerFooter center">
                        <span >
                            {paymentStateColor(payment.cashPayed)}
                            <label>
                                {payment.cashPayed
                                    ? t("orderSummary", "approved")
                                    : t("orderSummary", "pending")}
                            </label>
                        </span>
                    </div>
                </div>
                <label>{t("orderSummary", "cashPaymentLabel")}</label>
            </>
        } else {
            return null
        }
    }

    const order = data?.response
    return <IonPage>
        <IonContent>
            <ErrorModal showErrorModal={error} onRetry={async () => await handleRetry()} />
            <OrderSummaryPageWrapper loading={!!order}>
                <Overlay scroll>
                    <SmallOverlay hideOverflow>
                        <Header title={t("orderSummary", "title")} />
                    </SmallOverlay>
                    <IonContent>
                        {renderFirstContainer(order)}
                        {renderSecondContainer(order)}
                        {renderThirdContainer(order)}
                        <div className="orderSummary__pageFooter">
                            <img src={GrayLogo} alt="logo" />
                        </div>
                    </IonContent>
                </Overlay>
            </OrderSummaryPageWrapper>
        </IonContent>
    </IonPage >
}