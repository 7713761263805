import { PageId } from "./Enums"

export const getRoutes = () => {
    const flowPath = "/flow"
    return {
        // login: "/login",
        home: "/home",
        flow: flowPath,
        flowWithPageId: (pageId: PageId) => `${flowPath}/${pageId}`,
        implant: "/implant",
        restore: "/restore",
        locationChooser: "/locationChooser",
        choosePaymentMethod: "/choosePaymentMethod",
        idAndCoupon: "/idAndCoupon",
        cashPaymentMethod: "/cashPaymentMethod",
        financialEntity: "/financialEntity",
        financingOffer: "/financingOffer",
        paymentSummary: "/paymentSummary",
        appointmentChooser: "/appointmentChooser",
        contact: "/contact",
        pictureTaker: "/pictureTaker",
        orderSummary: "/orderSummary",
    }
}