import { useRecoilValue } from "recoil"
import { ApiClient } from "../../../api/ApiClient"
import { ResponseWithPosibleError } from "../../../api/ApiConfig"
import { paymentInfoState, resourceState, teethPictureState, userInfoState } from "../../../recoil/Atoms"
import { useLogout } from "../../../utils/customHooks/useLogout"
import { flowHasOdontogram, getPictureBase64FromFileSystem } from "../../../utils/HelperFunctions"
import { FinancingOptionsCash } from "../../../utils/Types"
import { useResourceHelper } from "./resourceHelper"

export const useGetCashAmount = () => {
    const resourcesIds = useResourceHelper()
    const resource = useRecoilValue(resourceState)
    const teethPicture = useRecoilValue(teethPictureState)
    const userInfo = useRecoilValue(userInfoState)
    const paymentInfo = useRecoilValue(paymentInfoState)
    const logout = useLogout()

    return async (): Promise<ResponseWithPosibleError<FinancingOptionsCash>> => {
        const result = await ApiClient.getCashPayment({
            documentoId: paymentInfo?.documentId!,
            importeContado: -1,
            tratamientos: resourcesIds.map(r => {
                return {
                    tratamientoId: r.resourceId,
                    cantidad: r.amount
                }
            }),
            foto: teethPicture?.pictureCropedPath && flowHasOdontogram(resource!) ? await getPictureBase64FromFileSystem(teethPicture?.pictureCropedPath) : "",
            identificador: userInfo?.id!,
            codigoPromocion: paymentInfo?.coupon,
        }, logout)
        if (result.error) {
            console.error("Error while calling for financing option on cash")
        }
        return result
    }
}