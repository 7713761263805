import React from "react"
import styled from "styled-components"
import { screenSize, colors, pagePadding } from "../../stylesGlobal/Variables"
import { ReactComponent as Search } from "../../assets/icons/search.svg"
import { useTranslationTyped } from "../../utils/i18n/i18nHelper"

const SearchInputWrapper = styled.form`
    position:relative;
    display: flex;
    justify-content:center;
    align-items:center;
    padding:0 ${pagePadding.leftRight};

    .location__search{
        background-color:${colors.white};
        color:${colors.darkBlue};
        padding:1.2rem;
        text-align:center;
        border-radius:3rem;
        width:100%;
        font-size:1.4rem;
        position:relative;
        outline:none;
        appearance:none;    

        &:focus{
            outline:none;
        }
        ::placeholder { 
            color:${colors.darkBlue};
            opacity: .8; 
        }

    }

    .location__searchIcon{
        position:absolute;
        top:calc(50% - 10px);
        left:4rem;
    }

    @media (min-width: ${screenSize.size_l}){
        .location__search{
            font-size:1.6rem;
        }
        .location__searchIcon{
            left:4.5rem;
        }
    }

    @media (min-width:${screenSize.size_xl}){
        .location__search{
            padding:1.6rem;
        }
    }
`

type Props = {
    value: string,
    onChange: (value: string) => void,
    onSubmit: () => void
}

const SearchInput = (props: Props) => {
    const t = useTranslationTyped()

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        props.onChange(event.target.value)
    }

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault()
        props.onSubmit()
    }

    return (
        <SearchInputWrapper onSubmit={handleSubmit}>
            <input
                type="search"
                autoFocus
                value={props.value}
                onChange={handleChange}
                placeholder={t("location", "placeholder")}
                className="location__search"
            />
            <Search className="location__searchIcon" />
        </SearchInputWrapper >
    )
}

export default SearchInput