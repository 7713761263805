import React from "react"
import styled from "styled-components"
import { colors } from "../../stylesGlobal/Variables"

const EmptyStateWrapper = styled.section`
    display:flex;
    flex-direction:column;
    justify-content:center;
    align-items:center;
    margin-top:4rem;

    p{
        font-size:1.6rem;
        text-align:center;
        color:${colors.gray};
        font-weight:400;
    }

    @media (min-height: 800px){
        margin-top:10rem;
    }

`

type Props = {
    message: string,
}

const EmptyState = (props: Props) => {
    return (
        <EmptyStateWrapper>
            <p>{props.message}</p>
        </EmptyStateWrapper>
    )
}

export default EmptyState