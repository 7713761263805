import { Mixpanel } from '@ionic-native/mixpanel';
import { PaymentMethod, Resource } from '../Enums';
import * as Sentry from "@sentry/react"
import { paymentInfoState } from '../../recoil/Atoms';
import { useRecoilValue } from 'recoil';
import { getRoutes } from '../Routes';

export enum EventNames {
    Navigation = "Navigation",
    SelectsTooth = "SelectsTooth",
    FillsInput = "FillsInput",
    MovesPicture = "MovesPicture",
    ChoosePaymentMethod = "ChoosePaymentMethod"
}

type MovesPictureData = {
    isNowHidden: boolean,
}

type NavigatesForwardData = {
    toPage: string,
    fromPage: string,
    direction: "FORWARD" | "BACKWARD"
}

type SelectsToothData = {
    toothId: string,
    resource: Resource.FixedImplant | Resource.ToothRestoration
    addedOrRemoved: "ADDED" | "REMOVED",
}

type ChoosePaymentMethodData = {
    paymentMethod: PaymentMethod,
}

type FillsFormData = {
    pageId: string,
    inputName: string,
    value: string,
}

type EventData = NavigatesForwardData & { eventName: EventNames.Navigation }
    | SelectsToothData & { eventName: EventNames.SelectsTooth }
    | FillsFormData & { eventName: EventNames.FillsInput }
    | MovesPictureData & { eventName: EventNames.MovesPicture }
    | ChoosePaymentMethodData & { eventName: EventNames.ChoosePaymentMethod }

export const trackEvent = (eventData: EventData) => {
    try {
        Mixpanel.track(eventData.eventName, eventData)
    } catch (e) {
        console.error("MixPanel faild tracking event", eventData)
        Sentry.captureException(e)
    }
}

const useGetPageWithExtraData = () => {
    const { implant, restore, idAndCoupon } = getRoutes()
    const paymentMethodValue = useRecoilValue(paymentInfoState)?.paymentMethod
    return (page: string) => {
        switch (page) {
            case implant:
                return `${page} ${"implants"}`
            case restore:
                return `${page} ${"restoration"}`
            case idAndCoupon:
                return `${page} ${paymentMethodValue}`
            default:
                return page
        }
    }
}

export const useTrackForwardNavigationEvent = () => {
    const getPageWithExtraData = useGetPageWithExtraData()
    return (data: { fromPage: string, toPage: string }) => {
        const fromPage = getPageWithExtraData(data.fromPage)
        const toPage = getPageWithExtraData(data.toPage)
        trackEvent({
            eventName: EventNames.Navigation,
            fromPage,
            toPage,
            direction: "FORWARD",
        })
    }
}

export const useTrackBackwardsNavigationEvent = () => {
    const getPageWithExtraData = useGetPageWithExtraData()
    return (data: { fromPage: string, toPage: string }) => {
        const fromPage = getPageWithExtraData(data.fromPage)
        const toPage = getPageWithExtraData(data.toPage)
        trackEvent({
            eventName: EventNames.Navigation,
            fromPage,
            toPage,
            direction: "BACKWARD",
        })
    }
}

export const identifyUser = (userEmail: string) => {
    Mixpanel.identify(userEmail)
}