import React from 'react';
import { IonPage, IonContent } from '@ionic/react';
import { useHistory } from 'react-router';
import { PaymentMethod } from '../../utils/Enums';
import { getRoutes } from '../../utils/Routes';
import styled from 'styled-components';
import { PageContainer } from '../../stylesGlobal/CommonStyles';
import { ButtonColor } from '../../components/shared/Button';
import { ReactComponent as Check } from "../../assets/icons/check.svg"
import { useTranslationTyped } from '../../utils/i18n/i18nHelper';
import Overlay from '../../components/shared/Overlay';
import Header from '../../components/shared/Header';
import { useForm } from 'react-hook-form';
import { pagePadding, zIndex } from '../../stylesGlobal/Variables';
import { InputRadio } from '../../components/shared/InputRadio';
import FormErrorMessage from '../../components/shared/FormErrorMessage';
import { useRecoilState } from 'recoil';
import SmallOverlay from '../../components/shared/SmallOverlay';
import GoBackButtonGroup from '../../components/shared/GoBackButtonGroup';
import { paymentInfoState, resourceState } from '../../recoil/Atoms';
import produce from 'immer';
import { PageName, PaymentStateType } from '../../utils/Types';
import { flowHasOdontogram, getPageNumber } from '../../utils/HelperFunctions';
import {
    EventNames,
    trackEvent,
} from '../../utils/mixPanels/MixPanelHelper';
import { EnvHelper } from '../../utils/EnvHelper';

const PaymentMethodPageWrapper = styled(PageContainer)`
    .payment__form{
        display:flex;
        flex-direction:column;
        padding:0 ${pagePadding.leftRight};
        margin-top:3rem;
        left:0;
        width: 100%;
        z-index:${zIndex.two};
    }


`
export type FormData = {
    paymentMethod: PaymentMethod,
}

const PaymentMethodChooser: React.FC = () => {

    const [paymentMethod, setPaymentMethod] = useRecoilState(paymentInfoState)
    const [resource, setResource] = useRecoilState(resourceState)
    const { register, handleSubmit, errors, watch } = useForm<FormData>({
        mode: "all",
        shouldFocusError: true,
    })
    const t = useTranslationTyped()
    const history = useHistory()

    const updateStatePaymentMethod = (paymentMethod: PaymentMethod) => {
        setPaymentMethod(produce((prevState: PaymentStateType | null) => {
            if (prevState) {
                prevState.paymentMethod = paymentMethod
            } else {
                prevState = { paymentMethod: paymentMethod }
            }
            return prevState
        }))
    }


    const onGoBack = () => {
        if (!flowHasOdontogram(resource!)) {
            setResource(null)
        }
        history.goBack()
    }

    const isFormWithErrors = () => !!errors.paymentMethod

    const onSubmit = (data: FormData) => {
        updateStatePaymentMethod(data.paymentMethod)
        trackEvent({
            eventName: EventNames.ChoosePaymentMethod,
            paymentMethod: paymentMethod?.paymentMethod!
        })
        history.push(getRoutes().idAndCoupon)
    }

    const renderPaymentMethod = (value: PaymentMethod, defaultChecked?: boolean) => {
        return (
            <InputRadio
                name="paymentMethod"
                value={value}
                label={t("paymentMethod", "form", value)}
                defaultChecked={defaultChecked}
                ref={register({
                    required: {
                        message: t("paymentMethod", "form", "errorMessage"),
                        value: true,
                    },
                })}
                selected={watch("paymentMethod") === value}
            />
        )
    }

    const renderForm = () => {
        return (
            <form className="payment__form" onSubmit={handleSubmit(onSubmit)}>
                {renderPaymentMethod(PaymentMethod.Cash, true)}
                {renderPaymentMethod(PaymentMethod.Financing)}
                {renderPaymentMethod(PaymentMethod.CashAndFinancing)}
                <FormErrorMessage
                    message={errors.paymentMethod && errors.paymentMethod.message
                        ? errors.paymentMethod.message : ""}
                />
            </form>
        )
    }
    if (EnvHelper.onlyFinancingOption) {
        return null
    } else {
        return (
            <IonPage>
                <IonContent>
                    <PaymentMethodPageWrapper>
                        <Overlay scroll={true}>
                            <SmallOverlay>
                                <Header
                                    title={t("paymentMethod", "pageTitle")}
                                    subtitle={t("paymentMethod", "pageSubtitle")}
                                    stepNumber={getPageNumber(PageName.Payment)}
                                />
                            </SmallOverlay>

                            <section>
                                {renderForm()}
                            </section>
                        </Overlay>
                        <GoBackButtonGroup
                            onGoBack={onGoBack}
                            showSmallButtons={true}
                            readyButton={{
                                color: ButtonColor.blue,
                                onClick: handleSubmit(onSubmit),
                                iconSVG: <Check />,
                                label: t("generic", "ready"),
                                disabled: isFormWithErrors(),
                            }}
                        />
                    </PaymentMethodPageWrapper>
                </IonContent>
            </IonPage>
        )
    }
}

export default PaymentMethodChooser