import React from "react"
import { GoBackSmallContainer, GoBackContainer } from "../../stylesGlobal/CommonStyles"
import GoBackButton from "./GoBackButton"
import Button, { ButtonColor } from "./Button"

type Props = {
    onGoBack: () => void,
    showSmallButtons: boolean,
    disabled?: boolean,
    readyButton?: {
        label: string,
        onClick: () => void,
        color: ButtonColor,
        disabled?: boolean,
        iconSVG?: JSX.Element,
    }
}

const GoBackButtonGroup = (props: Props) => {
    return (
        <>
            {props.showSmallButtons
                && <GoBackSmallContainer>
                    <GoBackButton
                        onClick={props.onGoBack}
                        small={true}
                        micro={true}
                        disabled={props.disabled}
                    />
                    {props.readyButton
                        && <Button
                            color={props.readyButton.color}
                            onClick={props.readyButton.onClick}
                            label={props.readyButton.label}
                            small={true}
                            disabled={props.readyButton.disabled}
                        />
                    }
                </GoBackSmallContainer>
            }<GoBackContainer hideSmallDevices={props.showSmallButtons}>
                {props.readyButton
                    && <Button
                        color={props.readyButton.color}
                        label={props.readyButton.label}
                        onClick={props.readyButton.onClick}
                        disabled={props.readyButton.disabled}
                    />
                }
                <GoBackButton
                    onClick={props.onGoBack}
                    disabled={props.disabled}
                />
            </GoBackContainer>
        </>
    )
}

export default GoBackButtonGroup