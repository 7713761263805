
import { Plugins } from "@capacitor/core"
import { getUserInfoFromStorage, setUserInfoInStorage } from "../components/persistanceModal/PersistenceDataAccess"
import { EnvHelper } from "./EnvHelper"

const buildNameKey = "buildName"

export const saveOnLocalStorage = async (key: string, value: string) => {
    const { Storage } = Plugins
    const buildName = await getSavedBuildName()
    if (!!buildName || buildName !== EnvHelper.buildName) {
        await saveBuildName()
    }
    return await Storage.set({ key, value, })
}

export const getFromLocalStorage = async (key: string) => {
    const { Storage } = Plugins
    return (await Storage.get({ key })).value
}

export const cleanLocalStorage = async () => {
    const { Storage } = Plugins
    const userId = (await getUserInfoFromStorage())?.id
    await Storage.clear()
    await saveBuildName()
    if (userId) {
        await setUserInfoInStorage({ id: userId })
    }
}

const saveBuildName = async () => {
    const { Storage } = Plugins
    return await Storage.set({ key: buildNameKey, value: EnvHelper.buildName, })
}

export const getSavedBuildName = async () => {
    return (await getFromLocalStorage(buildNameKey))
}

export const removeFromStorage = async (key: string) => {
    const { Storage } = Plugins
    return await Storage.remove({ key: key })
}
