import React, { useCallback, useRef } from "react"
import { useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { ApiClient } from '../../api/ApiClient';
import { ResponseWithPosibleError } from '../../api/ApiConfig';
import Spinner from "../shared/Spinner";
import { selectedTeethState } from '../../recoil/Atoms';
import { BaseFinancing } from '../../utils/Types';
import styled from "styled-components";
import { colors } from "../../stylesGlobal/Variables";
import { ErrorModal } from "../error/ErrorModal";
import { ResourceList, useResourceHelper } from "../../pages/payment/paymentMethods/resourceHelper";
import { timeoutPromise } from "../../utils/HelperFunctions";
import { useLogout } from "../../utils/customHooks/useLogout";
import { debounce } from "lodash";

const PriceCalculatorWrapper = styled.section`

    position: absolute;
    display: flex;
    justify-content: center;
    white-space: nowrap;
    bottom: 5rem;

    .teeth__price{
        background: linear-gradient(243.77deg, #36E4F3 -2.85%, #30D0DE 75.66%);
        box-shadow: 0px 4px 11px rgba(0, 112, 122, 0.13);
        display: flex;
        justify-content: center;
        height: 4rem;
        align-items: center;
        border-radius: 2.6rem;
        font-size: 1.4rem;
        padding: 0 2.5rem;
        position: absolute;
        z-index: 4;
        font-weight: 700;
        color: ${colors.white};
        border: 1px solid;
        border-image-source: linear-gradient(180deg, rgba(255, 255, 255, 0.35) 0%, rgba(28, 162, 174, 0.24) 100%);
    }
`

export const PriceCalculator: React.FC = () => {

    const [loading, setLoading] = useState(false)
    const [showErrorModal, setShowErrorModal] = useState(false)
    const [basePrice, setBasePrice] = useState<ResponseWithPosibleError<BaseFinancing>>()

    const selectedTeeth = useRecoilValue(selectedTeethState)
    const resourcesIds = useResourceHelper()
    const logout = useLogout()
    const resourcesRef = useRef<ResourceList>()
    resourcesRef.current = resourcesIds

    const getBaseFinancing = async (resources: ResourceList) => {
        try {
            setShowErrorModal(false)
            setLoading(true)
            const price = await pricefetcher(resources)
            if (!resourcesRef.current?.some((value, index) => value?.resourceId !== resources[index]?.resourceId)) {
                if (price.error) {
                    if (price.errorCode !== 401) {
                        await timeoutPromise(100)
                        setShowErrorModal(true)
                        setLoading(false)
                    }
                } else {
                    setShowErrorModal(false)
                    setBasePrice(price)
                    setLoading(false)
                }
            }
        } catch (e) {
            console.error(e)
            setLoading(false)
            setShowErrorModal(true)
        }
    }

    const throttled = useCallback(debounce(
        getBaseFinancing, 2000, { trailing: true }
    ), [])

    useEffect(() => {
        setLoading(true)
        throttled(resourcesIds)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedTeeth])

    const pricefetcher = async (resources: ResourceList) => {
        const response = await ApiClient.getBaseFinancing({
            tratamientos: resources.map(r => {
                return {
                    tratamientoId: r.resourceId,
                    cantidad: r.amount,
                }
            })
        }, logout)
        return response
    }

    return <PriceCalculatorWrapper>
        <ErrorModal showErrorModal={showErrorModal} onRetry={() => getBaseFinancing(resourcesIds)} />
        {loading ?
            <div className="teeth__price">
                <Spinner theme="light" small />
            </div>
            : (!basePrice?.error && basePrice?.response ?
                <div className="teeth__price">
                    <p>
                        {basePrice.response.amountOfInstallments} X {basePrice.response.currency} {basePrice.response.pricePerInstallment.toLocaleString('es')}
                    </p>
                </div>
                : null
            )
        }
    </PriceCalculatorWrapper>
}

