import { useResetRecoilState } from "recoil"
import {
    completedOrderState,
    financingOptionsState,
    orderState,
    paymentInfoState,
    resourceState,
    selectedAppointmentState,
    selectedDentistState,
    selectedTeethState,
    teethPictureState,
    treatmentFlowState,
    userInfoState,
    userLocationState
} from "../../recoil/Atoms"

export const useCleanRecoil = (logout?: boolean) => {
    const resetResource = useResetRecoilState(resourceState)
    const resetUserInfo = useResetRecoilState(userInfoState)
    const resetTreatmentFlow = useResetRecoilState(treatmentFlowState)
    const resetPaymentInfo = useResetRecoilState(paymentInfoState)
    const resetSelectedDentist = useResetRecoilState(selectedDentistState)
    const resetSelectedTeeth = useResetRecoilState(selectedTeethState)
    const resetOrder = useResetRecoilState(orderState)
    const resetAppointmentState = useResetRecoilState(selectedAppointmentState)
    const resetUserLocation = useResetRecoilState(userLocationState)
    const resetTeethPicture = useResetRecoilState(teethPictureState)
    const resetCompletedOrder = useResetRecoilState(completedOrderState)
    const resetFinancingOptionsState = useResetRecoilState(financingOptionsState)

    return () => {
        resetResource()
        resetTreatmentFlow()
        resetPaymentInfo()
        resetSelectedDentist()
        resetSelectedTeeth()
        resetOrder()
        resetAppointmentState()
        resetCompletedOrder()
        resetFinancingOptionsState()
        if (logout) {
            resetUserInfo()
            resetUserLocation()
            resetTeethPicture()
        }
    }
}