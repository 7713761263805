import moment from "moment"
import { flatten } from "../../utils/HelperFunctions"
import { AppointmentDataType, AvailableAppointments, ClinicDentistAppointment, IndependetDentistAppointment } from "../../utils/Types"
import { AgendaModel, ClinicViewModel, DentistModel } from "../clientInterfaces/ClinicModel"

export const getDateMonthString = (dateString: string) => {
    const date = moment(dateString)
    return `${date.get("date").toString()}-${date.get("month").toString()}`
}


const getClinicDentistsAvailableDates = (
    clinicName: string,
    clinicSurname: string,
    dentistList: DentistModel[],
    distance: number,
    address: string,
): ClinicDentistAppointment[] => {
    return flatten(dentistList.map(dentist => dentist.agendas.map(appointment => {
        return {
            clinicName,
            clinicSurname: clinicSurname,
            dentistName: dentist.nombre,
            dentistSurname: dentist.apellido,
            time: appointment.fechaInicio,
            dentistId: dentist.idExternoAgenda,
            type: "CLINIC",
            distance,
            address,
        }
    })))
}

const getIndependentDentistAvailableDates = (
    dates: AgendaModel[],
    dentistName: string,
    dentistSurname: string,
    dentistId: string,
    distance: number,
    address: string,
): IndependetDentistAppointment[] => {
    return dates.map(availableTime => {
        return {
            dentistName,
            dentistSurname,
            time: availableTime.fechaInicio,
            dentistId,
            type: "DENTIST",
            distance,
            address
        }
    })
}

export const digestAppointmentData = (data: ClinicViewModel[]): AvailableAppointments => {
    let appoinmentList: AppointmentDataType[] = []
    const dateList: AvailableAppointments = {}
    appoinmentList = data.reduce((list: AppointmentDataType[], clinicOrDentist) => {
        let availableDates: AppointmentDataType[]
        if (clinicOrDentist.tipo === "CLINICA") {
            availableDates = getClinicDentistsAvailableDates(
                clinicOrDentist.nombre,
                clinicOrDentist.apellido,
                clinicOrDentist.odontologos,
                clinicOrDentist.distance,
                clinicOrDentist.domicilio
            )
        } else {
            availableDates = getIndependentDentistAvailableDates(
                clinicOrDentist.agendas,
                clinicOrDentist.nombre,
                clinicOrDentist.apellido,
                clinicOrDentist.idExternoAgenda,
                clinicOrDentist.distance,
                clinicOrDentist.domicilio,
            )
        }
        return list.concat(availableDates)
    }, [])
    appoinmentList
        .sort((app1, app2) => moment(app1.time).valueOf() - moment(app2.time).valueOf())
        .forEach(appointment => {
            const dateAndMonthString = getDateMonthString(appointment.time)
            if (!dateList[dateAndMonthString]) {
                dateList[dateAndMonthString] = {}
            }
            const date = moment(appointment.time)
            const minutesNumber = date.get("minutes")
            const minutesString = minutesNumber < 10 ? `0${minutesNumber.toString()}` : minutesNumber.toString()
            const hour = `${date.get("hours").toString()}:${minutesString}`
            if (!dateList[dateAndMonthString][hour]) {
                dateList[dateAndMonthString][hour] = []
            }
            dateList[dateAndMonthString][hour].push(appointment)
        })
    Object.keys(dateList).forEach(dateKey => {
        Object.keys(dateList[dateKey]).forEach(hourKey => {
            dateList[dateKey][hourKey].sort((a, b) => a.distance - b.distance)
        })
    })
    return dateList
}