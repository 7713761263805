import React from "react"
import { IonIcon } from '@ionic/react';
import ReactMapGL, { Marker, ViewportProps } from "react-map-gl"
import { pin } from 'ionicons/icons'
import { EnvHelper } from '../../utils/EnvHelper';

export type MapValues = {
    latitude: number,
    longitude: number,
    zoom: number
}

type Props = {
    mapValues: MapValues,
    onViewPortChange: (viewport: ViewportProps) => void
}


const Map = (props: Props) => {

    return (
        <ReactMapGL
            {...props.mapValues}
            onViewportChange={props.onViewPortChange}
            mapboxApiAccessToken={EnvHelper.mapBoxApiToken}
            height="100%"
            width="100%"
            mapStyle={EnvHelper.mapBoxStyle}
            attributionControl={false}
            preventStyleDiffing={true}
        >
            <Marker
                latitude={props.mapValues.latitude}
                longitude={props.mapValues.longitude}
                offsetTop={-10}
                offsetLeft={-10}
            >
                <IonIcon icon={pin} color="primary" size="large" />
            </Marker>
        </ReactMapGL>
    )
}

export default Map