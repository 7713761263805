import React from "react"
import styled from "styled-components"
import { screenSize } from "../../stylesGlobal/Variables"

const BackgroundCirclesWrapper = styled.section`
    position:absolute;
    width:100%;
    height:100%;
    top:0;
    left:0;

    .circle{
        position:absolute;
        width:30rem;
        height:30rem;
        border-radius:100%;
        background:#262F5A;
        mix-blend-mode:lighten;

        &._1{
            left: -20rem;
            bottom: -8rem;
        }
        &._2{
            right: -13rem;
            bottom: -16rem;
        }
        &._3{
            right: -4rem;
            top: -20rem;
        }
    }

    @media (min-width:${screenSize.size_m})
    and (min-height:670px){
        .circle{
            &._1{
                left: -16rem;
                bottom: -5rem;
                }
            &._2{
                right: -12rem;
                bottom: -15rem;
            }
            &._3{
                right: -4rem;
                top: -17rem;
            }
        }
    }

    @media (min-height: 800px){
        .circle{
            &._1{
                left: -14rem;
                bottom: 3rem;
            }
            &._2{
                right: -12rem;
                bottom: -8rem;
            }
            &._3{
                right: -2rem;
                top: -14rem;
            }
        }
    }

`

const BackgroundCircles = () => {
    return (
        <BackgroundCirclesWrapper>
            <span className="circle _1" />
            <span className="circle _2" />
            <span className="circle _3" />
        </BackgroundCirclesWrapper>
    )
}

export default BackgroundCircles