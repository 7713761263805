import { Capacitor, FilesystemDirectory, Plugins } from "@capacitor/core"
import { Resource } from "./Enums"
import { FinancingOptions, PageName } from "./Types"
import BBVA_icon from "../assets/bankIcons/bbva.svg"
import Itau_icon from "../assets/bankIcons/itau.svg"
import Microfinanzas_icon from "../assets/bankIcons/republica.svg"

export const timeoutPromise = (timeout: number) => {
    return new Promise((resolve, _reject) => {
        setTimeout(resolve, timeout)
    })
}

export const flowHasOdontogram = (respurce: Resource) => {
    return respurce === Resource.FixedImplant || respurce === Resource.ToothRestoration
}

export const getPageNumber = (pageName: PageName) => {
    switch (pageName) {
        case PageName.PictureTaker:
            return 1
        case PageName.Odontogram:
            return 2
        case PageName.Payment:
            return 3
        case PageName.LocationChooser:
            return 4
        case PageName.AppointmentChooser:
            return 5;
        case PageName.Contact:
            return 6
    }
}

export const getPictureBase64FromFileSystem = (path: string): Promise<string> => {
    return new Promise<string>(async (resolve, reject) => {
        if (Capacitor.platform !== "web") {
            const { Filesystem } = Plugins
            let data = await Filesystem.readFile({
                path: "teethSelfieCroped.jpg",
                directory: FilesystemDirectory.Data
            })
            resolve('data:image/jpeg;base64,' + data.data)
        } else {
            resolve(path)
        }
    })
}

export const getBankIcon = (bankName: string | undefined) => {
    switch (bankName) {
        case "BBVA":
            return BBVA_icon
        case "Banco Itaú":
            return Itau_icon
        case "República Microfinanzas":
            return Microfinanzas_icon
        default:
            return undefined
    }
}

export const getQuotationFromPaymentInfo = (financingOptions: FinancingOptions, currency: string) => {
    return financingOptions?.quotation.find(quote => quote.code === currency)
}

export const mapAsync
    = async <T, R>(
        items: T[] | ReadonlyArray<T>,
        f: (p: T, i: number) => Promise<R>
    ): Promise<R[]> => {
        const ret: R[] = []

        // tslint:disable-next-line:prefer-for-of
        for (let index = 0; index < items.length; index++) {
            const element = items[index]
            ret.push(await f(element, index))
        }

        return ret
    }

export const flatten = <T>(list: T[][]): T[] => list.reduce((a, b) => a.concat(b), [])