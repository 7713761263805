import { flowHasOdontogram, mapAsync, timeoutPromise } from "../../utils/HelperFunctions"
import * as Sentry from "@sentry/react"
import { getRoutes } from "../../utils/Routes"
import { PageId, Resource } from "../../utils/Enums"
import { useHistory } from "react-router"
import { PictureInfo } from "../../utils/Types"

type HistoryType = ReturnType<typeof useHistory>

const addBreadcrumbBasedOnStorageForPage = (pageName: string) => {
    Sentry.addBreadcrumb({ message: `Se agrega la pantalla ${pageName} en base al storage` })
}

const pushFlowPage = async (treatmentValue: PageId[], history: HistoryType) => {
    const { flowWithPageId } = getRoutes()
    await mapAsync(treatmentValue, async step => {
        pushPage(flowWithPageId(step), history)
        await timeoutPromise(400)
    })
}

const pushPage = async (pageName: string, history: HistoryType) => {
    addBreadcrumbBasedOnStorageForPage(pageName)
    history.push(pageName)
    return await timeoutPromise(400)
}

export const continueFlow = async <T>(
    history: HistoryType,
    treatmentValue: PageId[],
    resourceValue: Resource | null,
    teethPicture: PictureInfo | null
) => {
    const { implant: odontogram, choosePaymentMethod, pictureTaker } = getRoutes()
    const flowStarted = !!treatmentValue.length
    if (flowStarted) {
        await pushFlowPage(treatmentValue, history)
        if (resourceValue) {
            if (flowHasOdontogram(resourceValue)) {
                // TODO When the odontogram page is complete add option for when 
                // odontogram was already completed to go to choosePaymentMethod
                await pushPage(pictureTaker, history)
                if (teethPicture) {
                    await pushPage(odontogram, history)
                }
            } else {
                await pushPage(choosePaymentMethod, history)
            }
        }
    }
}