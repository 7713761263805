import React from "react"
import { useRecoilValue } from "recoil"
import styled from "styled-components"
import { resourceState } from "../../recoil/Atoms"
import { colors } from "../../stylesGlobal/Variables"
import { Resource } from "../../utils/Enums"
import { useTranslationTyped } from "../../utils/i18n/i18nHelper"

const StepCountWraper = styled.section`

    display: flex;
    justify-content: center;
    margin: 1.5rem auto 0 auto;

    .step__text{
        font-size:1.4rem;
        text-align:center;
        font-weight:400;
        color: white;
    }

    .step__green {
        color: ${colors.green}
    }
    
    .step__blue {
        color: ${colors.blue}
    }
`

type Props = {
    stepNumber: number
}

const getFlowStepDifference = (resource: Resource | null) => {
    switch (resource) {
        case Resource.Appliances:
            return 2;
        case Resource.FixedApplieances:
            return 1;
        case Resource.FixedImplant:
            return 2;
        case Resource.InvisibleAligner:
            return 1;
        case Resource.MobileProstheticWithImplant:
            return 1;
        case Resource.MobileProstheticWithoutImplant:
            return 1;
        case Resource.ToothRestoration:
            return 2;
        case Resource.VeneerOrCrown:
            return 2;
        case Resource.Whitening:
            return 0;
        default:
            return 0
    }
}

export const StepCount = (props: Props) => {
    const t = useTranslationTyped()
    const selectedResource = useRecoilValue(resourceState)
    const differenceInSteps = getFlowStepDifference(selectedResource)

    return <StepCountWraper>
        <p>
            <span className="step__text">{`${t("flow", "step")} ${props.stepNumber + differenceInSteps}`} </span>
        </p>
    </StepCountWraper>
}