import { IonModal, IonSlide, IonSlides } from '@ionic/react';
import React, { useRef } from 'react';
import Button, { ButtonColor } from '../shared/Button';
import './OdontogramTutorial.css';

interface Props {
    title_one: string,
    subtitle_one: string,
    image_one: string,
    title_two: string,
    subtitle_two: string,
    image_two: string,
    hasSeenTutorial: boolean,
    setHasSeenTutorial: (value: boolean) => void

}

const OdontogramTutorial: React.FC<Props> = (props) => {
    const slidesRef = useRef<HTMLIonSlidesElement>(null);


    const handleNext = () => slidesRef.current?.slideNext();

    return (
        <IonModal
            isOpen={!props.hasSeenTutorial}
            cssClass='odontogram__tutorialModal'
            showBackdrop={true}
            onDidDismiss={() => props.setHasSeenTutorial(true)}
            swipeToClose={true}>

            <IonSlides
                pager={false}
                ref={slidesRef}
                options={{
                    initialSlide: 0,
                    speed: 400,
                }}>
                <IonSlide className="tutorial-slide">
                    <h1>{props.title_one}</h1>
                    <p>{props.subtitle_one}</p>
                    <img src={props.image_one} alt="Implant tutorial" />
                    <div className="tutorial-slide__button">
                        <Button
                            color={ButtonColor.blue}
                            label={"Siguiente"}
                            onClick={handleNext}
                        />
                    </div>
                </IonSlide>
                <IonSlide className="tutorial-slide">
                    <h1>{props.title_two}</h1>
                    <p>{props.subtitle_two}</p>
                    <img src={props.image_two} alt="Remove implant tutorial" />
                    <div className="tutorial-slide__button">
                        <Button
                            color={ButtonColor.blue}
                            label={"Comenzar"}
                            onClick={() => props.setHasSeenTutorial(true)}
                        />
                    </div>
                </IonSlide>
            </IonSlides>
        </IonModal>
    )
}

export default OdontogramTutorial;