import { EnvHelper } from "./EnvHelper"
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';

if (EnvHelper.isProduction) {
    Sentry.init({
        dsn: EnvHelper.sentryDSN,
        release: EnvHelper.buildName,
        environment: EnvHelper.envType,
        integrations: [
            new Integrations.BrowserTracing(),
        ],
        tracesSampleRate: EnvHelper.sentryTracesSampleRate,
        // debug: true,
    })
} else {
    console.warn("[RemoteDiagnosticsSentry] Disabled")
}