import React from 'react';
import { useHistory } from 'react-router';
import { getRoutes } from '../../utils/Routes';
import styled from 'styled-components';
import { PageContainer } from '../../stylesGlobal/CommonStyles';
import { ButtonColor } from '../../components/shared/Button';
import { ReactComponent as Check } from "../../assets/icons/check.svg"
import { useTranslationTyped } from '../../utils/i18n/i18nHelper';
import Overlay from '../../components/shared/Overlay';
import Header from '../../components/shared/Header';
import { useForm } from 'react-hook-form';
import { pagePadding, screenSize, colors, zIndex } from '../../stylesGlobal/Variables';
import { InputRadio } from '../../components/shared/InputRadio';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { IonPage, IonContent } from '@ionic/react';
import { Offer, PageName, PaymentStateType } from '../../utils/Types';
import produce from 'immer';
import SmallOverlay from '../../components/shared/SmallOverlay';
import GoBackButtonGroup from '../../components/shared/GoBackButtonGroup';
import { financingOptionsState, paymentInfoState } from '../../recoil/Atoms';
import { PaymentMethod } from '../../utils/Enums';
import { getFinancingOrMixedPaymentState } from '../../recoil/Selectors';
import { getBankIcon, getPageNumber, getQuotationFromPaymentInfo } from '../../utils/HelperFunctions';

const FinancingOfferWrapper = styled(PageContainer)`
    .financingOffer__form{
        display:flex;
        flex-direction:column;
        padding:0 ${pagePadding.leftRight};
        margin-top:1.5rem;
        padding-bottom:3rem;
        left:0;
        width: 100%;
        z-index:${zIndex.two};

        label{
            &:first-child{
                margin-bottom:3rem;
            }
        }
    }
    
    .financingOffer__terms{
        background:none;
        width:100%;
        text-align:center;
        font-size:1.4rem;
        border:none;
        color:${colors.darkBlue};
        margin-top:2rem;
        margin-bottom:2rem;
        text-decoration:underline;

        &:hover,&:focus{
            outline:none;
            border:none;
        }
    }

    .financingOffer__convertionRate {
        text-align: center;
        font-size: 12px;
        color: rgba(0,0,0,0.6);
    }

    .financingOffer__conditions {
        margin-bottom: 8rem;
        color: rgba(0, 0, 0, 0.3);
        font-size: 12px;
        text-align: center;
    }

    @media (min-width: ${screenSize.size_l}){
        .financingOffer__form{
             label{
                &:first-child{
                    margin-bottom:4rem;
                }
            }
        }
    }

`
export type FormData = {
    offerId: string,
}

const uruguayanPesosSymbol = "UYU"

const FinancingOfferChooser: React.FC = () => {

    const setPaymentInfo = useSetRecoilState(paymentInfoState)
    const paymentInfo = useRecoilValue(getFinancingOrMixedPaymentState)
    const financingOptions = useRecoilValue(financingOptionsState)


    const { register, handleSubmit, errors, watch } = useForm<FormData>({
        mode: "all",
        shouldFocusError: true,
    })

    const t = useTranslationTyped()

    const history = useHistory()

    const { paymentSummary } = getRoutes()

    const onGoBack = () => {
        history.goBack()
    }

    const isFormWithErrors = () => !!errors.offerId

    const selectedOfferConditions = (offers: Offer[] | undefined) => offers?.find(offer => offer.offerId === watch("offerId"))?.conditions

    const onSubmit = (data: FormData) => {
        if (financingOptions) {

            const financingEntityInfo = financingOptions.fundersList
                .find(f => f.funderName === paymentInfo.selectedFinancialEntityName)

            setPaymentInfo(
                produce((prevState: PaymentStateType | null) => {
                    if (prevState && prevState.paymentMethod !== PaymentMethod.Cash && financingEntityInfo) {
                        const offer = financingEntityInfo
                            .offerList.find(o => o.offerId === data.offerId)
                        if (offer) {
                            prevState.selectedOffer = offer
                        }
                    }
                })
            )
            history.push(paymentSummary)
        }
    }

    const renderForm = () => {
        if (financingOptions) {
            const financingEntityInfo = financingOptions.fundersList
                .find(f => f.funderName === paymentInfo.selectedFinancialEntityName)

            return (
                <>
                    {paymentInfo && paymentInfo.paymentMethod && <>
                        <form className="financingOffer__form" onSubmit={handleSubmit(onSubmit)}>
                            <InputRadio
                                name="paymentMethod"
                                value={paymentInfo.paymentMethod}
                                label={t("paymentMethod", "form", paymentInfo.paymentMethod)}
                                defaultChecked={true}
                                selected={true}
                                withMarginBottom={true}
                                bankIcon={getBankIcon(paymentInfo.selectedFinancialEntityName)}
                            />

                            {financingEntityInfo?.offerList.map((o, i) => {
                                const quotation = getQuotationFromPaymentInfo(financingOptions, o.currency)
                                return (
                                    <InputRadio
                                        name="offerId"
                                        value={o.offerId}
                                        largeText
                                        label={`${o.amountOfInstallments} cuotas de ${Math.round(o.pricePerInstallment)} ${quotation?.name}`}
                                        subLabel={o.currency !== uruguayanPesosSymbol && quotation?.value
                                            ? `Equivalente en Pesos: ${Math.round(quotation?.value * o.pricePerInstallment)}`
                                            : undefined}
                                        ref={register({
                                            required: {
                                                message: t("chooseFinancingOffer", "form", "errorMessage"),
                                                value: true,
                                            },
                                        })}
                                        selected={watch("offerId") === o.offerId}
                                        key={i}
                                        defaultChecked={o.offerId === financingOptions?.bestOfferId}
                                    />
                                )

                            })}
                        </form>
                        <button className="financingOffer__terms">
                            {t("chooseFinancingOffer", "termsAndConditions")}
                        </button>
                        <p className="financingOffer__conditions">
                            {selectedOfferConditions(financingEntityInfo?.offerList)}
                        </p>
                    </>
                    }
                </>
            )
        }
        return <></>
    }
    return (
        <IonPage>
            <IonContent>
                <FinancingOfferWrapper>
                    <Overlay scroll={true}>
                        <SmallOverlay>
                            <Header
                                title={t("chooseFinancingOffer", "pageTitle")}
                                subtitle={t("chooseFinancingOffer", "pageSubtitle")}
                                stepNumber={getPageNumber(PageName.Payment)}
                            />
                        </SmallOverlay>

                        <IonContent
                            scrollY={true}
                            forceOverscroll={false}
                        >
                            {renderForm()}
                        </IonContent>
                    </Overlay>
                    <GoBackButtonGroup
                        onGoBack={onGoBack}
                        showSmallButtons={true}
                        readyButton={{
                            color: ButtonColor.blue,
                            onClick: handleSubmit(onSubmit),
                            iconSVG: <Check />,
                            label: t("chooseFinancingOffer", "onSubmitButtonLabel"),
                            disabled: isFormWithErrors() || !watch("offerId"),
                        }}
                    />
                </FinancingOfferWrapper>
            </IonContent>
        </IonPage>
    )
}

export default FinancingOfferChooser