import { useRecoilValue } from "recoil"
import { BaseFinancingBindingModel } from "../../../api/clientInterfaces/PaymentModel";
import { resourceState, selectedTeethState } from "../../../recoil/Atoms"
import { Resource } from "../../../utils/Enums"
import { flowHasOdontogram } from "../../../utils/HelperFunctions";
import { ResourceBindingModel } from "../../../utils/Types";

export type ResourceList = {
    resourceId: any;
    amount: number;
}[]

const getResourceId = (resource: Resource) => {
    switch (resource) {
        case Resource.FixedApplieances:
            return "ALI02";
        case Resource.FixedImplant:
            return "IMP";
        case Resource.InvisibleAligner:
            return "ALI01";
        case Resource.MobileProstheticWithImplant:
            return "Pro01";
        case Resource.MobileProstheticWithoutImplant:
            return "PM";
        case Resource.ToothRestoration:
            return "RES";
        case Resource.Whitening:
            return "BLA01";
        default:
            console.error(`Resource not contemplated: ${resource}`)
            return "";
        //TODO: esto se comento para que no explote cuando comenzamos de nuevo una recorrida
        // throw new Error(`Resource not contemplated: ${resource}`)
    }
}

const getResourceListForImplantsOrRestoration = (
    teethList: string[],
    resource: Resource.FixedImplant | Resource.ToothRestoration
) => {
    const resourceId = getResourceId(resource)
    if (resourceId) {
        return teethList.map(tId => {
            return {
                resourceId: `${resourceId}${tId}`,
                amount: 1
            }
        })
    } else {
        return []
    }
}

export const useResourceHelper = (): ResourceList => {
    const resource = useRecoilValue(resourceState)
    const selectedTeeth = useRecoilValue(selectedTeethState)
    if (flowHasOdontogram(resource!)) {
        return [
            ...getResourceListForImplantsOrRestoration(selectedTeeth.implantedIds, Resource.FixedImplant),
            ...getResourceListForImplantsOrRestoration(selectedTeeth.restoredIds, Resource.ToothRestoration)
        ]
    } else {
        const resourceId = getResourceId(resource!)
        if (resourceId) {
            return [{
                resourceId: resourceId,
                amount: 1
            }]
        } else {
            return []
        }
    }
}

export const mapResources = (resources: ResourceBindingModel[]): BaseFinancingBindingModel => {
    return {
        tratamientos: resources.map(t => {
            return {
                tratamientoId: t.resourceId,
                cantidad: t.amount,
            }
        })
    }
}