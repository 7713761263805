import React, { useState } from 'react';
import { IonPage, IonContent, useIonViewDidEnter, IonAlert, IonToast, useIonViewDidLeave } from '@ionic/react';
import { useHistory } from 'react-router';
import { hasSeenImpantTutorialState, selectedTeethState } from '../../recoil/Atoms';
import { useRecoilState } from 'recoil';
import { ButtonColor } from '../../components/shared/Button';
import styled from 'styled-components';
import { PageContainer } from '../../stylesGlobal/CommonStyles';
import { getRoutes } from '../../utils/Routes';
import { ReactComponent as Check } from "../../assets/icons/check.svg"
import { useTranslationTyped } from '../../utils/i18n/i18nHelper';
import Header from '../../components/shared/Header';
import Overlay from '../../components/shared/Overlay';
import SmallOverlay from '../../components/shared/SmallOverlay';
import GoBackButtonGroup from '../../components/shared/GoBackButtonGroup';
import Odontogram from '../../components/odontogram/Odontogram';
import OdontogramTutorial from '../../components/odontogram/OdontogramTutorial';
import HelpIcon from "../../assets/icons/help.svg"
import { PriceCalculator } from '../../components/odontogram/PriceCalculator';
import { PictureComponent } from "../../components/odontogram/PictureComponent";
import { PageName } from '../../utils/Types';
import { getPageNumber } from '../../utils/HelperFunctions';
import tutorial_basic from '../../assets/implant-tutorial.gif';
import tutorial_delete from '../../assets/delete-tutorial.gif';

export const OdontogramPageWrapper = styled(PageContainer)`

    .odontogram__container{
        position:relative;
        left:0;
        display:flex;
        flex-direction:column;
        width: 100%;
        align-items: center;
        height: 100%;
    }
    
    .teeth__help{
        position: absolute;
        display: flex;
        height: 4rem;
        width: 4rem;
        border-radius: 2.5rem;
        z-index: 4;
        justify-content: center;
        background: rgba(0, 0, 0, 0.24);
        box-shadow: 0px 6px 12px -4px rgba(0, 0, 0, 0.19);
        backdrop-filter: blur(18px);
        left: 2.5rem;
        bottom: 1rem;

        img {
            width: 11px;
        }
    }

`

export enum ODONTOGRAM_TYPE {
    IMPLANT = 'IMPLANT',
    RESTORATION = 'RESTORATION'
}

const ImplantPage: React.FC = () => {

    const [pageLoaded, setPageLoaded] = useState(false)
    const [odontogramLoaded, setLoadOdontogram] = useState(false)
    const [toastMessage, setToastMessage] = useState("")
    const [restorAlertIsOpen, setRestorAlertIsOpen] = useState(false)
    const [isMounted, setIsMounted] = useState(false)

    const [hasSeenTutorial, setHasSeenTutorial] = useRecoilState(hasSeenImpantTutorialState);
    const [selectedTeeth, setSelectedTeeth] = useRecoilState(selectedTeethState)

    const history = useHistory()
    const t = useTranslationTyped()
    const { choosePaymentMethod } = getRoutes()

    const onGoBack = () => {
        setSelectedTeeth({
            ...selectedTeeth,
            implantedIds: [],
        })
        history.goBack()
    }

    const onClickReady = () => {
        setRestorAlertIsOpen(true)
    }

    useIonViewDidLeave(() => {
        setIsMounted(false);
        setPageLoaded(false)
    })

    useIonViewDidEnter(() => {
        setIsMounted(true);
        setPageLoaded(true)
    });

    const handleRestore = () => {
        history.push(getRoutes().restore)
    }

    const handleDontRestore = () => {
        history.push(choosePaymentMethod)
    }

    const renderRestaurationAlert = () => {
        return <IonAlert
            isOpen={restorAlertIsOpen}
            onDidDismiss={() => setRestorAlertIsOpen(false)}
            cssClass='my-custom-class'
            header={t("implant", "restoreAlert", "alertTitle")}
            subHeader={t("implant", "restoreAlert", "alertSubtitle")}
            message={t("implant", "restoreAlert", "alertDescription")}
            buttons={[
                {
                    text: t("implant", "restoreAlert", "alertRestore"),
                    cssClass: 'primary',
                    handler: () => {
                        handleRestore()
                    }
                },
                {
                    text: t("implant", "restoreAlert", "alertDontRestore"),
                    cssClass: 'primary',
                    handler: () => {
                        handleDontRestore()
                    }
                },
                {
                    text: t("implant", "restoreAlert", "alertCancel"),
                    role: 'cancel'
                }
            ]}
        />
    }

    const renderHelpIcon = () => {
        return <div className="teeth__help" onClick={() => setHasSeenTutorial(false)}>
            <img src={HelpIcon} alt="help" />
        </div>
    }

    return (
        <IonPage>
            <IonContent
                color="transparent"
                scrollY={false}
            >
                {renderRestaurationAlert()}
                <IonToast
                    isOpen={toastMessage !== ""}
                    onDidDismiss={() => setToastMessage("")}
                    message={toastMessage}
                    duration={3000}
                    position="top"
                    color="danger"
                />
                <OdontogramPageWrapper>
                    <Overlay fullHeight>
                        <SmallOverlay>
                            <Header
                                title={t("implant", "pageTitle")}
                                subtitle={t("implant", "pageSubtitle")}
                                stepNumber={getPageNumber(PageName.Odontogram)}
                            />
                        </SmallOverlay>
                        <section className="odontogram__container">
                            {isMounted ? <PriceCalculator /> : null}
                            {renderHelpIcon()}
                            {isMounted ? <PictureComponent /> : null}
                            {
                                isMounted ? <Odontogram
                                    // isEngineOnPause={enginPause}
                                    pageLoaded={pageLoaded}
                                    odontogramType={ODONTOGRAM_TYPE.IMPLANT}
                                    setLoadOdontogram={setLoadOdontogram}
                                    setErrorMessage={setToastMessage} /> : null
                            }
                        </section>
                    </Overlay>

                    <GoBackButtonGroup
                        onGoBack={onGoBack}
                        showSmallButtons={true}
                        disabled={!odontogramLoaded}
                        readyButton={{
                            color: ButtonColor.blue,
                            onClick: onClickReady,
                            iconSVG: <Check />,
                            label: t("generic", "ready"),
                            disabled: selectedTeeth.implantedIds.length === 0
                        }}
                    />
                    <OdontogramTutorial
                        title_one={t("implant", "stepOneTutorialTitle")}
                        subtitle_one={t("implant", "stepOneTutorialSubTitle")}
                        image_one={tutorial_basic}
                        title_two={t("implant", "stepTwoTutorialTitle")}
                        subtitle_two={t("implant", "stepTwoTutorialSubTitle")}
                        image_two={tutorial_delete}
                        hasSeenTutorial={hasSeenTutorial!}
                        setHasSeenTutorial={setHasSeenTutorial} />
                </OdontogramPageWrapper>
            </IonContent>
        </IonPage >
    )
}

export default ImplantPage