import { TokenViewModel } from "./clientInterfaces/AuthModel"
import { saveOnLocalStorage, getFromLocalStorage, removeFromStorage } from "../utils/LocalStorageConnector"

const authTokenKey = "authTokenKey"

export const saveTokens = async (tokenObject: TokenViewModel) => {
    await saveOnLocalStorage(authTokenKey, JSON.stringify({ ...tokenObject, date: (new Date().getTime()) }))
}

export const getTokenFromStorage = async () => {
    return (await getAuthOrRefreshToken())?.access_token
}

export const getRefreshTokenFromStorage = async () => {
    const tokenObject = await getAuthOrRefreshToken()
    if (tokenObject) {
        if (tokenObject.date + tokenObject.refresh_expires_in * 1000 > new Date().getTime()) {
            return tokenObject.refresh_token
        }
        await deleteTokens()
    }
    return null
}

const getAuthOrRefreshToken = async () => {
    const tokenStringed = await getFromLocalStorage(authTokenKey)
    if (tokenStringed) {
        const tokenObject = JSON.parse(tokenStringed) as TokenViewModel
        return tokenObject
    }
    return null
}

export const deleteTokens = async () => {
    await removeFromStorage(authTokenKey)
}