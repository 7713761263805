import React from "react"
import { IonModal, IonContent } from "@ionic/react"
import styled from "styled-components"
import { PageContainer } from "../../stylesGlobal/CommonStyles"
import { useTranslationTyped } from "../../utils/i18n/i18nHelper"
import Button, { ButtonColor } from "../shared/Button"
import { colors, screenSize } from "../../stylesGlobal/Variables"
import { ReactComponent as ErrorIcon } from "../../assets/icons/error.svg"
import { ReactComponent as WarningIcon } from "../../assets/icons/warning.svg"

const ErrorModalWrapper = styled(PageContainer)`
    background:${colors.darkGradient};

    .modal__messageContainer{
        display:flex;
        flex-direction:column;
        justify-content:center;
        align-items:center;
        margin-top:30%;
    }
     svg{
        width:6rem;
        height:6rem;
        margin-bottom:3rem;
    }

    .modal__logo{
        margin-bottom:3rem;
    }

    .modal__message{
        font-size:1.8rem;
        font-weight:600;
        color:${colors.white};
        text-align:center;
        margin-bottom:.5rem;
    }

    .modal__buttonsContainer{
        width:80%;
        margin-top:5rem;
        display:flex;
        flex-direction:column;
        justify-content:center;
        align-items:center;
        button{
            &:first-child{
                margin-bottom:2rem;
            }
        }
    }

    @media (min-width:${screenSize.size_m})
    and (min-height:670px){
        .modal__message{
            font-size:2.1rem;
        }
        
    }

    @media (min-height: 800px){
        .modal__messageContainer{
            margin-top:50%;
        }
    }
`

type Props = {
    onRetry: () => Promise<void>,
    showErrorModal: boolean,
    conflictModal?: boolean,
}

export const ErrorModal: React.FC<Props> = (props: Props) => {
    const t = useTranslationTyped()

    const handleOnRetry = async () => {
        await props.onRetry()
    }

    return (
        <IonModal isOpen={props.showErrorModal} >
            <IonContent>
                <ErrorModalWrapper>
                    <section className="modal__messageContainer">
                        {props.conflictModal ? <WarningIcon /> : <ErrorIcon />}
                        <p className="modal__message">{t(props.conflictModal ? "conflictModal" : "errorModal", "title")}</p>
                        <p className="modal__message">{t(props.conflictModal ? "conflictModal" : "errorModal", "message")}</p>
                        <section className="modal__buttonsContainer">
                            <Button
                                color={props.conflictModal ? ButtonColor.blue : ButtonColor.red}
                                label={t(props.conflictModal ? "conflictModal" : "errorModal", "button")}
                                onClick={handleOnRetry}
                            />
                        </section>
                    </section>
                </ErrorModalWrapper>
            </IonContent>
        </IonModal>
    )
}
