import ky from "ky"
import { EnvHelper } from "../../utils/EnvHelper"
import * as Sentry from "@sentry/react"

enum PlaceType {
    COUNTRY = "country",
    REGION = "region",
    PLACE = "place",
}

type GeocodeFeatureType = {
    id: string,
    place_type: PlaceType[],
    text: string,
}
type GeocodeResponseType = {
    features: GeocodeFeatureType[];
}

export const getLocationInfo = async (longitude: number, latitude: number) => {
    try {
        const parsedResponse = await ky.get(`https://api.mapbox.com/geocoding/v5/mapbox.places/${longitude},${latitude}.json?access_token=${EnvHelper.mapBoxApiToken}`)
        if (parsedResponse.ok) {
            const response = await parsedResponse.json() as GeocodeResponseType
            const country = response.features.find(location => location.place_type[0] === PlaceType.COUNTRY)?.text
            const state = response.features.find(location => location.place_type[0] === PlaceType.REGION)?.text
            const city = response.features.find(location => location.place_type[0] === PlaceType.PLACE)?.text
            return { country, state, city }
        } else {
            throw new Error(`Failed getting geocode info. ${JSON.stringify(parsedResponse)}`)
        }
    } catch (e) {
        console.error(e)
        Sentry.captureMessage(`Error getting location information`)
        Sentry.captureException(e)
    }

}