import React from "react"
import Button, { ButtonColor } from "./Button"
import { ReactComponent as GoBack } from "../../assets/icons/go_back.svg"
import { useTranslationTyped } from "../../utils/i18n/i18nHelper"

type Props = {
    onClick: () => void,
    small?: boolean,
    micro?: boolean,
    disabled?: boolean,
}

const GoBackButton = (props: Props) => {
    const t = useTranslationTyped()

    return (
        <Button
            color={ButtonColor.white}
            label={t("generic", "goBack")}
            onClick={props.onClick}
            iconSVG={<GoBack />}
            small={props.small}
            micro={props.micro}
            withBorder={true}
            disabled={props.disabled}
        />
    )
}

export default GoBackButton