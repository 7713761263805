import { PaymentMethod } from "./Enums"
import { OfferType } from "./Enums"

export type AvailableAppointments = {
    [date: string]: {
        [hour: string]: AppointmentDataType[];
    };
}

export type IndependetDentistAppointment = {
    dentistName: string,
    dentistSurname: string,
    address: string,
    time: string,
    dentistId: string,
    type: "DENTIST",
    distance: number
}

export type ClinicDentistAppointment = Omit<IndependetDentistAppointment, "type"> & {
    clinicName: string,
    clinicSurname: string,
    address: string,
    type: "CLINIC",
    distance: number
}

export type AppointmentDataType = IndependetDentistAppointment | ClinicDentistAppointment

export type UserLocation = {
    latitude: number,
    longitude: number,
    country?: string,
    state?: string,
    city?: string,
}

export type ContactInfo = {
    name: string,
    phone: string,
    address: string,
}

export type PaymentCash = {
    paymentMethod: PaymentMethod.Cash,
    cost?: number,
    cashCurrency?: string,
    coupon?: string,
    documentId?: number,
}

export type PaymentFinancing = {
    paymentMethod: PaymentMethod.Financing,
    documentId?: number,
    documentValidatorId?: number,
    selectedFinancialEntityName?: string,
    selectedOffer?: Offer,
    financingOptions?: FinancingOptions,
    coupon?: string,
}

export type PaymentCashFinancing = Omit<PaymentCash, "paymentMethod"> & Omit<PaymentFinancing, "paymentMethod"> & {
    paymentMethod: PaymentMethod.CashAndFinancing,
    amountCash?: number
}

export type PaymentStateType = PaymentCashFinancing | PaymentFinancing | PaymentCash

export type UserInfo = {
    id: string,
    email?: string,
    firstName?: string,
    lastName?: string,
    username?: string,
    phone?: string,
    address?: string,
    income?: string,
}

export type SelectedTeeth = {
    implantedIds: string[],
    restoredIds: string[],
}

export type BaseFinancing = {
    amountOfInstallments: number,
    conditions: string,
    cashPayment: number,
    pricePerInstallment: number,
    currency: string
}

export type Offer = {
    offerId: string,
    amountOfInstallments: number,
    pricePerInstallment: number,
    conditions: string,
    offerType?: OfferType,
    currency: string,
}

export type Financing = {
    funderName: string,
    isClient: boolean,
    offerList: Offer[]
}

export type Quotation = {
    id: number,
    code: string,
    name: string,
    symbol: string,
    // date?: string,
    value?: number,
}

export type FinancingOptions = {
    bestOfferId: string,
    fundersList: Financing[],
    quotation: Quotation[],
}

export type FinancingOptionsCash = {
    orderId: string,
    importeContado: string,
    moneda: string,
}

export type RegisteredFinancing = {
    orderId: string,
    orderStatus?: string,
}

export type PictureInfo = {
    picturePath: string,
    pictureCropedPath: string,
    idPicture: number
}

export enum PageName {
    PictureTaker = "PictureTaker",
    Odontogram = "Odontogram",
    Payment = "Payment",
    LocationChooser = "LocationChooser",
    AppointmentChooser = "AppointmentChooser",
    Contact = "Contact",
}

export type ResourceBindingModel = {
    resourceId: string,
    amount: number,
}

export type OrderInfo = {
    appointment: {
        dateTime: string,
        clinicName: string,
        clinicAddress: string,
        clinicCoordinates: {
            latitude: string,
            longitude: string,
        },
        dentistName: string,
        dentistSurname: string,
    },
    treatments: ResourceBindingModel[],
    payment: {
        amountOfInstallments?: number,
        pricePerInstallment?: number,
        installmentsCurrency: string,
        offerConditions: string,
        financiatorName: string,
        financementApproved: boolean,
        cashPaymentAmount?: number,
        cashPayed: boolean,
        cashPaymentEnabled: boolean,
        cashCurrency: string
    }
}
