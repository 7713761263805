import React from "react"
import styled, { css } from "styled-components"
import { colors } from "../../stylesGlobal/Variables"

type DateItemWrapperProps = {
    selected?: boolean,
    isPlaceholder?: boolean,
}

const DateItemWrapper = styled.li<DateItemWrapperProps>`
    display:flex;
    flex-direction:column;
    justify-content:space-between;
    align-items:center;
    width:5rem;
    height:7.2rem;
    min-width:5rem;
    min-height:7.2rem;
    background-color:${p => p.selected ? colors.lightBlue : colors.white};
    margin-right:.8rem;
    transform:${p => p.selected ? "scale(1.05)" : "scale(1)"};
    transition: transform .2s, background-color .2s;
    border-radius:1rem;
    padding:1rem;

    ${p => p.isPlaceholder && css`
        min-width:5rem;
        min-height:7.2rem;
        background-color:rgba(255,255,255,.3);
    `}

    /* .dateItem__info{ */
        /* background:red; */
        /* display:flex; */
        /* flex-direction:column; */
        /* align-items:center; */
        /* justify-content:space-between; */
    /* } */


    .dateItem__day{
        font-size:${p => p.selected ? "2.6rem" : "2.1rem"};
        color:${p => p.selected ? colors.white : colors.darkBlue};
        margin-top: ${p => p.selected ? "0rem" : ".7rem"};;
        text-shadow: 0px 4px 10px rgba(0,0,0,.33);
        font-weight:700;
        transition: margin-bottom .2s linear, font-size .2s linear, color .2s,margin-top .2s linear;
    }
    .dateItem__month{
        font-size:1.3rem;
        color:${p => p.selected ? colors.white : colors.darkBlue};
        opacity:${p => p.selected ? "1" : ".6"};
        font-weight:400;
        transition: color .2s;
    }
    
`

type Props = {
    selected?: boolean,
    day?: string,
    month?: string | undefined,
    onClick?: () => void,
    isPlaceholder?: boolean,
}

const DateItem = (props: Props) => {
    return (
        <DateItemWrapper
            onClick={props.onClick}
            selected={props.selected}
            isPlaceholder={props.isPlaceholder}
        >
            {!props.isPlaceholder
                && (<>
                    <p className="dateItem__day">{props.day}</p>
                    <p className="dateItem__month">{props.month}</p>
                </>)
            }

        </DateItemWrapper>
    )
}

export default DateItem