import { Dictionary } from "./i18n"
import { useTranslation } from "react-i18next"

type T = Dictionary

export const useTranslationTyped = () => {
    const { t } = useTranslation()
    return <
        path1 extends (keyof T),
        path2 extends (T[path1] extends string ? never : keyof T[path1]),
        path3 extends (T[path1][path2] extends string ? never : keyof T[path1][path2]),
        path4 extends (T[path1][path2][path3] extends string
            ? never : keyof T[path1][path2][path3]
        ),
        path5 extends (T[path1][path2][path3][path4] extends string
            ? never : keyof T[path1][path2][path3][path4]
        ),
        >(
            // _t: T,
            path1p: path1,
            path2p?: path2,
            path3p?: path3,
            path4p?: path4,
            path5p?: path5,
    ) => {
        const p = [
            path1p,
            path2p,
            path3p,
            path4p,
            path5p,
        ].filter(Boolean).join(".")
        return t(p)
    }
}
