import { ApiFunctions, apiUrl, ResponseWithError, ResponseWithPosibleError } from "./ApiConfig"
import { EnvHelper } from "../utils/EnvHelper"
import { MockClient } from "./MockClient"
import { ClinicViewModel, ClinicOrDentistSearchBindingModel, ClinicsByNameViewModel } from "./clientInterfaces/ClinicModel"
import {
    FinancingViewModel,
    RegisterFinancingViewModel,
    RegisterFinancingBindingModel,
    BaseFinancingBindingModel,
    BaseFinancingViewModel,
    FinancingBindingModel,
    ClinicsByLocationBindingModel,
    FinancingViewModelCash
} from "./clientInterfaces/PaymentModel"
import { TokenViewModel, TokenBindingModel, RefreshTokenBindingModel, TokenWithEmailAndPasswordBindingModel } from "./clientInterfaces/AuthModel"
import { saveTokens } from "./AuthTokenHelper"
import { UserViewModel } from "./clientInterfaces/UserModel"
import { AvailableAppointments, BaseFinancing, FinancingOptions, FinancingOptionsCash, OrderInfo, RegisteredFinancing, UserInfo } from "../utils/Types"
import moment from "moment"
import { digestAppointmentData } from "./helpers/AppointmentHelper"
import { RegisterAppointmentBindingModel } from "./clientInterfaces/AppointmentMode"
import { OrderBindingModel, OrderViewModel } from "./clientInterfaces/OrderModel"
import { fetcher, fetcherPost, fetcherPostWithRetry, returnErrorResponse } from "./Fetchers"

const responseHasError = <T>(response: ResponseWithPosibleError<T>): response is ResponseWithError => {
    return (response as ResponseWithError).error
}

class _ApiClient implements ApiFunctions {

    private static instance: _ApiClient

    private constructor() { }

    static getInstance(): _ApiClient {
        if (!_ApiClient.instance) {
            _ApiClient.instance = new _ApiClient()
            return _ApiClient.instance
        } else {
            return _ApiClient.instance
        }
    }

    getToken = async (code: string): Promise<ResponseWithPosibleError<TokenViewModel>> => {
        const result = await fetcherPost<TokenBindingModel>(apiUrl.getToken, {
            grant_type: "authorization_code",
            code,
            client_id: EnvHelper.clientId,
            redirect_uri: EnvHelper.redirectUrl!
        })
        if (!result.error && result.response) {
            await saveTokens(result.response)
            return result.response
        } else {
            return returnErrorResponse(500)
        }
    }

    getTokenWithEmailAndPassword = async (): Promise<ResponseWithPosibleError<TokenViewModel>> => {
        const result = await fetcherPost<TokenWithEmailAndPasswordBindingModel>(apiUrl.getToken, {
            username: EnvHelper.loginInfo.username!,
            password: EnvHelper.loginInfo.password!,
            grant_type: EnvHelper.loginInfo.grant_type!,
            client_id: EnvHelper.loginInfo.client_id!,
            client_secret: EnvHelper.loginInfo.client_secret!,
            scope: EnvHelper.loginInfo.scope!,
        })
        if (!result.error && result.response) {
            await saveTokens(result.response)
            return result.response
        } else {
            return returnErrorResponse(500)
        }
    }

    refreshToken = async (refreshToken: string, logout: () => void): Promise<ResponseWithPosibleError<TokenViewModel>> => {
        const result = await fetcherPostWithRetry<TokenViewModel, RefreshTokenBindingModel>(apiUrl.getToken, {
            grant_type: "refresh_token",
            client_id: EnvHelper.clientId,
            refresh_token: refreshToken
        }, logout)
        if (!result.error && result.response) {
            await saveTokens(result.response)
        }
        return result
    }

    getUserInfo = async (logout: () => void): Promise<ResponseWithPosibleError<UserInfo>> => {
        const response = await fetcher<UserViewModel>(apiUrl.getUserInfo, logout)
        const userInfo = response.response
        if (!response.error && userInfo) {
            return {
                response: {
                    id: userInfo.usuario.id.toString(),
                    email: userInfo.usuario.email,
                    firstName: userInfo.usuario.given_name,
                    lastName: userInfo.usuario.family_name,
                    username: userInfo.usuario.preferred_username,
                },
                error: false,
            }
        } else {
            return returnErrorResponse(500)
        }
    }

    getDentistsByName = async (data: ClinicOrDentistSearchBindingModel, logout: () => void)
        : Promise<ResponseWithPosibleError<ClinicsByNameViewModel[]>> => {
        const now = moment().format("YYYYMMDD[T]HHmmZ")
        const queryParams = `${data.searchValue === "" ? "null" : data.searchValue}/fechaHoraDesde/${now}/tipoConsulta/cita_inicial?documentoId=${data.documentId}`
        const result = await fetcher<ClinicsByNameViewModel[]>(
            `${apiUrl.getClinicsByNameOrDentist}${queryParams}`,
            logout
        )
        if (!result.error && result.response) {
            return { response: result.response, error: false }
        } else {
            return returnErrorResponse(result.errorCode)
        }
    }

    getClinicsByLocation = async (query: ClinicsByLocationBindingModel, logout: () => void): Promise<ResponseWithPosibleError<AvailableAppointments>> => {
        const now = moment().format("YYYYMMDD[T]HHmmZ")
        const result = await fetcher<ClinicViewModel[]>(
            `${apiUrl.getAllClinics}/miLatitud/${query.latitude}/miLongitud/${query.longitude}/fechaHoraDesde/${now}/tipoConsulta/cita_inicial?documentoId=${query.documentId}`,
            logout
        )
        if (!responseHasError(result)) {
            return {
                response: digestAppointmentData(result.response),
                error: false,
            }
        } else {
            return returnErrorResponse(result.errorCode)
        }
    }

    getCashPayment = async (
        query: FinancingBindingModel,
        logout: () => void
    ): Promise<ResponseWithPosibleError<FinancingOptionsCash>> => {
        const result = await fetcherPostWithRetry<FinancingViewModelCash, FinancingBindingModel>(
            apiUrl.financings,
            query,
            logout
        )
        if (result.response) {
            return {
                response: {
                    orderId: result.response.ordenId,
                    importeContado: result.response.importeContado,
                    moneda: result.response.monedaContado,
                },
                error: result.error
            }
        } else {
            return returnErrorResponse(result.errorCode)
        }
    }

    getFinancingOptions = async (
        query: FinancingBindingModel,
        logout: () => void
    ): Promise<ResponseWithPosibleError<FinancingOptions>> => {
        const result = await fetcherPostWithRetry<FinancingViewModel, FinancingBindingModel>(
            apiUrl.financings,
            query,
            logout
        )
        if (result.response) {
            return {
                response: {
                    bestOfferId: result.response.mejorOfertaId,
                    quotation: result.response.cotizacionesMonedas.map(coin => {
                        return {
                            id: coin.id,
                            code: coin.codigo,
                            name: coin.moneda,
                            symbol: coin.simbolo,
                            // date: coin.ultimaCotizacion?.fecha,
                            value: coin.ultimaCotizacion?.valor,
                        }
                    }),
                    fundersList: result.response?.listaFinanciadores.map(funder => {
                        return {
                            funderName: funder.nombreFinanciador,
                            isClient: funder.esCliente,
                            offerList: funder.listaOfertas.map(offer => {
                                return {
                                    offerId: offer.ofertaId,
                                    amountOfInstallments: offer.cantidadCuotas,
                                    pricePerInstallment: offer.importeCuota,
                                    conditions: offer.condicionesOferta,
                                    offerType: offer.tipoOferta,
                                    currency: offer.monedaCodigo
                                }
                            })
                        }
                    })
                },
                error: result.error
            }
        } else {
            return returnErrorResponse(result.errorCode)
        }
    }

    getBaseFinancing = async (query: BaseFinancingBindingModel, logout: () => void): Promise<ResponseWithPosibleError<BaseFinancing>> => {
        const result = await fetcherPostWithRetry<BaseFinancingViewModel, BaseFinancingBindingModel>(apiUrl.baseFinancing, query, logout)
        if (!responseHasError(result)) {
            return {
                response: {
                    amountOfInstallments: result.response.cantidadCuotas,
                    conditions: result.response.condicionesOferta,
                    cashPayment: result.response.importeContado,
                    pricePerInstallment: result.response.importeCuota,
                    currency: result.response.monedaCuota
                },
                error: false,
            }
        } else {
            return returnErrorResponse(result.errorCode)
        }
    }

    registerFinancing = async (queryParams: RegisterFinancingBindingModel, logout: () => void): Promise<ResponseWithPosibleError<RegisteredFinancing>> => {
        const result = await fetcherPostWithRetry<RegisterFinancingViewModel, RegisterFinancingBindingModel>(apiUrl.registerFinancing, {
            ofertaId: queryParams.ofertaId,
            identificador: queryParams.identificador
        }, logout)
        if (!responseHasError(result)) {
            return {
                response: {
                    orderId: result.response.ordenId,
                    orderStatus: result.response.ordenEstado
                },
                error: false,
            }
        } else {
            return returnErrorResponse(result.errorCode)
        }
    }
    registerAppointment = async (queryParams: RegisterAppointmentBindingModel, logout: () => void): Promise<ResponseWithPosibleError<null>> => {
        const result = await fetcherPostWithRetry<null, RegisterAppointmentBindingModel>(apiUrl.appointments, {
            fechaHoraInicio: queryParams.fechaHoraInicio,
            ordenId: queryParams.ordenId,
            odontologoAgendaId: queryParams.odontologoAgendaId,
            tipoConsulta: "cita_inicial",
            telefono: queryParams.telefono,
            domicilio: queryParams.domicilio,
            ciudad: queryParams.ciudad,
            departamento: queryParams.departamento,
            pais: queryParams.pais,
            identificador: queryParams.identificador,
            email: queryParams.email,
            nombre: queryParams.nombre,
            apellido: queryParams.apellido,
            montoIngresos: queryParams.montoIngresos,
        }, logout)
        if (!responseHasError(result)) {
            return result
        } else {
            return returnErrorResponse(result.errorCode)
        }
    }

    getOrder = async (query: OrderBindingModel, logout: () => void): Promise<ResponseWithPosibleError<OrderInfo>> => {
        const result = await fetcher<OrderViewModel>(`${apiUrl.order}/${query.orderId}`, logout)
        if (!responseHasError(result)) {
            return {
                response: {
                    appointment: {
                        dateTime: result.response.fechaHoraInicio,
                        clinicName: result.response.clinicaNombre,
                        clinicAddress: result.response.clinicaDomicilio,
                        dentistName: result.response.odontologoNombre,
                        dentistSurname: result.response.odontologoApellido,
                        clinicCoordinates: {
                            latitude: result.response.clinicaLatitud,
                            longitude: result.response.clinicaLongitud,
                        },
                    },
                    treatments: result.response.tratamientos.map(treatment => {
                        return {
                            resourceId: treatment.recursoId,
                            amount: treatment.cantidad,
                        }
                    }),
                    payment: {
                        amountOfInstallments: result.response.cantidadDeCuotas,
                        pricePerInstallment: result.response.importeCuota,
                        installmentsCurrency: result.response.monedaCuota,
                        offerConditions: result.response.condicionesOferta,
                        financiatorName: result.response.nombreFinanciador,
                        financementApproved: result.response.financiamientoAprobado,
                        cashPaymentAmount: result.response.importeContado,
                        cashPayed: result.response.efectivoPagado,
                        cashPaymentEnabled: result.response.efectivoPagoHabilitado,
                        cashCurrency: result.response.monedaContado,
                    }
                },
                error: false,
            }
        } else {
            return returnErrorResponse(result.errorCode)
        }
    }

}


export const ApiClient = EnvHelper.apiMock
    ? MockClient.getInstance()
    : _ApiClient.getInstance()