import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { getRoutes } from '../../../utils/Routes';
import styled from 'styled-components';
import { PageContainer } from '../../../stylesGlobal/CommonStyles';
import { ButtonColor } from '../../../components/shared/Button';
import { ReactComponent as Check } from "../../../assets/icons/check.svg"
import { useTranslationTyped } from '../../../utils/i18n/i18nHelper';
import Overlay from '../../../components/shared/Overlay';
import Header from '../../../components/shared/Header';
import { useForm } from 'react-hook-form';
import { pagePadding, colors, zIndex } from '../../../stylesGlobal/Variables';
import { InputRadio } from '../../../components/shared/InputRadio';
import { Input } from '../../../components/shared/Inputs';
import FormErrorMessage from '../../../components/shared/FormErrorMessage';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import SmallOverlay from '../../../components/shared/SmallOverlay';
import { IonContent, IonPage } from '@ionic/react';
import GoBackButtonGroup from '../../../components/shared/GoBackButtonGroup';
import { paymentInfoState, orderState } from '../../../recoil/Atoms';
import produce from 'immer';
import { PageName, PaymentStateType } from '../../../utils/Types';
import { PaymentMethod } from '../../../utils/Enums';
import { getCashPaymentState } from '../../../recoil/Selectors';
import Spinner from '../../../components/shared/Spinner';
import { ErrorModal } from '../../../components/error/ErrorModal';
import { getPageNumber, timeoutPromise } from '../../../utils/HelperFunctions';
import { useGetCashAmount } from './RegisterEmptyFinancing';

const CashPaymentMethodPageWrapper = styled(PageContainer)`
    .cash__form{
        display:flex;
        flex-direction:column;
        padding:0 ${pagePadding.leftRight};
        margin-top:3rem;
        padding-bottom:13rem;
        position:absolute;
        left:0;
        width: 100%;
        z-index:${zIndex.two};
    }

    .cash__message{
        color:${colors.gray};
        font-size:1.2rem;
        margin-top:.5rem;
        margin-bottom:1rem;

        &.-first{
            margin-top:3rem;
        }
    }
`
type FormData = {
    cost: string,
}

const CashPaymentMethodPage: React.FC = () => {

    const setPaymentInfo = useSetRecoilState(paymentInfoState)
    const paymentInfo = useRecoilValue(getCashPaymentState)
    const setOrder = useSetRecoilState(orderState)

    const [errorOcurred, setErrorOcurred] = useState<"PRICE" | "SUBMIT" | null>(null)
    const [loading, setLoading] = useState(false)
    const t = useTranslationTyped()
    const history = useHistory()
    const { locationChooser } = getRoutes()

    const getCashAmount = useGetCashAmount()

    const { register, handleSubmit, errors } = useForm<FormData>({
        mode: "all",
        shouldFocusError: true,
    })


    const getPrice = async () => {
        try {
            setLoading(true)
            const result = await getCashAmount()
            setLoading(false)
            if (result.response) {
                setPaymentInfo(
                    produce((prevState: PaymentStateType | null) => {
                        if (prevState && prevState.paymentMethod === PaymentMethod.Cash) {
                            prevState.cost = parseInt(result.response.importeContado)
                            prevState.cashCurrency = result.response.moneda
                        }
                    })
                )
                setOrder({ orderId: result.response.orderId })
            } else {
                await timeoutPromise(100)
                setErrorOcurred("PRICE")
            }
        } catch (e) {
            await timeoutPromise(100)
            setLoading(false)
            setErrorOcurred("PRICE")
            console.error("Error while getting base financing", e)
        }
    }

    useEffect(() => {
        getPrice()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const onGoBack = () => {
        history.goBack()
    }

    const isFormWithErrors = () => !!errors.cost

    const onRetryCall = async () => {
        if (errorOcurred === "SUBMIT") {
            await onSubmit()
        } else if (errorOcurred === "PRICE") {
            await getPrice()
        }
        setErrorOcurred(null)
    }

    const onSubmit = async () => {
        history.push(locationChooser)
    }

    const renderForm = () => {
        return (
            <div>
                <ErrorModal
                    showErrorModal={!!errorOcurred}
                    onRetry={onRetryCall} />
                <form className="cash__form" onSubmit={handleSubmit(onSubmit)}>
                    <InputRadio
                        name="paymentMethod"
                        value={paymentInfo.paymentMethod}
                        label={t("paymentMethod", "form", paymentInfo.paymentMethod)}
                        defaultChecked={true}
                        selected={true}
                        withMarginBottom={true}
                    />
                    {!loading && paymentInfo.cost ?
                        <Input
                            type="text"
                            inputMode="text"
                            name="cost"
                            aria-label="Cost"
                            greyFont={true}
                            error={!!errors.cost}
                            readOnly={true}
                            value={`${paymentInfo.cashCurrency} ${paymentInfo.cost!.toLocaleString()}`}
                            ref={register({
                                required: {
                                    message: t("paymentMethod", "cashPaymentMethodForm", "amount", "errorMessage"),
                                    value: true,
                                },
                            })}
                        />
                        : <Spinner theme="light" />
                    }
                    <FormErrorMessage
                        message={(errors.cost && errors.cost.message) || ""}
                    />
                    <p className="cash__message">
                        {t("paymentMethod", "cashPaymentMethodForm", "second_message")}
                    </p>
                </form>
            </div>
        )

    }

    return (
        <IonPage>
            <IonContent forceOverscroll={true} scrollY={false} style={{ position: "fixed" }}>
                <CashPaymentMethodPageWrapper>
                    <Overlay scroll={true}>
                        <SmallOverlay>
                            <Header
                                title={t("paymentMethod", "pageTitle")}
                                subtitle={t("paymentMethod", "pageSubtitle")}
                                stepNumber={getPageNumber(PageName.Payment)}
                            />
                        </SmallOverlay>

                        <section
                        >
                            {renderForm()}
                        </section>
                    </Overlay>
                    <GoBackButtonGroup
                        onGoBack={onGoBack}
                        showSmallButtons={true}
                        readyButton={{
                            color: ButtonColor.blue,
                            onClick: handleSubmit(onSubmit),
                            iconSVG: <Check />,
                            label: t("paymentMethod", "cashPaymentMethodForm", "onSubmitButtonLabel"),
                            disabled: isFormWithErrors() || loading,
                        }}
                    />
                </CashPaymentMethodPageWrapper>
            </IonContent >
        </IonPage>
    )
}

export default CashPaymentMethodPage