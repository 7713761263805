import React, { useState } from 'react';
import { useHistory } from 'react-router';
import { getRoutes } from '../../utils/Routes';
import styled from 'styled-components';
import { PageContainer } from '../../stylesGlobal/CommonStyles';
import { ButtonColor } from '../../components/shared/Button';
import { ReactComponent as Check } from "../../assets/icons/check.svg"
import { useTranslationTyped } from '../../utils/i18n/i18nHelper';
import Overlay from '../../components/shared/Overlay';
import Header from '../../components/shared/Header';
import { pagePadding, screenSize, colors, zIndex } from '../../stylesGlobal/Variables';
import { InputRadio } from '../../components/shared/InputRadio';
import { useRecoilValue } from 'recoil';
import { IonPage, IonContent } from '@ionic/react';
import { Input } from '../../components/shared/Inputs';
import { OfferType, PaymentMethod } from '../../utils/Enums';
import { ErrorModal } from '../../components/error/ErrorModal';
import SmallOverlay from '../../components/shared/SmallOverlay';
import GoBackButtonGroup from '../../components/shared/GoBackButtonGroup';
import { financingOptionsState, paymentInfoState } from '../../recoil/Atoms';
import { PageName } from '../../utils/Types';
import { getBankIcon, getPageNumber, getQuotationFromPaymentInfo } from '../../utils/HelperFunctions';

const PaymentSummaryWrapper = styled(PageContainer)`
    .paymentSummary__content{
        display:flex;
        flex-direction:column;
        padding:0 ${pagePadding.leftRight};
        margin-top:1.5rem;
        position:absolute;
        left:0;
        width: 100%;
        z-index:${zIndex.two};
    }

    .paymentSummary__message{
        p{
            color:${colors.gray};
            font-size:1.2rem;
            margin-bottom:1.5rem;

            &.message__red{
                color:#FD8EA1;
            }

            &.message__bold{
                font-weight:600;
            }

            &.message__first{
                margin-top:3rem;
            }
        }
        .message_underline{
            text-decoration:underline;
        }


    }

    @media (min-width: ${screenSize.size_l}){
        .paymentSummary__content{
            margin-top:2rem;
        }
        .paymentSummary__message{
            margin-top:3rem;
        }

    }

`


const PaymentSummaryPage: React.FC = () => {
    const paymentInfo = useRecoilValue(paymentInfoState)
    const financingOptions = useRecoilValue(financingOptionsState)

    const t = useTranslationTyped()
    const [errorOcurred, setErrorOcurred] = useState(false)
    const { locationChooser } = getRoutes()

    const history = useHistory()

    const onGoBack = () => {
        history.goBack()
    }

    const getQuotation = (currency: string) => {
        if (paymentInfo && paymentInfo.paymentMethod !== PaymentMethod.Cash) {
            return getQuotationFromPaymentInfo(financingOptions!, currency)
        }
    }

    const getConvertionRateMessage = () => {
        if (paymentInfo
            && paymentInfo.paymentMethod !== PaymentMethod.Cash
            && paymentInfo.selectedOffer
        ) {
            const currency = paymentInfo?.selectedOffer.currency
            const quotation = getQuotation(currency)
            if (quotation?.value) {
                const message = `${t("paymentSummary", "convertionRateMessage1")} ${quotation.name}
                        ${t("paymentSummary", "convertionRateMessage2")} ${quotation.name}.
                        ${t("paymentSummary", "convertionRateMessage3")} ${Math.round(quotation?.value * paymentInfo?.selectedOffer.pricePerInstallment)}
                        ${t("paymentSummary", "convertionRateMessage4")}`
                return <p className="message__bold" > {message}</p>
            }
        }
    }

    const getMessage = () => {
        if (paymentInfo && paymentInfo.paymentMethod !== PaymentMethod.Cash && paymentInfo.selectedOffer) {
            if (paymentInfo.paymentMethod === PaymentMethod.Financing) {
                if (paymentInfo.selectedOffer.offerType === OfferType.preApproved) {
                    return (
                        <>
                            <p className="message__first">{t("paymentSummary", "messages", "loanAutomaticallyApproved")}</p>
                            <p className="message__red">
                                {t("paymentSummary", "messages", "emailReception")}
                                <span className="message_underline">
                                    {t("paymentSummary", "messages", "followInstructions")}
                                </span>
                            </p>
                        </>
                    )
                } else {
                    return (
                        <>
                            <p className="message__bold">{t("paymentSummary", "messages", "financingSecondAppointment")}</p>
                        </>
                    )

                }
            } else {
                if (paymentInfo.selectedOffer.offerType === OfferType.preApproved) {
                    return (
                        <>
                            <p className="message__first">{t("paymentSummary", "messages", "loanAutomaticallyApproved")}</p>
                            <p className="message__red">
                                {t("paymentSummary", "messages", "emailReception")}
                                <span className="message_underline">
                                    {t("paymentSummary", "messages", "followInstructions")}
                                </span>
                            </p>
                            <p>{t("paymentSummary", "messages", "cashPayment")}</p>
                        </>
                    )
                } else {
                    return (
                        <>
                            <p>{t("paymentSummary", "messages", "cashPayment")}</p>
                            <p className="message__bold">{t("paymentSummary", "messages", "cashFinancingSecondAppointment")}</p>
                        </>
                    )
                }
            }
        }
        return null
    }

    const handleClick = async () => {
        history.push(locationChooser)
    }

    const onRetryCall = async () => {
        setErrorOcurred(false)
        handleClick()
    }

    const renderSummary = () => {
        if (paymentInfo && paymentInfo.paymentMethod !== PaymentMethod.Cash && paymentInfo.selectedOffer) {
            return (
                <>
                    <section className="paymentSummary__content">
                        <InputRadio
                            name="paymentMethod"
                            value={paymentInfo.paymentMethod}
                            label={t("paymentMethod", "form", paymentInfo.paymentMethod)}
                            defaultChecked={true}
                            selected={true}
                            bankIcon={getBankIcon(paymentInfo.selectedFinancialEntityName)}
                        />
                        {paymentInfo.paymentMethod === PaymentMethod.CashAndFinancing && paymentInfo.cashCurrency
                            && <Input
                                type="text"
                                name="cashAmount"
                                aria-label="Cash amount"
                                greyFont={true}
                                readOnly
                                value={`${t("paymentSummary", "form", "cash")}: ${getQuotation(paymentInfo.cashCurrency)?.name} ${paymentInfo.amountCash?.toLocaleString()}`}
                            />
                        }
                        <InputRadio
                            name="offer"
                            label={`${paymentInfo.selectedOffer.amountOfInstallments} cuotas de 
                                ${Math.round(paymentInfo.selectedOffer.pricePerInstallment)}
                                ${getQuotation(paymentInfo.selectedOffer.currency)?.name}`}
                            value={paymentInfo.selectedOffer.offerId}
                            defaultChecked={true}
                            selected={true}
                            largeText
                        />
                        <div className="paymentSummary__message">
                            {getConvertionRateMessage()}
                            {getMessage()}
                        </div>
                    </section>
                </>
            )
        }
        return <></>
    }

    return (
        <IonPage>
            <IonContent>
                <PaymentSummaryWrapper>
                    <ErrorModal
                        showErrorModal={errorOcurred}
                        onRetry={onRetryCall} />
                    <Overlay scroll={true}>
                        <SmallOverlay>
                            <Header
                                title={t("paymentSummary", "pageTitle")}
                                subtitle={t("paymentSummary", "pageSubtitle")}
                                stepNumber={getPageNumber(PageName.Payment)}
                            />
                        </SmallOverlay>
                        <IonContent
                            scrollY={true}
                            forceOverscroll={false}
                        >
                            {renderSummary()}
                        </IonContent>
                    </Overlay>
                    <GoBackButtonGroup
                        onGoBack={onGoBack}
                        showSmallButtons={true}
                        readyButton={{
                            color: ButtonColor.blue,
                            onClick: handleClick,
                            iconSVG: <Check />,
                            label: t("paymentSummary", "onSubmitButtonLabel"),
                        }}
                    />
                </PaymentSummaryWrapper>
            </IonContent>
        </IonPage>
    )
}

export default PaymentSummaryPage