import React from "react"
import styled from "styled-components"
import { ReactComponent as ErrorIcon } from "../../assets/icons/error.svg"
import { useTranslationTyped } from "../../utils/i18n/i18nHelper"
import Button, { ButtonColor } from "./Button"
import { colors, pagePadding } from "../../stylesGlobal/Variables"

const ErrorModalWrapper = styled.div`
    display:flex;
    flex-direction:column;
    justify-content:center;
    align-items:center;
    margin-top:2rem;

    .error__button{
        padding:0 ${pagePadding.leftRight};
        margin-top:3rem;
        width:100%;
    }

    svg{
        width:4rem;
        height:4rem;
        margin-bottom:1rem;
    }

    p{
        font-size:1.6rem;
        text-align:center;
        color:${colors.gray};

        &.label{
            font-size:1.8rem;
            font-weight:600;
            margin-bottom:1rem;
            width: 70%;
            line-height: 1.5;
            color:${colors.darkBlue};
        }
    }

    button{
        margin-top:1.5rem;
    }
`

type Props = {
    handleRetry: () => void,
    label: string,
}

const ErrorMessage = (props: Props) => {
    const t = useTranslationTyped()

    return (
        <ErrorModalWrapper>
            <ErrorIcon />
            <p className="label">{props.label}</p>
            <p>{t("generic", "tryAgain")}</p>
            <div className="error__button">
                <Button
                    color={ButtonColor.red}
                    label={t("generic", "retry")}
                    onClick={props.handleRetry}
                />
            </div>
        </ErrorModalWrapper>
    )
}

export default ErrorMessage