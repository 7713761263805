import React from "react"
import styled, { css } from "styled-components"
import { zIndex, colors } from "../../stylesGlobal/Variables"
import { HideScrollBar } from "../../stylesGlobal/CommonStyles"

type OverlayWrapperProps = {
    large?: boolean,
    scroll?: boolean,
    fullHeight?: boolean
}

const OverlayWrapper = styled.section<OverlayWrapperProps>`
    position:relative;
    width:100%;
    top:0;
    left:0;
    z-index:${zIndex.two};
    background-color:${colors.white};
    /* height: ${p => p.large ? "80vh" : "74vh"}; */
    flex: 1;
    display: flex;

    .overlay__gradient{
        position:absolute;
        width:100%;
        bottom:0;
        left:0;
        height:50px;
        background: linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(255,255,255,.94) 100%);
        z-index:999;
        pointer-events:none;
    }
    
    .overlay__container{
        width:100%;
        position:relative;
        ${p => p.scroll && css`
        overflow-y:auto;
        ${HideScrollBar};
        `}
        display: flex;
        flex-direction: column;
        overflow:hidden;
        flex: 1;
        ${p => p.fullHeight && 'height: 100%'};
    }
    
`

type Props = {
    children: JSX.Element | JSX.Element[],
    large?: boolean,
    scroll?: boolean,
    fullHeight?: boolean,
}

const Overlay = (props: Props) => {
    return (
        <OverlayWrapper large={props.large} scroll={props.scroll} fullHeight={props.fullHeight}>
            <section className="overlay__container">
                {props.children}
                {props.scroll && <div className="overlay__gradient" />}
            </section>
        </OverlayWrapper>
    )
}

export default Overlay