import React from "react"
import styled from "styled-components"
import { colors, screenSize } from "../../stylesGlobal/Variables"

type SmallOverlayWrapperProps = {
    hideOverflow: boolean,
    extraHeight?: number,
}

const SmallOverlayWrapper = styled.section<SmallOverlayWrapperProps>`
    position:relative;
    width:100%;
    top:0;
    left:0;
    background:${colors.darkGradient};
    padding-top: 4rem;
    padding-top: calc(1rem + env(safe-area-inset-top, 2rem));
    border-radius:0 0 2rem 2rem;
    box-shadow: 0px 4px 39px 0px rgba(0,49,146,.35);
    padding-bottom: ${props => props.extraHeight ? `${props.extraHeight}rem` : "2.5rem"};
    overflow: ${props => props.hideOverflow ? "hidden" : "unset"};
    z-index: ${props => props.hideOverflow ? 3 : "unset"};

    .smallOverlay__container{
        width:100%;
        height:100%;
        position:relative;
    }

    .circle{
        position:absolute;
        width:30rem;
        height:30rem;
        border-radius:100%;
        background:#262F5A;
        mix-blend-mode:lighten;

        &._1{
            left: -17rem;
            bottom: -16rem;
        }
        &._2{
            right: -18rem;
            bottom: -19rem;
        }
        &._3{
            right: 0;
            top: -25rem;
        }
    }

    @media (min-width:${screenSize.size_m})
    and (min-height:670px){
        .circle{
            &._1{
                left: -14rem;
                bottom: -15rem;
            }
            &._2{
                right: -17rem;
                bottom: -18rem;
            }
            &._3{
                right: -2rem;
                top: -23rem;
            }
        }
    }

    @media (min-width: ${screenSize.size_l}){
        .circle{
            &._2{
                bottom: -17rem;
            }
            &._3{
                right: 0rem;
            }
        }
    }

    @media (min-height: 800px){

        .circle{
            &._1{
                left: -13rem;
                bottom: -13rem;
            }
            &._2{
                right: -18rem;
                bottom: -15rem;
            }
            &._3{
                right: -2rem;
                top: -23rem;
            }
        }
    }
`


type Props = {
    children: JSX.Element | JSX.Element[],
    hideOverflow?: boolean,
    extraHeight?: number,
}

const SmallOverlay = (props: Props) => {
    return (
        <SmallOverlayWrapper hideOverflow={props.hideOverflow!} extraHeight={props.extraHeight}>
            <span className="circle _1" />
            <span className="circle _2" />
            <span className="circle _3" />
            <section className="smallOverlay__container">
                {props.children}
            </section>
        </SmallOverlayWrapper>
    )
}

export default SmallOverlay