import { SetterOrUpdater } from "recoil"
import { Resource, PageId } from "../../utils/Enums"
import { UserLocation, UserInfo, PictureInfo } from "../../utils/Types"
import * as Sentry from "@sentry/react"
import {
    getFlowFromStorage,
    getResourceFromStorage,
    getUserLocationFromStorage,
    getUserInfoFromStorage,
    getTeethPictureFromStorage,
    getOdontogramTutorialStateFromStorage,
    getCompletedOrderStateFromStorage
} from "./PersistenceDataAccess"

export const initializeStateAndWasUpdated = async (
    setTreatmentState: SetterOrUpdater<PageId[] | null>,
    setResourceState: SetterOrUpdater<Resource | null>,
    setUserLocationState: SetterOrUpdater<UserLocation | null>,
    setUserInfoState: SetterOrUpdater<UserInfo | null>,
    setTeethPicture: SetterOrUpdater<PictureInfo | null>,
    setOdontogramTutorialState: SetterOrUpdater<boolean | null>,
    setCompletedOrderState: SetterOrUpdater<boolean | null>,
) => {

    const cleanRecoilStates = () => {
        setTreatmentState([])
        setResourceState(null)
        setUserLocationState(null)
        setUserInfoState(null)
        setTeethPicture(null)
        setCompletedOrderState(false)
    }

    try {
        Sentry.addBreadcrumb({ message: "Se inicializa el estado en base al storage" })
        const completedOrder = await loadCompletedOrderState(setCompletedOrderState)
        if (!completedOrder) {
            const canContinueFlow = await loadTreatmentState(setTreatmentState)
            await loadResourceState(setResourceState)
            await loadUserLocationState(setUserLocationState)
            await loadUserInfoState(setUserInfoState)
            await loadTeethPictureState(setTeethPicture)
            await loadOdontogramTutorialState(setOdontogramTutorialState)
            return canContinueFlow
        } else {
            setCompletedOrderState(false)
            await loadUserLocationState(setUserLocationState)
            await loadUserInfoState(setUserInfoState)
            await loadTeethPictureState(setTeethPicture)
        }
    } catch (e) {
        console.error("Error initializing state")
        Sentry.captureException(e)
        cleanRecoilStates()
        return false
    }
}

const loadState = <T>(
    getStateFromStorage: () => Promise<T>,
    shouldSetState?: (state: T) => boolean,
) => {
    return async (setRecoilState: SetterOrUpdater<T>) => {
        const savedState = await getStateFromStorage()
        const shouldUpdate = shouldSetState ? shouldSetState(savedState) : !!savedState
        if (shouldUpdate) {
            setRecoilState(savedState)
        }
        return shouldUpdate
    }
}

export const loadTreatmentState = loadState(
    getFlowFromStorage,
    (treatment: PageId[] | null) => !!treatment && !!treatment.length
)


export const loadResourceState = loadState(getResourceFromStorage)


export const loadUserLocationState = loadState(getUserLocationFromStorage)

export const loadUserInfoState = loadState(getUserInfoFromStorage)

export const loadTeethPictureState = loadState(getTeethPictureFromStorage)

export const loadOdontogramTutorialState = loadState(
    getOdontogramTutorialStateFromStorage,
    (hasSeenTutorial: boolean | null) => hasSeenTutorial !== null)

export const loadCompletedOrderState = loadState(getCompletedOrderStateFromStorage)
