import React, { useRef } from "react"
import { motion } from "framer-motion"
import { useRecoilValue } from "recoil"
import { teethPictureState } from "../../recoil/Atoms"
import styled from "styled-components"

const PictureWrapper = styled(motion.section)`
    position: absolute;
    z-index: 4;
    bottom: 0;
    top: 0;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    pointer-events:none;

    .teeth__picture{
        display: block;
        width: 100%;
        object-fit: cover;
        max-height: 18rem;
        border-radius: 2.1rem;
        transform: scaleX(-1);
    }

    .teeth__picture__holder {
        height: 3rem;
        width: 0.4rem;
        background: rgba(255, 255, 255, 0.35);
        position: absolute;
        z-index: 5;
        border-radius: 0.2rem;
        left: 1rem;
    }

    .teeth__picture__container {
        width: calc(100% - 50px);
        display: flex;
        justify-content: center;
        align-items: center;
        pointer-events:all;
        left: 90%;
        position: absolute;
    }

    .teeth__picture__hidden {
        /* left: 90%; */
    }
`

// const MINIMUN_VELOCITY = 20
// const MINIMUN_POINT = 45

export const PictureComponent: React.FC = () => {

    // const [hiddenPicture, setHiddenPicture] = useState(true)
    const teethPictureInfo = useRecoilValue(teethPictureState)
    const imageRef = useRef<HTMLDivElement>(null)

    // const onPicturePress = () => {
    //     setHiddenPicture(!hiddenPicture)
    //     trackEvent({
    //         eventName: EventNames.MovesPicture,
    //         isNowHidden: !hiddenPicture
    //     })
    // }

    // const onDragEnd = (event: TouchEvent, info: PanInfo) => {
    //     const shouldClose =
    //         info.velocity.x > MINIMUN_VELOCITY || (info.velocity.x >= 0 && info.point.x > MINIMUN_POINT);
    //     if (shouldClose) {
    //         setHiddenPicture(true)
    //     } else {
    //         setHiddenPicture(false)
    //     }
    // }

    if (teethPictureInfo?.picturePath) {
        return <PictureWrapper>
            <motion.div
                drag
                dragConstraints={{ left: -(window.innerWidth - (window.innerWidth / 6)), right: 0, top: -window.innerHeight / 3, bottom: 0 }}
                // layout
                // transition={{ type: 'spring', bounce: 0.4 }}
                ref={imageRef}
                // onDragEnd={onDragEnd}
                className={`teeth__picture__container`}
            // onClick={onPicturePress}
            >
                <img src={teethPictureInfo.pictureCropedPath}
                    alt="Teeth Selfie"
                    className={"teeth__picture"}
                />
                <div className="teeth__picture__holder" />
            </motion.div>
        </PictureWrapper>
    } else {
        return null
    }

}