export const generateValidationDigit: (
    idNumber: string | number
) => number = idNumber => {
    let ci = typeof idNumber === 'number' ? idNumber.toString() : idNumber;

    if (ci.length <= 6) {
        for (let i = ci.length; i < 7; i++) {
            ci = '0' + ci;
        }
    }

    return safeGenerateValidationDigit(ci);
};

const safeGenerateValidationDigit: (idNumber: string) => number = idNumber => {
    let ci = idNumber;
    let sum = 0;

    for (let i = 0; i < 7; i++) {
        sum += (parseInt('2987634'[i]) * parseInt(ci[i])) % 10;
    }

    if (sum % 10 === 0) {
        return 0;
    }

    return 10 - (sum % 10);
};

const cleanIdNumber: (idNumber: string) => string = idNumber => idNumber.replace(/\D/g, '');

export const validateIdentificationNumber: (
    idNumber: string | number
) => boolean = idNumber => {
    const ci = typeof idNumber === 'number' ? idNumber.toString() : idNumber;

    if (ci.length < 7 || ci.length > 8) {
        return false;
    }

    const cleanNumber = cleanIdNumber(ci);
    const possibleValidationDigit = parseInt(
        cleanNumber[cleanNumber.length - 1],
        10
    );

    const validableNumber = cleanNumber.replace(/[0-9]$/, '');
    const actualValidationDigit = generateValidationDigit(validableNumber);

    return possibleValidationDigit === actualValidationDigit;
};