import React from "react"
import styled from "styled-components"
import { colors, screenSize, pagePadding } from "../../stylesGlobal/Variables"

type StyleProps = {
    small?: boolean
}

const ListItemWrapper = styled.li<StyleProps>`
    display:flex;
    flex-direction:column;
    padding: ${p => p.small ? "1rem" : "2rem"} ${pagePadding.leftRight};
    border-bottom:1px solid #DEE5F1;

    .listItem__title{
        font-size:1.6rem;
        font-weight:600;
        margin-bottom:.3rem;
    }

    .listItem__subtitle{
        font-size:1.2rem;
        font-weight:400;
        color:#444b50;
        margin-bottom:.1rem;
    }

    .listItem__text {
        color:${colors.gray};
        font-size:1rem;
        text-overflow: ellipsis;
    }

    @media (min-width:${screenSize.size_m})
    and (min-height:670px){
        .listItem__title{
            margin-bottom:.3rem;
        }
        .listItem__subtitle{
            font-size:1.2rem;
        }
    }

    @media (min-height: 800px){
        .listItem__title{
            margin-bottom:.3rem;
        }
    }
    

`

type Props = {
    title: string,
    subtitle?: string,
    onClick?: () => void,
    small?: boolean,
    text?: string,
}

const ListItem = (props: Props) => {

    return (
        <ListItemWrapper
            onClick={props.onClick}
            small={props.small}
        >
            <p className="listItem__title">
                {props.title}
            </p>
            {props.subtitle &&
                <div>
                    <p className="listItem__subtitle">
                        {props.subtitle}
                    </p>
                    {props.text &&
                        <p className="listItem__text">{props.text}</p>
                    }
                </div>
            }
        </ListItemWrapper>
    )
}

export default ListItem