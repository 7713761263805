import React from 'react';
import styled from 'styled-components';
import { screenSize } from '../../../../stylesGlobal/Variables';
import { AnimatePresence, motion } from "framer-motion"

export enum ID_TYPE_ENUM {
    CHIP = 'CHIP',
    CLASSIC = 'CLASSIC'
}

interface Props {
    value: ID_TYPE_ENUM,
    onChange: (newValue: ID_TYPE_ENUM) => void
}

const IdSwitchWraper = styled.div<{ value: ID_TYPE_ENUM }>`
    padding: 1.2rem 1.2rem;
    border: 1px solid #DEE5F1;
    font-size:1.4rem;
    font-weight:500;
    margin-bottom:.75rem;
    margin-top:.75rem;
    box-shadow: 0px 2px 5px 0px rgba(0,0,0,.1);
    border-radius:.5rem;
    background:#fff;
    outline:none;
    appearance:none;
    position: relative;
    height: 51px;

    .fixed_container {
        color: #88949e;
        position: absolute;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        top: 0;
        left: 0;
        padding: 0 3rem 0 3rem;
    }

    .switch_container {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        display: flex;
        flex-direction: row;
        justify-content: ${(props) => props.value === ID_TYPE_ENUM.CLASSIC ? 'flex-start' : 'flex-end'};

        p {
            padding: 1rem;
            margin: 0.4rem;
            border-radius: 4px;
            background: #0E53FC;
            color: white;
            display: flex;
            justify-content: center;
            align-items: center;
            min-width: 50%;
        }
    }

    @media (min-width: ${screenSize.size_l}){
        padding: 1.6rem 1.2rem;
        font-size:1.6rem;
    }
`;

const IdSwitch: React.FC<Props> = (props) => {

    const getActiveToggle = () => {
        switch (props.value) {
            case ID_TYPE_ENUM.CLASSIC:
                return <motion.span exit={{ opacity: 0 }} animate={{ opacity: 1 }} initial={{ opacity: 0 }}>C.I tradicional</motion.span>
            case ID_TYPE_ENUM.CHIP:
                return <motion.span initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>C.I con chip</motion.span>
        }
    }

    return (
        <IdSwitchWraper value={props.value} onClick={() => props.onChange(props.value === ID_TYPE_ENUM.CLASSIC ? ID_TYPE_ENUM.CHIP : ID_TYPE_ENUM.CLASSIC)}>
            <div className="fixed_container">
                <p>C.I tradicional</p>
                <p>C.I con chip</p>
            </div>
            <AnimatePresence initial={false}>
                <motion.div className="switch_container">
                    <motion.p layout>
                        {getActiveToggle()}
                    </motion.p>
                </motion.div>
            </AnimatePresence>
        </IdSwitchWraper>
    )
}

export default IdSwitch;