import React from 'react';
import { IonApp } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';

import { RecoilRoot } from 'recoil';
import { GlobalStyles } from './stylesGlobal/GlobalStyles';
import ScrollToTop from './utils/ScrollToTop';
import ErrorBoundary from './components/error/ErrorBoundry';
import AppRouter from './components/rootContainer/AppRouter';

import './App.css';

const App: React.FC = () => {

  return (
    <React.Fragment>
      <IonApp>
        <ErrorBoundary>
          <RecoilRoot>
            <GlobalStyles />
            <IonReactRouter>
              <ScrollToTop />
              <AppRouter />
            </IonReactRouter>
          </RecoilRoot>
        </ErrorBoundary>
      </IonApp>
    </React.Fragment>
  )
}

export default App;
