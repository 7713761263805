import React from "react"
import styled, { css } from "styled-components"
import { colors } from "../../stylesGlobal/Variables"

export enum ButtonColor {
    blue = "BLUE",
    white = "WHITE",
    transparent = "TRANSPARENT",
    red = "RED"
}

type ButtonWrapperProps = {
    color: ButtonColor,
    small?: boolean,
    micro?: boolean,
    withBorder?: boolean,
}

const getButtonBackground = (color: ButtonColor) => {
    switch (color) {
        case ButtonColor.blue:
            return colors.blue
        case ButtonColor.white:
            return colors.white
        case ButtonColor.transparent:
            return "transparent"
        case ButtonColor.red:
            return colors.red
        default:
            console.error(`${color} not exists`)
    }
}

const getButtonFontColor = (color: ButtonColor) => {
    if (color === ButtonColor.white) {
        return colors.darkBlue
    } else {
        return colors.white
    }
}

const ButtonWrapper = styled.button<ButtonWrapperProps>`
    max-height: 50px;
    border:none;
    position:relative;
    width:${p => p.small ? p.micro ? "5rem" : "auto" : "100%"};
    flex-grow: ${p => p.micro ? "auto" : "1"};
    margin-left: ${p => !p.micro && p.small  ? "1rem" : "0"};
    height:${p => p.small ? "5rem" : "unset"};
    font-size: ${p => p.small ? "1.3rem" : "1.6rem"};
    font-weight: 500;
    border-radius:${p => p.small ? "7px" : ".5rem"};
    background-color:${p => getButtonBackground(p.color)};
    color:${p => getButtonFontColor(p.color)};
    display:flex;
    align-items:center;
    justify-content:center;
    padding:2rem 13px;
    transition:background-color .3s;
    backdrop-filter: blur(8px);

    &:focus{
        outline:none;
    }

    &:disabled{
        background-color:#E4E4E4;
    }

    ${p => p.withBorder && css`
        border:1px solid ${colors.darkBlue};
    `}

    ${p => p.small
        ? css`
        svg{
            width:18px;
            height:18px;
            position:absolute;
            left:calc(50% - 9px);
            top:calc(50% - 9px);
        }

    `
        : css`
            svg{
                
                position:absolute;
                left:1.5rem;
                top:calc(50% - 8px);
            }
    `}

    
`

type Props = {
    micro?: boolean,
    label?: string,
    color: ButtonColor,
    onClick: () => void,
    iconSVG?: JSX.Element,
    disabled?: boolean,
    small?: boolean,
    withBorder?: boolean,
}

const Button = (props: Props) => {
    return (
        <ButtonWrapper
            color={props.color}
            onClick={props.onClick}
            disabled={props.disabled}
            small={props.small}
            micro={props.micro}
            withBorder={props.withBorder}
        >
            {props.iconSVG && props.iconSVG}
            {!props.micro && props.label}
        </ButtonWrapper>
    )
}

export default Button