import styled, { css } from "styled-components"
import { colors, screenSize } from "../../stylesGlobal/Variables"
import select_background from "../../assets/icons/select_background.png"

type InputWrapperProps = {
    error?: boolean,
    greyFont?: boolean,
}

export const InputGeneralCSS = css<InputWrapperProps>`
    padding: 1.2rem 1.2rem;
    border: 1px solid ${p => p.error ? colors.red : "#DEE5F1"};
    color:${p => p.greyFont ? "#88949e" : colors.darkBlue};
    font-size:1.4rem;
    font-weight:500;
    margin-bottom:.75rem;
    margin-top:.75rem;
    box-shadow: 0px 2px 5px 0px rgba(0,0,0,.1);
    border-radius:.5rem;
    background:#fff;
    outline:none;
    appearance:none;
    min-height: 50px;
    width: 100%;

    &:focus{
        outline:none;
        border:1px solid ${p => p.error ? colors.red : colors.darkBlue};
    }

    ::placeholder { 
        color: ${colors.gray};
        font-weight:400;
    }
    
    @media (min-width: ${screenSize.size_l}){
        padding: 1.6rem 1.2rem;
        font-size:1.6rem;
    }
`

export const Input = styled.input<InputWrapperProps>`
    ${InputGeneralCSS};
`

export const InputSelect = styled.select<InputWrapperProps>`
    ${InputGeneralCSS};

    background:none;
    background-color:white;
    background-image:url(${select_background});
    background-repeat: no-repeat;
    background-position: 95% 50%;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
`
