import React from "react"
import styled from "styled-components"
import { colors, screenSize } from "../../stylesGlobal/Variables"

type TreatmentFlowItemWrapperProps = {
    withMarginBottom?: boolean
}

const TreatmentFlowItemWrapper = styled.li<TreatmentFlowItemWrapperProps>`
    width:100%;
    position:relative;
    display:flex;
    align-items:center;
    border:.5px solid #C6CDD8;
    box-shadow: 0px 48px 24px -40px rgba(0,0,0,0.18);
    background-color:${colors.white};
    border-radius:.5rem;
    margin-bottom:${p => p.withMarginBottom ? "12rem" : "2.3rem"};
    height:8rem;
    
    .treatment__image{
        position: absolute;
        width: auto;
        left: -30px;
        width: auto;
        height: 141%;
    }
    
    .treatment__info{
        display:flex;
        flex-direction:column;
        padding:2rem 2rem 2rem 30%;
    }

    .treatment__title{
        font-size:1.4rem;
        font-weight:600;
        color:${colors.darkBlue};
        text-align:left;
    }
    .treatment__subtitle{
        font-size:1.2rem;
        font-weight:400;
        color:${colors.gray};
    }

    @media (min-width: ${screenSize.size_m}){
        height:12rem;
        .treatment__info{
            padding-left: 40%;
        }
        .treatment__image{
            left: -40px;
            height: 127%;
        }
        .treatment__title{
            font-size:1.6rem;
            margin-bottom:1rem;
        }
        .treatment__subtitle{
            font-size:1.3rem;
        }
    }
    @media (min-width: ${screenSize.size_l}){
        .treatment__title{
            font-size:1.8rem;
        }
    }
    @media (min-width: ${screenSize.size_xl}){
        height:13rem;        
    }

    @media (min-height: 800px){
        height:14rem;
    }
`

type Props = {
    title: string,
    subtitle: string,
    onClick: () => void,
    withMarginBottom?: boolean,
    image: string,
}

const TreatmentFlowItem = (props: Props) => {

    return (
        <TreatmentFlowItemWrapper
            onClick={props.onClick}
            withMarginBottom={props.withMarginBottom}
        >
            <img className="treatment__image" src={props.image} alt="treatment illustration" />
            <div className="treatment__info">
                <h2 className="treatment__title">{props.title}</h2>
                <h3 className="treatment__subtitle">{props.subtitle}</h3>
            </div>
        </TreatmentFlowItemWrapper>
    )
}

export default TreatmentFlowItem