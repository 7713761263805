import React, { useCallback, useEffect, useRef, useState } from "react"
import styled from "styled-components"
import { IonModal, IonContent } from "@ionic/react"
import SmallOverlay from "../shared/SmallOverlay"
import SearchInput from "./SearchInput"
import { screenSize, colors, pagePadding, zIndex } from "../../stylesGlobal/Variables"
import Spinner from "../shared/Spinner"
import ListItem from "../shared/ListItem"
import { useTranslationTyped } from "../../utils/i18n/i18nHelper"
import { ReactComponent as Back } from "../../assets/icons/back.svg"
import { ApiClient } from "../../api/ApiClient"
import EmptyState from "../shared/EmptyState"
import ErrorMessage from "../shared/ErrorMessage"
import { useHistory } from "react-router"
import { getRoutes } from "../../utils/Routes"
import { paymentInfoState, selectedDentistState } from "../../recoil/Atoms"
import { useRecoilValue, useSetRecoilState } from "recoil"
import { PageContainer, HideScrollBar } from "../../stylesGlobal/CommonStyles"
import { ClinicsByNameViewModel, DentistByNameView } from "../../api/clientInterfaces/ClinicModel"
import { digestAppointmentData } from "../../api/helpers/AppointmentHelper"
import { useLogout } from "../../utils/customHooks/useLogout"
import { debounce } from "lodash"
import { flatten } from "../../utils/HelperFunctions"

const SearchModalWrapper = styled(PageContainer)`

    .search__page{
        position:fixed;
        width:100%;
        left:0;
        z-index:${zIndex.two};
        background-color:${colors.white};
        height: 100%;
    }
    .search__container{
        width:100%;
        height:100%;
        position:relative;        
        ${HideScrollBar};
    }
    
    .search__clinicList{
        position: relative;
        left:0;
        display:flex;
        flex-direction:column;
        width: 100%;
        height: calc(100% - 12.5rem);
        overflow-y: scroll;
        ${HideScrollBar};
    }

    .search__back{
        border:none;
        background:none;
        font-size:1.6rem;
        color:${colors.white};
        display:flex;
        align-items:center;
        margin-left:${pagePadding.leftRight};
        margin-bottom:3rem;

        &:focus{
            outline:none;
        }

        svg{
            min-width:1rem;
            min-height:1.3rem;
            margin-right:1rem;
        }
    }

    @media (min-width:${screenSize.size_m})
    and (min-height:670px){
        .search__back{
            margin-top:1rem;
            margin-bottom:4rem;
        }
    }
`
type Props = {
    isOpen: boolean,
    onClose: () => void,
}

const SearchModal = (props: Props) => {
    const t = useTranslationTyped()
    const history = useHistory()
    const { appointmentChooser } = getRoutes()
    const logout = useLogout()
    const [dentistList, setDesntistList] = useState<ClinicsByNameViewModel[]>()
    const [error, setError] = useState(false)
    const [loading, setLoading] = useState(false)

    const [searchValue, setSearchValue] = useState("")
    const searchValueRef = useRef<string>()
    searchValueRef.current = searchValue
    const setSelectedDentist = useSetRecoilState(selectedDentistState)
    const paymentInfo = useRecoilValue(paymentInfoState)


    const getDentist = async (searchValue: string) => {
        setError(false)
        setLoading(true)
        const response = await ApiClient.getDentistsByName({ searchValue: searchValue, documentId: paymentInfo?.documentId! }, logout)
        if (searchValueRef.current === searchValue) {
            if (!response.error) {
                setDesntistList(response.response)
                setLoading(false)
            } else {
                setError(true)
                setLoading(false)
            }
        }
    }

    const throttled = useCallback(
        debounce(getDentist, 1000, { trailing: true })
        , [])

    useEffect(() => {
        if (props.isOpen) {
            setLoading(true)
            throttled(searchValue)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchValue, props.isOpen])


    const handleInputChange = (value: string) => {
        setSearchValue(value)
    }

    const handleSubmit = () => {
        throttled(searchValue)
    }

    const onClickDentist = (clinic: ClinicsByNameViewModel, dentist: DentistByNameView) => () => {
        setSelectedDentist(digestAppointmentData(
            [{
                ...clinic,
                id: 0,
                nombre: "",
                telefono: "",
                distance: 1,
                latitud: 0,
                longitud: 0,
                agendas: [],
                tipo: "CLINICA",
                idExternoAgenda: "",
                odontologos: [{
                    ...dentist,
                    idExternoAgenda: dentist.idExternoAgenda,
                    id: "",
                }]
            }],
        ))
        props.onClose()
        history.push(appointmentChooser)
    }

    const renderList = () => {
        if (loading) {
            return <Spinner theme="dark" />
        } else if (error) {
            return <ErrorMessage
                label={t("location", "error")}
                handleRetry={() => getDentist(searchValue)}
            />
        } else if (
            !loading
            && dentistList
            && !error
        ) {
            if (searchValue !== "" && dentistList.length === 0) {
                return <EmptyState message={t("location", "emptySearchResults")} />
            } else if (dentistList.length > 0) {
                return (
                    flatten(dentistList.map((c) => {
                        return c.odontologos.map(o => {
                            return (
                                <ListItem
                                    title={`${o.nombre} ${o.apellido}`}
                                    subtitle={`${c.nombre} ${c.apellido}`}
                                    key={`${c.apellido}-${o.nombre}-${o.apellido}`}
                                    text={c.domicilio}
                                    onClick={onClickDentist(c, o)}
                                    small={true}
                                />
                            )
                        })
                    })
                    )
                )
            }
        }
        return null
    }

    return (

        <IonModal isOpen={props.isOpen}>
            <IonContent forceOverscroll={false} scrollX={false}>
                <SearchModalWrapper>
                    <section className="search__page">
                        <section className="search__container">

                            <SmallOverlay>
                                <button
                                    onClick={props.onClose}
                                    className="search__back"
                                >
                                    <Back />
                                    {t("generic", "return")}
                                </button>
                                <SearchInput
                                    onChange={handleInputChange}
                                    onSubmit={handleSubmit}
                                    value={searchValue}
                                />
                            </SmallOverlay>
                            <ul className="search__clinicList">
                                {renderList()}
                            </ul>
                        </section>
                    </section>
                </SearchModalWrapper>
            </IonContent>
        </IonModal >

    )
}

export default SearchModal