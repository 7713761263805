import { useTranslationTyped } from "../../../../utils/i18n/i18nHelper"
import { validateIdentificationNumber } from "./ciValidation";
import { ID_TYPE_ENUM } from "./IdSwitch";

export const useCashIsLessThanPrice = () => {
    const t = useTranslationTyped()
    return (cashAmount: number, maxValue: number) => {
        return cashAmount < maxValue || t("paymentMethod", "financingMethodForm", "cashAmount", "maxErrorMessage")
    }
}

export const useCiIsValid = () => {
    const t = useTranslationTyped()
    return (value: string) => {
        return validateIdentificationNumber(value) || t("paymentMethod", "financingMethodForm", "documentId", "errorMessage")
    }
}

export const useDocumentValidatorValidator = () => {
    const t = useTranslationTyped()
    return (value: string, idType: ID_TYPE_ENUM) => {
        if (idType === ID_TYPE_ENUM.CLASSIC) {
            const mustBeLetter = value[0]
            const mustBeNumbers = value.substring(1)
            return (mustBeLetter.match(/[a-z]/i)
                && mustBeNumbers.length === 6
                && mustBeNumbers.match(/^\d+$/))
                || t("paymentMethod", "financingMethodForm", "documentValidatorIdClassic", "errorMessage")
        } else {
            return true
        }
    }
}