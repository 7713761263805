import ky from "ky";
import { apiUrl, ResponseWithError, ResponseWithPosibleError } from "./ApiConfig";
import { getTokenFromStorage } from "./AuthTokenHelper";
import * as Sentry from "@sentry/react"
import { ApiClient } from "./ApiClient";
import { cleanLocalStorage } from "../utils/LocalStorageConnector";


export const fetcherPost = async <U extends Object>(url: string, payload: U) => {
    let resp: Response
    if (url === apiUrl.getToken) {
        const rawData = new URLSearchParams(Object.entries(payload));
        resp = await ky.post(url, {
            body: rawData,
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
            retry: 0,
            timeout: 30000,
        })
    } else {
        const token = await getTokenFromStorage()
        resp = await ky.post(url, {
            body: JSON.stringify(payload),
            headers: {
                'Content-Type': 'application/json',
                "Authorization": `Bearer ${token}`,
            },
            retry: 0,
            timeout: 30000,
        })
    }
    return { response: await resp.json(), error: false }
}

const fetcherGet = async (url: string) => {
    const token = await getTokenFromStorage()
    const resp = await ky.get(url, {
        headers: {
            "Authorization": `Bearer ${token}`,
            'Content-Type': 'application/json'
        },
        retry: 0,
        timeout: 30000,
    })
    return resp.json()
}

const retryWithRefreshToken = async <T>(url: string, logout: () => void): Promise<ResponseWithPosibleError<T>> => {
    try {
        const newToken = await ApiClient.getTokenWithEmailAndPassword()
        if (!newToken.error) {
            const resp2 = await fetcherGet(url)
            return { response: resp2, error: false, }
        } else {
            logout()
            return returnErrorResponse(newToken.errorCode)
        }
    } catch (e) {
        console.error("Error retrying with new token: ", e)
        console.error("Response: ", JSON.stringify(e?.response))
        if (e?.response?.status === 401) {
            console.error("Unauthorized por segunda vez. Deslogueando...")
            await cleanLocalStorage()
            logout()
        }
        Sentry.captureException(e)
        return returnErrorResponse(e?.response?.status! || 500)
    }
}

const retryPostWithRefreshToken = async <T1, T2>(url: string, payload: T2, logout: () => void): Promise<ResponseWithPosibleError<T1>> => {
    try {
        const newToken = await ApiClient.getTokenWithEmailAndPassword()
        if (!newToken.error) {
            const result = await fetcherPost(url, payload)
            return {
                response: result.response,
                error: false
            }
        }
        await cleanLocalStorage()
        return returnErrorResponse(401)
    } catch (e) {
        console.error("Error retrying post with new token: ", e)
        console.error("Response: ", JSON.stringify(e?.response))
        console.error("payload", JSON.stringify(payload))
        if (e?.response?.status === 401) {
            console.error("Unauthorized por segunda vez. Deslogueando...")
            await cleanLocalStorage()
            logout()
        }
        Sentry.captureException(e)
        return returnErrorResponse(e?.response?.status || 500)
    }
}
export const fetcherPostWithRetry = async <T1, T2>(url: string, payload: T2, logout: () => void): Promise<ResponseWithPosibleError<T1>> => {
    try {
        const response = await fetcherPost<T2>(url, payload)
        return {
            response: response.response,
            error: false,
        }
    } catch (e) {
        console.error("error: ", e)
        console.error("url: ", url)
        console.error("payload: ", JSON.stringify(payload))
        if (e?.response?.status === 401) {
            console.log("Fallo primer intento de autorizacion. Se probara refrescar el token y volver a intentar")
            return await retryPostWithRefreshToken(url, payload, logout)
        } else {
            Sentry.captureException(e)
            return returnErrorResponse(e?.response?.status || 500)
        }
    }
}

export const fetcher = async <T>(url: string, logout: () => void): Promise<ResponseWithPosibleError<T>> => {
    try {
        const response = await fetcherGet(url)
        return { response, error: false }
    } catch (e) {
        console.error("error: ", e)
        console.error("url: ", url)
        if (e?.response?.status === 401) {
            console.log("Fallo primer intento de autorizacion. Se probara refrescar el token y volver a intentar")
            return await retryWithRefreshToken(url, logout)
        } else {
            Sentry.captureException(e)
            return returnErrorResponse(e?.response?.status || 500)
        }
    }
}

export const returnErrorResponse = (errorCode: number): ResponseWithError => {
    return {
        response: null,
        error: true,
        errorCode,
    }
}