import React, { useEffect, useRef, useState } from 'react';
import { IonPage, IonContent } from '@ionic/react';
import { useHistory } from 'react-router';
import styled from 'styled-components';
import { PageContainer } from '../../stylesGlobal/CommonStyles';
import { ButtonColor } from '../../components/shared/Button';
import { ReactComponent as Send } from "../../assets/icons/send.svg"
import { useTranslationTyped } from '../../utils/i18n/i18nHelper';
import Overlay from '../../components/shared/Overlay';
import { useForm } from "react-hook-form"
import Header from '../../components/shared/Header';
import { Input } from '../../components/shared/Inputs';
import { pagePadding, zIndex } from '../../stylesGlobal/Variables';
import FormErrorMessage from '../../components/shared/FormErrorMessage';
import { PageName, UserInfo } from "../../utils/Types"
import {
    appointmentConflictState,
    completedOrderState,
    orderState,
    paymentInfoState,
    selectedAppointmentState,
    userInfoState,
    userLocationState
} from '../../recoil/Atoms';
import { useRecoilState } from 'recoil';
import SmallOverlay from '../../components/shared/SmallOverlay';
import GoBackButtonGroup from '../../components/shared/GoBackButtonGroup';
import produce from 'immer';
import { getPageNumber, timeoutPromise } from '../../utils/HelperFunctions';
import { useRecoilValue } from 'recoil';
import { ApiClient } from "../../api/ApiClient";
import Spinner from '../../components/shared/Spinner';
import { ErrorModal } from '../../components/error/ErrorModal';
import { getRoutes } from '../../utils/Routes';
import { EventNames, trackEvent } from '../../utils/mixPanels/MixPanelHelper';
import { useSetRecoilState } from 'recoil';
import { useLogout } from '../../utils/customHooks/useLogout';
import { RegisterFinancingBindingModel } from '../../api/clientInterfaces/PaymentModel';
import { PaymentMethod } from '../../utils/Enums';
import animateScrollTo from 'animated-scroll-to';
import { browserName, isMobile } from 'react-device-detect';

const ContactPageWrapper = styled(PageContainer)`
    
    .contact__form{
        width:100%;
        padding: 3rem ${pagePadding.leftRight} 50% ${pagePadding.leftRight};
        /* margin-top: 3rem; */
        position:absolute;
        left:0;
        width: 100%;
        height: 100%;
        z-index:${zIndex.two};
        overflow: scroll;
        scroll-behavior: smooth;
    }
`

const ContactPage: React.FC = () => {

    const t = useTranslationTyped()

    const { register, handleSubmit, errors, setValue, getValues } = useForm<UserInfo>({
        mode: "all",
        shouldFocusError: true,
    })
    const { contact } = getRoutes()

    const [contactValue, setContactState] = useRecoilState(userInfoState)
    const selectedAppointment = useRecoilValue(selectedAppointmentState)
    const [order, setOrder] = useRecoilState(orderState)
    const setCompletedOrder = useSetRecoilState(completedOrderState)
    const setAppointmentConflict = useSetRecoilState(appointmentConflictState)
    const paymentInfo = useRecoilValue(paymentInfoState)
    const userLocation = useRecoilValue(userLocationState)
    const userInfo = useRecoilValue(userInfoState)
    const [showButtons, setShowButtons] = useState(true)

    const contentRef = useRef<HTMLFormElement>(null)
    const phoneRef = useRef<HTMLInputElement | null>(null)
    const addressRef = useRef<HTMLInputElement | null>(null)
    const firstNameRef = useRef<HTMLInputElement | null>(null)
    const lastNameRef = useRef<HTMLInputElement | null>(null)
    const incomeRef = useRef<HTMLInputElement | null>(null)
    const emailRef = useRef<HTMLInputElement | null>(null)

    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(false)

    const logout = useLogout()
    const history = useHistory()

    const onGoBack = () => {
        history.goBack()
    }

    const registerAppointment = async (orderId?: string) => {
        setLoading(true)
        const result = await ApiClient.registerAppointment({
            fechaHoraInicio: selectedAppointment?.time!,
            ordenId: order?.orderId! || orderId!,
            odontologoAgendaId: selectedAppointment?.dentistId!,
            tipoConsulta: "cita_inicial",
            domicilio: contactValue?.address!,
            telefono: contactValue?.phone!,
            ciudad: userLocation?.city,
            departamento: userLocation?.state,
            pais: userLocation?.country,
            identificador: userInfo?.id!,
            email: userInfo?.email!,
            nombre: userInfo?.firstName!,
            apellido: userInfo?.lastName!,
            montoIngresos: Number(userInfo?.income!),
        }, logout)
        if (!result.error) {
            setCompletedOrder(true)
            history.push(getRoutes().orderSummary)
            setError(false)
        } else {
            if (result.errorCode === 409) {
                setAppointmentConflict(true)
                history.goBack()
            } else {
                await timeoutPromise(100)
                setError(true)
            }
        }
        setLoading(false)
    }

    const registerFinancing = async () => {
        try {
            setLoading(true)
            if (paymentInfo) {
                if (paymentInfo.paymentMethod === PaymentMethod.Cash) {
                    if (order) {
                        return order.orderId
                    } else {
                        await timeoutPromise(100)
                        setLoading(false)
                        setError(true)
                    }
                } else if (paymentInfo.selectedOffer) {
                    const bindingModel: RegisterFinancingBindingModel = {
                        ofertaId: paymentInfo.selectedOffer.offerId,
                        identificador: contactValue?.id!
                    }
                    const result = await ApiClient.registerFinancing(bindingModel, logout)
                    if (result?.response) {
                        setOrder(result.response)
                        setLoading(false)
                        return result.response.orderId
                    } else {
                        await timeoutPromise(100)
                        setLoading(false)
                        setError(true)
                    }
                }
            }
        } catch (e) {
            setLoading(false)
            setError(true)
            console.error("Error while register financing", e)
        }
    }

    const registerBothCalls = async () => {
        let orderId = order?.orderId
        if (!orderId) {
            orderId = await registerFinancing()
        }
        if (orderId) {
            await registerAppointment(orderId)
        }
    }

    const onSubmit = async (data: UserInfo) => {
        await registerBothCalls()
    }

    const onRetry = async () => {
        setError(false)
        if (order?.orderId) {
            await registerAppointment()
        } else {
            await registerBothCalls()
        }
    }

    useEffect(() => {
        if (contactValue) {
            setValue("phone", contactValue.phone)
            setValue("address", contactValue.address)
            setValue("firstName", contactValue.firstName)
            setValue("lastName", contactValue.lastName)
            setValue("email", contactValue.email)
            setValue("income", contactValue.income)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedAppointment])

    const isFormWithErrors = () => !!errors.address || !!errors.phone

    const captureEvent = (_event: React.FocusEvent<HTMLInputElement>, inputName: string) => {
        trackEvent({
            eventName: EventNames.FillsInput,
            pageId: contact,
            inputName,
            value: getValues(inputName)
        })
    }

    const checkPhone = (value: string) => {
        return value.length > 7 || t("contact", "form", "phone", "errorMessage")
    }

    const checkAddress = (value: string) => {
        return value.length > 5 || t("contact", "form", "address", "errorMessage")
    }

    const checkEmail = (value: string) => {
        return /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(value) || t("contact", "form", "email", "errorMessage")
    }

    const updateContactInfo = (
        e: React.ChangeEvent<HTMLInputElement>,
        input: "phone" | "address" | "email" | "firstName" | "lastName" | "income"
    ) => {
        setContactState(
            produce((prevState: UserInfo | null) => {
                prevState![input] = e.target.value
            }))
    }


    return (
        <IonPage>
            <IonContent
                forceOverscroll={false}
            >
                <ContactPageWrapper>
                    <ErrorModal
                        showErrorModal={error}
                        onRetry={onRetry} />
                    <Overlay scroll={true}>
                        <SmallOverlay>
                            <Header
                                title={t("contact", "pageTitle")}
                                stepNumber={getPageNumber(PageName.Contact)}
                            />
                        </SmallOverlay>
                        <IonContent forceOverscroll={true} scrollY={false}>
                            {!loading ? <form className="contact__form" onSubmit={handleSubmit(onSubmit)} ref={contentRef}>
                                <Input
                                    type="text"
                                    name="firstName"
                                    aria-label="FirstName"
                                    error={!!errors.firstName}
                                    onChange={e => updateContactInfo(e, "firstName")}
                                    placeholder={t("contact", "form", "firstName", "label")}
                                    ref={(e) => {
                                        register(e, {
                                            required: {
                                                message: t("contact", "form", "firstName", "errorMessage"),
                                                value: true,
                                            },
                                            // validate: checkAddress
                                        })
                                        firstNameRef.current = e;
                                    }}
                                    onFocus={async () => {
                                        animateScrollTo((firstNameRef.current as any), {
                                            elementToScroll: contentRef.current as any,
                                            verticalOffset: -20
                                        })
                                        setShowButtons(false)
                                    }}
                                    onBlur={event => { captureEvent(event, "firstName"); setShowButtons(true) }}
                                    onKeyDown={(e) => {
                                        if (e.keyCode === 13) {
                                            lastNameRef.current?.focus()
                                        }
                                    }}
                                />
                                <Input
                                    type="text"
                                    name="lastName"
                                    aria-label="LastName"
                                    error={!!errors.lastName}
                                    onChange={e => updateContactInfo(e, "lastName")}
                                    placeholder={t("contact", "form", "lastName", "label")}
                                    ref={(e) => {
                                        register(e, {
                                            required: {
                                                message: t("contact", "form", "lastName", "errorMessage"),
                                                value: true,
                                            },
                                            // validate: checkAddress
                                        })
                                        lastNameRef.current = e;
                                    }}
                                    onFocus={async () => {
                                        animateScrollTo((lastNameRef.current as any), {
                                            elementToScroll: contentRef.current as any,
                                            verticalOffset: -20
                                        })
                                        setShowButtons(false)
                                    }}
                                    onBlur={event => { captureEvent(event, "lastName"); setShowButtons(true) }}
                                    onKeyDown={(e) => {
                                        if (e.keyCode === 13) {
                                            emailRef.current?.focus()
                                        }
                                    }}
                                />
                                <Input
                                    type="email"
                                    name="email"
                                    aria-label="Email"
                                    error={!!errors.email}
                                    onChange={e => updateContactInfo(e, "email")}
                                    placeholder={t("contact", "form", "email", "label")}
                                    ref={(e) => {
                                        register(e, {
                                            required: {
                                                message: t("contact", "form", "email", "errorMessage"),
                                                value: true,
                                            },
                                            validate: checkEmail
                                        })
                                        emailRef.current = e;
                                    }}
                                    onFocus={async () => {
                                        animateScrollTo((emailRef.current as any), {
                                            elementToScroll: contentRef.current as any,
                                            verticalOffset: -20
                                        })
                                        setShowButtons(false)
                                    }}
                                    onBlur={event => { captureEvent(event, "email"); setShowButtons(true) }}
                                    onKeyDown={(e) => {
                                        if (e.keyCode === 13) {
                                            phoneRef.current?.focus()
                                        }
                                    }}
                                />
                                <Input
                                    type="text"
                                    name="phone"
                                    aria-label="Phone"
                                    placeholder={t("contact", "form", "phone", "label")}
                                    error={!!errors.phone}
                                    onChange={e => updateContactInfo(e, "phone")}
                                    ref={(e) => {
                                        register(e, {
                                            required: {
                                                message: t("contact", "form", "phone", "errorMessage"),
                                                value: true,
                                            },
                                            validate: checkPhone
                                        })
                                        phoneRef.current = e;
                                    }}
                                    onFocus={async () => {
                                        animateScrollTo((phoneRef.current as any), {
                                            elementToScroll: contentRef.current as any,
                                            verticalOffset: -20
                                        })
                                        setShowButtons(false)
                                    }}
                                    onBlur={event => { captureEvent(event, "phone"); setShowButtons(true) }}
                                    onKeyDown={(e) => {
                                        if (e.keyCode === 13) {
                                            addressRef.current?.focus()
                                        }
                                    }}
                                />
                                <FormErrorMessage
                                    message={(errors.phone && errors.phone.message) || ""}
                                />
                                <Input
                                    type="text"
                                    name="address"
                                    aria-label="Address"
                                    error={!!errors.address}
                                    onChange={e => updateContactInfo(e, "address")}
                                    placeholder={t("contact", "form", "address", "label")}
                                    ref={(e) => {
                                        register(e, {
                                            required: {
                                                message: t("contact", "form", "address", "errorMessage"),
                                                value: true,
                                            },
                                            validate: checkAddress
                                        })
                                        addressRef.current = e;
                                    }}
                                    onFocus={async () => {
                                        animateScrollTo((addressRef.current as any), {
                                            elementToScroll: contentRef.current as any,
                                            verticalOffset: -20
                                        })
                                        setShowButtons(false)
                                    }}
                                    onBlur={event => { captureEvent(event, "address"); setShowButtons(true) }}
                                    onKeyDown={(e) => {
                                        if (e.keyCode === 13) {
                                            incomeRef.current?.focus()
                                        }
                                    }}
                                />
                                <Input
                                    type="number"
                                    name="income"
                                    aria-label="Income"
                                    error={!!errors.income}
                                    onChange={e => updateContactInfo(e, "income")}
                                    placeholder={t("contact", "form", "income", "label")}
                                    ref={(e) => {
                                        register(e, {
                                            required: {
                                                message: t("contact", "form", "income", "errorMessage"),
                                                value: true,
                                            },
                                            // validate: checkIncome
                                        })
                                        incomeRef.current = e;
                                    }}
                                    onFocus={async () => {
                                        animateScrollTo((incomeRef.current as any), {
                                            elementToScroll: contentRef.current as any,
                                            verticalOffset: -20
                                        })
                                        setShowButtons(false)
                                    }}
                                    onBlur={event => { captureEvent(event, "income"); setShowButtons(true) }}
                                    onKeyDown={(e) => {
                                        if (e.keyCode === 13 && !isFormWithErrors()) {
                                            incomeRef.current?.blur()
                                            handleSubmit(onSubmit)
                                        }
                                    }}
                                />
                                <FormErrorMessage
                                    message={(errors.address && errors.address.message) || ""}
                                />
                            </form>
                                : <Spinner theme="light" />
                            }

                        </IonContent>

                    </Overlay>
                    {(showButtons || !(browserName === 'Chrome' && isMobile)) &&
                        <GoBackButtonGroup
                            onGoBack={onGoBack}
                            showSmallButtons={true}
                            readyButton={{
                                color: ButtonColor.blue,
                                onClick: handleSubmit(onSubmit),
                                iconSVG: <Send />,
                                disabled: isFormWithErrors() || loading,
                                label: t("contact", "form", "sendButtonLabel")
                            }}
                        />
                    }
                </ContactPageWrapper>
            </IonContent>
        </IonPage>
    )
}

export default ContactPage