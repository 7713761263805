import React, { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router';
import { paymentInfoState } from '../../../recoil/Atoms';
import styled from 'styled-components';
import { PageContainer } from '../../../stylesGlobal/CommonStyles';
import { ButtonColor } from '../../../components/shared/Button';
import { ReactComponent as Check } from "../../../assets/icons/check.svg"
import { useTranslationTyped } from '../../../utils/i18n/i18nHelper';
import Overlay from '../../../components/shared/Overlay';
import Header from '../../../components/shared/Header';
import { useForm } from 'react-hook-form';
import { colors, pagePadding, zIndex } from '../../../stylesGlobal/Variables';
import { InputRadio } from '../../../components/shared/InputRadio';
import { Input } from '../../../components/shared/Inputs';
import FormErrorMessage from '../../../components/shared/FormErrorMessage';
import { useRecoilState } from 'recoil';
import produce from 'immer';
import { BaseFinancing, PageName, PaymentStateType } from '../../../utils/Types';
import GoBackButtonGroup from '../../../components/shared/GoBackButtonGroup';
import { IonContent, IonPage } from '@ionic/react';
import SmallOverlay from '../../../components/shared/SmallOverlay';
import { getPageNumber } from '../../../utils/HelperFunctions';
import { EventNames, trackEvent } from '../../../utils/mixPanels/MixPanelHelper';
import animateScrollTo from 'animated-scroll-to';
import { useCashIsLessThanPrice, useCiIsValid } from './paymentComponents/validators';
import { ApiClient } from '../../../api/ApiClient';
import { mapResources, useResourceHelper } from './resourceHelper';
import { useLogout } from '../../../utils/customHooks/useLogout';
import { PaymentMethod } from '../../../utils/Enums';
import { getRoutes } from '../../../utils/Routes';
import Spinner from '../../../components/shared/Spinner';
import { browserName, isMobile } from 'react-device-detect';

const IdAndCouponPageWrapper = styled(PageContainer) <{ finAndCash: boolean }>`
    .financing__form{
        padding: 3rem ${pagePadding.leftRight};
        /* margin-top: 3rem; */
        padding-bottom:60%;
        position:absolute;
        left:0;
        width: 100%;
        height: 100%;
        z-index:${zIndex.two};
        overflow: scroll;
        scroll-behavior: smooth;
    }

    .payment__amountContainer {
        background: rgb(13, 59, 251);
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        border-radius: 0 0 2rem 2rem;
        padding-top: 2rem;
    }

    .payment__amount {
        font-size: 24px;
        color: ${colors.white};
        font-weight: 700;
        padding-top: 2rem;
        padding-bottom: 1rem;
        text-align: center;
    }

    .payment__label {
        font-size: 11px;
        color: rgba(255, 255, 255, 0.65);
        padding-bottom: 1.5rem;
        text-align: center;
    }

    ${p => p.finAndCash && `
        ion-content {
            position: relative;
            top: -2rem;
        }`

    }

`
type FormData = {
    documentId: number,
    coupon: string,
    cashAmount?: number,
    // documentValidatorId: number
}

const IdAndCouponPage: React.FC = () => {
    const [paymentInfo, setPaymentInfo] = useRecoilState(paymentInfoState)
    const [financingData, setFinancingData] = useState<BaseFinancing>()
    const [showButtons, setShowButtons] = useState(true)
    const [loading, setLoading] = useState(false);

    const documentRef = useRef<HTMLElement | null>(null)
    const couponRef = useRef<HTMLElement | null>(null)
    const contentRef = useRef<HTMLFormElement>(null)
    const cashRef = useRef<HTMLElement | null>(null)

    const resourcesIds = useResourceHelper()
    const ciIsValid = useCiIsValid()
    const cashIsLessThanPrice = useCashIsLessThanPrice()
    const { register, handleSubmit, errors, setValue, getValues } = useForm<FormData>({
        mode: "all",
        shouldFocusError: true,
    })
    const t = useTranslationTyped()
    const history = useHistory()
    const logout = useLogout()
    const isCashAndFinancing = paymentInfo?.paymentMethod === PaymentMethod.CashAndFinancing

    useEffect(() => {
        const getBaseFinancing = async () => {
            if (isCashAndFinancing) {
                try {
                    setLoading(true)
                    const baseFinancing = await ApiClient.getBaseFinancing(mapResources(resourcesIds), logout)
                    if (!baseFinancing.error && baseFinancing.response) {
                        setFinancingData(baseFinancing.response)
                        setLoading(false)
                    } else {
                        setLoading(false)
                    }
                } catch (e) {
                    console.error(e)
                    setLoading(false)
                }
            } else {
                return null
            }
        }
        getBaseFinancing()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [paymentInfo?.paymentMethod])

    useEffect(() => {
        !getValues().documentId && paymentInfo?.documentId && setValue("documentId", paymentInfo.documentId)
        !getValues().coupon && paymentInfo?.coupon && setValue("coupon", paymentInfo?.coupon)
    }, [getValues, paymentInfo, setValue])

    useEffect(() => {
        if (!paymentInfo?.paymentMethod ||
            (paymentInfo?.paymentMethod !== PaymentMethod.Cash &&
                paymentInfo?.paymentMethod !== PaymentMethod.CashAndFinancing &&
                paymentInfo?.paymentMethod !== PaymentMethod.Financing)
        ) {
            logout()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [paymentInfo?.paymentMethod])

    const onGoBack = () => {
        history.goBack()
    }

    const isFormWithErrors = () => !!errors.documentId

    const setPaymentInformation = (
        documentId: number,
        coupon?: string,
        cashAmount?: number,
    ) => {
        setPaymentInfo(
            produce((prevState: PaymentStateType | null) => {
                if (prevState) {
                    prevState.coupon = coupon
                    prevState.documentId = documentId
                    if (prevState?.paymentMethod === PaymentMethod.CashAndFinancing) {
                        prevState.amountCash = cashAmount
                    }
                }
            })
        )
    }

    const setPaymentInformationAndProceed = (data: FormData) => {
        setPaymentInformation(data.documentId, data.coupon, data.cashAmount)
        const { financialEntity, cashPaymentMethod } = getRoutes()
        history.push(paymentInfo?.paymentMethod === PaymentMethod.Cash ? cashPaymentMethod : financialEntity)
    }

    const captureEvent = (_event: React.FocusEvent<HTMLInputElement>, inputName: string) => {
        trackEvent({
            eventName: EventNames.FillsInput,
            pageId: `${paymentInfo?.paymentMethod}`,
            inputName,
            value: getValues(inputName)
        })
    }

    const renderForm = () => {
        return <form className="financing__form" onSubmit={handleSubmit(setPaymentInformationAndProceed)} ref={contentRef}>
            <InputRadio
                name="paymentMethod"
                value={paymentInfo?.paymentMethod}
                label={t("paymentMethod", "form", paymentInfo?.paymentMethod)}
                defaultChecked={true}
                selected={true}
                withMarginBottom={true}
            />
            {isCashAndFinancing &&
                <>
                    <Input
                        type="text"
                        inputMode="text"
                        name="cashAmount"
                        aria-label="Cash amount"
                        placeholder={t("paymentMethod", "financingMethodForm", "cashAmount", "placeholder")}
                        error={!!errors.cashAmount || !cashIsLessThanPrice(getValues("cashAmount")!, financingData?.cashPayment!)}
                        ref={(e) => {
                            register(e, {
                                required: {
                                    message: t("paymentMethod", "financingMethodForm", "cashAmount", "errorMessage"),
                                    value: true,
                                },
                                validate: (value: number) => cashIsLessThanPrice(value, financingData?.cashPayment!),
                            })
                            cashRef.current = e;
                        }
                        }
                        onFocus={async () => {
                            animateScrollTo((cashRef.current as any), {
                                elementToScroll: contentRef.current as any,
                                verticalOffset: -20
                            })
                            setShowButtons(false)
                        }}
                        onBlur={() => setShowButtons(true)}
                        onKeyDown={(e) => {
                            if (e.keyCode === 13) {
                                documentRef.current?.focus()
                            }
                        }}
                    />
                    <FormErrorMessage
                        message={(errors.cashAmount && errors.cashAmount.message) || ""}
                    />
                </>
            }
            <Input
                type="text"
                inputMode="text"
                name="documentId"
                aria-label="Number ID"
                placeholder={t("paymentMethod", "financingMethodForm", "documentId", "placeholder")}
                error={!!errors.documentId}
                ref={(e) => {
                    register(e, {
                        required: {
                            message: t("paymentMethod", "financingMethodForm", "documentId", "errorMessage"),
                            value: true,
                        },
                        validate: ciIsValid
                    })
                    documentRef.current = e
                }}
                onFocus={async () => {
                    animateScrollTo((documentRef.current as any), {
                        elementToScroll: contentRef.current as any,
                        verticalOffset: -20
                    })
                    setShowButtons(false)
                }}
                onBlur={event => { captureEvent(event, "documentId"); setShowButtons(true) }}
                onKeyDown={(e) => {
                    if (e.keyCode === 13) {
                        couponRef.current?.focus()
                    }
                }}
            />
            <FormErrorMessage
                message={(errors.documentId && errors.documentId.message) || ""}
            />
            <Input
                type="text"
                inputMode="text"
                name="coupon"
                aria-label="Coupon"
                placeholder={t("paymentMethod", "financingMethodForm", "couponPlaceholder")}
                ref={(e) => {
                    register(e, {})
                    couponRef.current = e
                }}
                onFocus={async () => {
                    animateScrollTo((couponRef.current as any), {
                        elementToScroll: contentRef.current as any,
                        verticalOffset: -20
                    })
                    setShowButtons(false)
                }}
                onBlur={event => { captureEvent(event, "coupon"); setShowButtons(true) }}
                onKeyDown={(e) => {
                    if (e.keyCode === 13 && !isFormWithErrors()) {
                        couponRef.current?.blur()
                        handleSubmit(setPaymentInformationAndProceed)
                    }
                }}
            />
        </form>
    }

    return (
        <IonPage>
            <IonContent forceOverscroll={true} scrollY={false}>
                <IdAndCouponPageWrapper finAndCash={isCashAndFinancing}>
                    <Overlay scroll={true}>
                        <SmallOverlay hideOverflow>
                            <Header
                                title={t("paymentMethod", "pageTitle")}
                                subtitle={t("paymentMethod", "pageSubtitle")}
                                stepNumber={getPageNumber(PageName.Payment)}
                            />
                        </SmallOverlay>
                        <IonContent
                            forceOverscroll={true} scrollY={false}
                        >
                            {isCashAndFinancing &&
                                <div className="payment__amountContainer">
                                    {loading || !financingData ? <Spinner theme="light" small /> :
                                        <div>
                                            <p className="payment__amount">{`${financingData?.currency} ${financingData?.cashPayment.toLocaleString('es')}`}</p>
                                            <p className="payment__label">{t("paymentMethod", "aproximatePaymentAmount")}</p>
                                        </div>
                                    }
                                </div>
                            }
                            {renderForm()}
                        </IonContent>
                    </Overlay>
                    {(showButtons || !(browserName === 'Chrome' && isMobile)) &&
                        <GoBackButtonGroup
                            onGoBack={onGoBack}
                            showSmallButtons={true}
                            readyButton={{
                                color: ButtonColor.blue,
                                onClick: handleSubmit(setPaymentInformationAndProceed),
                                iconSVG: <Check />,
                                label: t("paymentMethod", "financingMethodForm", "onSubmitButtonLabel"),
                                disabled: isFormWithErrors(),
                            }}
                        />
                    }
                </IdAndCouponPageWrapper>
            </IonContent>
        </IonPage >
    )
}

export default IdAndCouponPage