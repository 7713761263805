import { TreatmentPagePropType } from "./TreatmentFlowDataTypes";
import { Resource, PageId } from "../../utils/Enums";
import { useTranslationTyped } from '../../utils/i18n/i18nHelper';
import { getRoutes } from "../../utils/Routes";
import alineador_fijo from "../../assets/alineador_fijo.png"
import alineador_transparente from "../../assets/alineador_transparente.png"
import alinear from "../../assets/alinear.png"
import blanquear from "../../assets/blanquear.png"
import dentadura_fija from "../../assets/dentadura_fija.png"
import dentadura_movil from "../../assets/dentadura_movil.png"
import dentadura from "../../assets/dentadura.png"
import implantes from "../../assets/implantes.png"
import faltan_dientes from "../../assets/faltan_dientes.png"
import restaurar from "../../assets/restaurar.png"
import todos_los_dientes from "../../assets/todos_los_dientes.png"

const usePageData = () => {
    const t = useTranslationTyped()
    const { pictureTaker, choosePaymentMethod } = getRoutes()

    const pagesData: TreatmentPagePropType[] = [
        {
            title: t("flow", PageId.MissingTeeth, "title"),
            pageId: PageId.MissingTeeth,
            stepNumber: 1,
            options: [
                {
                    title: t("flow", PageId.MissingTeeth, "option_1", "title"),
                    description: t("flow", PageId.MissingTeeth, "option_1", "subtitle"),
                    image: faltan_dientes,
                    nextPageId: PageId.FixedOrMobileImplants,
                },
                {
                    title: t("flow", PageId.MissingTeeth, "option_2", "title"),
                    description: t("flow", PageId.MissingTeeth, "option_2", "subtitle"),
                    image: todos_los_dientes,
                    nextPageId: PageId.TeethImprovements,
                },
            ]
        },
        {
            title: t("flow", PageId.FixedOrMobileImplants, "title"),
            pageId: PageId.FixedOrMobileImplants,
            stepNumber: 2,
            options: [
                {
                    title: t("flow", PageId.FixedOrMobileImplants, "option_1", "title"),
                    description: t("flow", PageId.FixedOrMobileImplants, "option_1", "subtitle"),
                    image: implantes,
                    resource: Resource.FixedImplant,
                    onSelectionRoute: pictureTaker,
                },
                {
                    title: t("flow", PageId.FixedOrMobileImplants, "option_2", "title"),
                    description: t("flow", PageId.FixedOrMobileImplants, "option_2", "subtitle"),
                    image: dentadura,
                    nextPageId: PageId.Implants,
                },
            ]
        },
        {
            title: t("flow", PageId.TeethImprovements, "title"),
            pageId: PageId.TeethImprovements,
            stepNumber: 2,
            options: [
                {
                    title: t("flow", PageId.TeethImprovements, "option_1", "title"),
                    description: t("flow", PageId.TeethImprovements, "option_1", "subtitle"),
                    image: alinear,
                    nextPageId: PageId.Alignment,
                },
                {
                    title: t("flow", PageId.TeethImprovements, "option_2", "title"),
                    description: t("flow", PageId.TeethImprovements, "option_2", "subtitle"),
                    image: blanquear,
                    resource: Resource.Whitening,
                    onSelectionRoute: choosePaymentMethod,
                },
                {
                    title: t("flow", PageId.TeethImprovements, "option_3", "title"),
                    description: t("flow", PageId.TeethImprovements, "option_3", "subtitle"),
                    image: restaurar,
                    resource: Resource.ToothRestoration,
                    onSelectionRoute: pictureTaker,
                },
            ]
        },
        {
            title: t("flow", PageId.Implants, "title"),
            pageId: PageId.Implants,
            stepNumber: 3,
            options: [
                {
                    title: t("flow", PageId.Implants, "option_1", "title"),
                    description: t("flow", PageId.Implants, "option_1", "subtitle"),
                    image: dentadura_fija,
                    resource: Resource.MobileProstheticWithImplant,
                    onSelectionRoute: choosePaymentMethod
                },
                {
                    title: t("flow", PageId.Implants, "option_2", "title"),
                    description: t("flow", PageId.Implants, "option_2", "subtitle"),
                    image: dentadura_movil,
                    resource: Resource.MobileProstheticWithoutImplant,
                    onSelectionRoute: choosePaymentMethod
                },
            ]
        },
        {
            title: t("flow", PageId.Alignment, "title"),
            pageId: PageId.Alignment,
            stepNumber: 3,
            options: [
                {
                    title: t("flow", PageId.Alignment, "option_1", "title"),
                    description: t("flow", PageId.Alignment, "option_1", "subtitle"),
                    image: alineador_transparente,
                    resource: Resource.InvisibleAligner,
                    onSelectionRoute: choosePaymentMethod,
                },
                {
                    title: t("flow", PageId.Alignment, "option_2", "title"),
                    description: t("flow", PageId.Alignment, "option_2", "subtitle"),
                    image: alineador_fijo,
                    resource: Resource.FixedApplieances,
                    onSelectionRoute: choosePaymentMethod,
                },
            ]
        },
    ]
    return pagesData
}



export const usePage = (pageId: PageId) => {
    const pageData = usePageData()
    return pageData.find(page => page.pageId === pageId)
}