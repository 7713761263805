import React from "react"
import styled, { keyframes } from "styled-components"

const loaderAnimation = keyframes`
    0%{
        transform: rotate(0deg);
    }
    100%{
        transform: rotate(360deg);
    }
`


const getThemeColor = (theme: "dark" | "light", inverted?: boolean) => {
    if (theme === "dark") {
        return inverted ? "rgba(255, 255, 255, 0.2)" : "#000000"
    } else {
        return inverted ? "rgba(0, 0, 0, 0.2)" : "#ffffff"
    }
}

const SpinnerWrapper = styled.div`

    .loader{
        border-radius: 50%;
        width: ${(p: Props) => p.small ? "2" : "7"}rem;
        height: ${(p: Props) => p.small ? "2" : "7"}rem;
        margin: ${(p: Props) => p.small ? "3" : "6"}rem auto;
        position: relative;
        border-top: .7rem solid ${(p: Props) => getThemeColor(p.theme)};
        border-right: .7rem solid ${(p: Props) => getThemeColor(p.theme)};
        border-bottom: .7rem solid ${(p: Props) => getThemeColor(p.theme)};
        border-left: .7rem solid ${(p: Props) => getThemeColor(p.theme, true)};
        transform: translateZ(0);
        animation: ${loaderAnimation} 1.1s infinite linear;

        &:after{
            border-radius: 50%;
            width: 7rem;
            height: 7rem; 
        }
    }
`

type Props = {
    theme: "dark" | "light",
    small?: boolean,
}

const Spinner = (props: Props) => {

    return (
        <SpinnerWrapper {...props}>
            <div className="loader" />
        </SpinnerWrapper>

    )
}

export default Spinner