import { atom } from "recoil"
import { setCompletedOrderStateInStorage, setFlowInStorage, setOdontogramTutorialStateInStorage, setResourceInStorage, setTeethPictureInStorage, setUserInfoInStorage, setUserLocationInStorage } from "../components/persistanceModal/PersistenceDataAccess";
import { Resource, PageId } from "../utils/Enums";
import {
  AppointmentDataType, UserLocation, UserInfo
  , PaymentStateType, SelectedTeeth, RegisteredFinancing, PictureInfo, AvailableAppointments, FinancingOptions
} from "../utils/Types";


export const resourceState = atom<Resource | null>({
  key: 'resourceState',
  default: null,
  effects: [
    ({ onSet }) => {
      onSet((newValue, _, isReset) => {
        setResourceInStorage(newValue)
      });
    }
  ]
});

export const treatmentFlowState = atom<PageId[] | null>({
  key: 'treatmentFlowState',
  default: [],
  effects: [
    ({ onSet }) => {
      onSet((newValue, _, isReset) => {
        setFlowInStorage(newValue)
      });
    }
  ]
})


export const paymentInfoState = atom<PaymentStateType | null>({
  key: 'paymentInfoState',
  default: null,
})

export const financingOptionsState = atom<FinancingOptions | null>({
  key: 'financingOptionsState',
  default: null,
})

export const selectedAppointmentState = atom<AppointmentDataType | null>({
  key: 'selectedAppointment',
  default: null,
})

export const selectedDentistState = atom<AvailableAppointments | undefined>({
  key: 'selectedClinicState',
  default: undefined,
})

export const userLocationState = atom<UserLocation | null>({
  key: 'userLocation',
  default: null,
  effects: [
    ({ onSet }) => {
      onSet((newValue, _, isReset) => {
        setUserLocationInStorage(newValue)
      });
    }
  ]
})

export const userInfoState = atom<UserInfo | null>({
  key: "contactInfo",
  default: null,
  effects: [
    ({ onSet }) => {
      onSet((newValue, _, isReset) => {
        setUserInfoInStorage(newValue)
      });
    }
  ]
})

export const teethPictureState = atom<PictureInfo | null>({
  key: "teethPicture",
  default: null,
  effects: [
    ({ onSet }) => {
      onSet((newValue, _, isReset) => {
        setTeethPictureInStorage(newValue)
      });
    }
  ]
})

export const selectedTeethState = atom<SelectedTeeth>({
  key: "selectedTeeth",
  default: {
    implantedIds: [],
    restoredIds: []
  },
})

export const hasSeenImpantTutorialState = atom<boolean | null>({
  key: "implantTutorial",
  default: false,
  effects: [
    ({ onSet }) => {
      onSet((newValue, _, isReset) => {
        setOdontogramTutorialStateInStorage(newValue)
      });
    }
  ]

})

export const hasSeenRestorationTutorialState = atom<boolean | null>({
  key: "restorationTutorial",
  default: false,
})

export const completedOrderState = atom<boolean | null>({
  key: "odontogramTutorial",
  default: false,
  effects: [
    ({ onSet }) => {
      onSet((newValue, _, isReset) => {
        setCompletedOrderStateInStorage(newValue)
      });
    }
  ]
})

export const orderState = atom<RegisteredFinancing | null>({
  key: "order",
  default: null,
})

export const appointmentConflictState = atom<boolean>({
  key: "appointmentConflict",
  default: false,
})

export const loginLoadingState = atom<boolean>({
  key: "loadingLogin",
  default: false,
})